import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import CustomIcon from "components/CustomIcon";

MDDRadioGroup.propTypes = {
  item: PropTypes.object,
  variant: PropTypes.string,
  control: PropTypes.object,
  trigger: PropTypes.func,
};

function MDDRadioGroup(props) {
  const { item, control, trigger, variant = "outlined" } = props;

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{item.label}</FormLabel>
      <Controller
        name={item.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <RadioGroup value={value} onChange={onChange}>
            <div style={{ display: "flex" }}>
              {item.options.map((option) => (
                <Box
                  key={option.value}
                  sx={{
                    backgroundColor: value === option.value ? "#C0C0C0" : "#ffffff",
                    marginTop: "0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    cursor: "pointer",
                    border: value === option.value ? "1px solid #001f54" : "1px solid grey",
                    borderRadius: "4px",
                    padding: "2px",
                    width: "90px",
                    height: "90px",
                    flex: "1 1 0",
                  }}
                  onClick={() => {
                    onChange(option.value);
                    trigger(item.name);
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel key={item.value} value={item.value} control={<Radio />} />
                    <CustomIcon name={option.icon} size={48} />
                    <Typography variant="small">{option.label}</Typography>
                  </Box>
                </Box>
              ))}
            </div>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

export default MDDRadioGroup;
