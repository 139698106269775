import PropTypes from "prop-types";
import { Fragment, useEffect, useRef } from "react";
import { Text as KonvaText, Transformer } from "react-konva";

Text.propTypes = {
  shapeProps: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  isRubberSelected: PropTypes.bool,
  onDeleteShape: PropTypes.func,
  stageRef: PropTypes.object,
  layerRef: PropTypes.object,
};

export default function Text(props) {
  const { shapeProps, isSelected, onSelect, onChange, isRubberSelected, onDeleteShape, stageRef, layerRef } = props;
  const shapeRef = useRef();
  const trRef = useRef();
  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleDblClick = (e) => {
    console.log(" dblclick", shapeRef);
    let textNode = shapeRef.current;
    let stage = stageRef.current;
    let layer = layerRef.current;
    let tr = trRef.current;
    //hide text node and transformer:
    textNode.hide();
    tr.hide();
    layer.draw();

    //now to create textArea over Canvas with absolute position
    //do this by finding position for textarea
    //first will find the position of textNode relative to stage:
    let textPosition = textNode.absolutePosition();
    //then finding the position of stage container on page:
    let stageBox = stage.container().getBoundingClientRect();

    //position of the text area is the sum of the above to variables
    let areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    };

    //create and style the text area
    let textArea = document.createElement("textArea");
    document.body.appendChild(textArea);

    //the following applies many styles to match the text on canvas
    //text rendering on canvas and on the text area can be different
    //typically hard to make them 100% the same

    textArea.value = textNode.text();
    textArea.style.position = "absolute";
    textArea.style.top = areaPosition.y + "px";
    textArea.style.left = areaPosition.x + "px";
    textArea.style.width = textNode.width() - textNode.padding() * 2 + "px";
    textArea.style.height = textNode.height() - textNode.padding() * 2 + 5 + "px";
    textArea.style.fontSize = shapeProps.fontSizeSave + "px";
    textArea.style.border = "2";
    textArea.style.padding = "2px";
    textArea.style.margin = "0px";
    textArea.style.overflow = "hidden";
    textArea.style.background = "none";
    textArea.style.outline = "none";
    textArea.style.resize = "none";
    textArea.style.lineHeight = textNode.lineHeight();
    textArea.style.fontFamily = textNode.fontFamily();
    textArea.style.transformOrigin = "left top";
    textArea.style.textAlign = textNode.align();
    textArea.style.color = textNode.fill();

    let rotation = textNode.rotation();
    let transform = "";
    if (rotation) {
      transform += "rotateZ(" + rotation + "deg)";
    }

    let px = 0;
    let isFireFox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (isFireFox) {
      px += 2 + Math.round(textNode.fontSize() / 20);
    }
    transform += "translateY(-" + px + "px)";

    textArea.style.transform = transform;
    textArea.style.height = "auto";
    //after browsers resized it, we can set the actual value
    textArea.style.height = textArea.scrollHeight + 3 + "px";
    textArea.style.zIndex = "2000";

    textArea.focus();

    function removeTextArea(doSave) {
      if (doSave) {
        const newText = textArea.value;
        const updateValues = {
          ...shapeProps,
          text: textArea.value,
          x: e.target.x(),
          y: e.target.y(),
        };

        let newFontSize = shapeProps.fontSize;
        if (newText !== "Text hier eingeben") newFontSize = shapeProps.fontSizeSave;
        updateValues.fontSize = newFontSize;
        console.log("updateValues", updateValues);
        onChange(updateValues);
      }
      textArea.parentNode.removeChild(textArea);
      window.removeEventListener("click", handleOutsideClick);
      textNode.show();
      tr.show();
      tr.forceUpdate();
      layer.draw();
    }

    function setTextAreaWidth(newWidth) {
      if (!newWidth) {
        newWidth = textNode.placeholder.length * textNode.fontSize();
      }
      //extra fixes for different browsers
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      let isFireFox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

      if (isSafari || isFireFox) {
        newWidth = Math.ceil(newWidth);
      }
      let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
      if (isEdge) {
        newWidth += 1;
      }
      textArea.style.width = newWidth + "px";
    }

    textArea.addEventListener("keydown", function (e) {
      //hide on enter
      //but dont hide on shift+enter
      /*   if (e.keyCode === 13) {
           console.log("enter");
           textArea.value += "\n";
           textNode.text(textArea.value);
           //removeTextArea();
         }*/
      //on esc do not set value back to node
      if (e.keyCode === 27) {
        console.log("esc");
        removeTextArea(false);
      }
    });

    textArea.addEventListener("keydown", function () {
      let scale = textNode.getAbsoluteScale().x;
      setTextAreaWidth(textNode.width() * scale);
      textArea.style.height = "auto";
      textArea.style.height = textArea.scrollHeight + textNode.fontSize() + "px";
    });

    function handleOutsideClick(e) {
      if (e.target !== textArea) {
        console.log("outside");
        removeTextArea(true);
      }
    }

    setTimeout(() => {
      window.addEventListener("click", handleOutsideClick);
    });
  };

  return (
    <Fragment>
      <KonvaText
        onMouseOver={(e) => {
          if (e.evt.buttons !== 0 && isRubberSelected) {
            onDeleteShape(shapeRef.current, isSelected);
          }
        }}
        onClick={() => {
          if (isRubberSelected) {
            onDeleteShape(shapeRef.current, isSelected);
          } else {
            onSelect();
          }
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable={true}
        onDblClick={handleDblClick}
        onTap={handleDblClick}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
            width: node.width() * scaleX,
            height: node.height() * scaleY,
          });
        }}
      />
      {isSelected && <Transformer ref={trRef} anchorSize={20} rotateEnabled={false} borderDash={[6, 2]} />}
    </Fragment>
  );
}
