export const defTemplateSdV = [
	{
		name: "haustiere",
		label: "Haustiere",
		control: "button_checkboxes",
		cols: 12,
		info: {
			title: "Hinweise",
			description: "Sofern Sie Halter/-in eines Hundes oder Pferdes sind, geben Sie dieses an. Als Halter/-in dieser Tiere besteht ein erhöhtes Haftungsrisiko, welches üblicherweise über die Privathaftpflicht nicht abgedeckt wird.",
		},
		options: [
			{ value: "hund", label: "Hund", icon: "Dog" },
			{ value: "pferd", label: "Pferd", icon: "Horse" },
		],
	},
	{
		control: "section",
		name: "fahrzeuge",
		label: "Fahrzeuge",
		description: "",
		cols: 12,
		children: [
			{
				control: "expandable",
				label_add: "Kraftfahrzeuge hinzufügen",
				name: "autos",
				label: "Kraftfahrzeug/e",
				icon: "Car",
				cols: 12,
				info: {
					title: "Hinweise",
					description:
						"Sofern Sie Halter/-in eines Kraftfahrzeuges sind, geben Sie dieses an. Für Kraftfahrzeuge besteht eine gesetzliche Versicherungspflicht. Hierunter fallen neben dem PKW auch Last- & Nutzkraftwagen sowie motorisierte Zweiräder.",
				},
				children: [
					{
						name: "bezeichnung",
						label: "Bezeichnung",
						default: "",
						control: "input",
						cols: 12,
					},
					{
						name: "wert",
						label: "Wert",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
					{
						name: "finanzierung",
						label: "Finanzierung",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
				],
			},

			{
				control: "expandable",
				label_add: "Luftfahrzeug hinzufügen",
				name: "flugzeuge",
				label: "Luftfahrzeug/e",
				icon: "Plane",
				cols: 12,
				info: {
					title: "Hinweise",
					description: "Sofern Sie Halter eines Luftfahrzeugs (inkl. ferngesteuerter Flugmodelle und Drohnen) sind, erfassen Sie bitte ein entsprechendes Luftfahrzeug.",
				},
				children: [
					{
						name: "bezeichnung",
						label: "Bezeichnung",
						control: "input",
						cols: 12,
					},
					{
						name: "wert",
						label: "Wert",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
					{
						name: "finanzierung",
						label: "Finanzierung",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
				],
			},

			{
				control: "expandable",
				label_add: "Wasserfahrzeug hinzufügen",
				name: "boote",
				label: "Wasserfahrzeug/e",
				icon: "Boat",
				cols: 12,
				info: {
					title: "Hinweise",
					description: "Sofern Sie Halter/-in eines Wasserfahrzeuges sind, geben Sie dieses an. Hierunter fallen Motor- & Segelboote, Ruderboote mit Außenbordmotor, Jet-Ski aber auch Wind- & Kitesurfen",
				},
				children: [
					{
						name: "bezeichnung",
						label: "Bezeichnung",
						control: "input",
						cols: 12,
					},
					{
						name: "wert",
						label: "Wert",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
					{
						name: "finanzierung",
						label: "Finanzierung",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
				],
			},

			{
				control: "expandable",
				label_add: "Sonstiges Fahrzeug hinzufügen",
				name: "sonstige_fahrzeuge",
				label: "Sonstige",
				icon: "Vehicle",
				cols: 12,
				info: {
					title: "Hinweise",
					description:
						"Sofern sich weitere Fahrzeuge in ihrem Besitz befinden, geben Sie diese an. Hierunter fallen unter anderem Fahrräder und andere Gefährte, für die keine verpflichtende Haftpflichtversicherung nötig ist, aber die Absicherung gegen den Verlust/Beschädigung sinnvoll ist.",
				},
				children: [
					{
						name: "bezeichnung",
						label: "Bezeichnung",
						control: "input",
						cols: 12,
					},
					{
						name: "wert",
						label: "Wert",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
					{
						name: "finanzierung",
						label: "Finanzierung",
						unit: "€",
						default: 0,
						min: 0,
						max: 250000,
						step: 100,
						unit_type: "currency_no_digit",
						control: "sliderinput",
						cols: 12,
					},
				],
			},
		],
	},
	{
		name: "hobbies_und_aktivitaeten",
		label: "Hobbies und Aktivitäten",
		control: "button_checkboxes",
		info: {
			title: "Hinweise",
			description: "Sofern Sie als Jäger/-in mit Jagdschein tätig sind, geben Sie dieses an. Es besteht eine gesetzliche Versicherungspflicht.",
		},
		cols: 12,
		options: [{ value: "jagdsport", label: "Jagdsport", icon: "Hunting" }],
	},
	{
		control: "expandable",
		label_add: "Ersatzinvestition hinzufügen",
		name: "ersatzinvestitionen",
		label: "Ersatzinvestitionen",
		icon: "Money",
		cols: 12,
		info: {
			title: "Hinweise",
			description:
				"Nahezu alle Gebrauchsgüter im Haushalt haben eine begrenzte, oft vorhersehbare Nutzungsdauer. Sofern Sie über Sachwerte (ohne Immobilien) verfügen, für deren absehbaren Ersatz relevante Haushaltsmittel notwendig sind, geben Sie diese an.",
		},
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "input",
				cols: 6,
			},
			{
				name: "zielzeitpunkt",
				label: "Zielzeitpunkt",
				control: "year_month_picker",
				cols: 6,
			},
			{
				name: "wunschbetrag",
				label: "Wunschbetrag",
				unit: "€",
				default: 0,
				min: 0,
				max: 250000,
				step: 100,
				unit_type: "currency_no_digit",
				control: "sliderinput",
				cols: 12,
			},
		],
	},
	{
		control: "upload",
		name: "sdv_dokumente",
		label: "Dokumente (optional)",
		hide_label: true,
		multiple: true,
		dok_types: ["Gehaltsabrechnung", "Rentenbescheid", "sonstiges"],
	},
];
