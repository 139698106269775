/* eslint-disable react/prop-types */
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
	header: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 10,
	},
	headerRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		fontSize: 14,
		marginBottom: 10,
	},
	horizontalLineContainer: {
		display: "flex",
		flexDirection: "row",
	},
	horizontalLine: {
		height: 2,
		width: "100%",
	},
	verticalLine: {
		width: 2,
		height: "24px",
	},
});

export const Header = ({ pdfModel }) => (
	<View style={styles.header} fixed>
		<View style={styles.headerRow}>
			<Text render={({ pageNumber }) => pageNumber - 1} />
			<Text>Persönliches Finanzkonzept</Text>
		</View>
		<View style={styles.horizontalLineContainer}>
			<View style={[styles.horizontalLine, { backgroundColor: pdfModel.company.secondaryColor }]} />
			<View style={[styles.verticalLine, { backgroundColor: pdfModel.company.secondaryColor }]} />
		</View>
	</View>
);
