import React, { useState } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { useTheme } from "@mui/material/styles";
//import { useRecoilValue } from "recoil";
//import { contentPositionAtom } from "../recoil/atoms/contentPositionAtom";
import { Box, IconButton, Button, Input, InputAdornment, Typography, Pagination } from "@mui/material";
import { Close, Search, SearchOff } from "@mui/icons-material";
import { FileDownload, Add } from "@mui/icons-material";

DMTable.propTypes = {
	data: PropTypes.array,
	hidden: PropTypes.array,
	columns: PropTypes.array,
	actions: PropTypes.func,
	rowAction: PropTypes.func,
	initialStateConfig: PropTypes.object,
	searchword: PropTypes.string,
	handleCreate: PropTypes.func,
	fnRenderDetailPanel: PropTypes.func,
	labelNeu: PropTypes.string,
	showExport2Excel: PropTypes.bool,
};

export const flattenArray = (arr) => {
	return arr.map((obj) => Object.values(obj).join("")).join(" ");
};
export default function DMTable(props) {
	const { data, columns, actions, hidden, rowAction, initialStateConfig = {}, searchword = "", handleCreate = null, fnRenderDetailPanel = null, labelNeu = "Neu", showExport2Excel = true } = props;
	const [searchText, setSearchText] = useState("");
	const [searchBoxVisible, setSearchBoxVisible] = useState(false);
	//const { isMobile } = useRecoilValue(contentPositionAtom);
	const isMobile = false;
	const theme = useTheme();
	const hiddenColumns = {};
	const displayColumns = hidden && hidden.length > 0 ? columns.filter((x) => !hidden.includes(x.accessorKey)) : [...columns];
	hidden?.forEach((name) => {
		hiddenColumns[name] = false;
	});

	const filteredData = (data || []).filter((dataItem) => {
		const searchString = Object.values(dataItem)
			.filter((x) => x && typeof x === "string")
			.join(" ")
			.toLowerCase();
		return searchText ? searchString.includes(searchText.toLowerCase()) : true;
	});

	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const filteredDataToDisplay = filteredData.slice(startIndex, endIndex);

	const handleChange = (event, value) => {
		setCurrentPage(value);
	};

	const hideSearchBox = () => {
		setSearchText("");
		setSearchBoxVisible(false);
	};

	const getListSX = (col, item) => {
		let sx = {
			display: "flex",
			flexDirection: "column",
			p: "12px",
			mb: "16px",
			backgroundColor: "#EBEAED",
			borderRadius: "6px",
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: "clickableHover",
			":hover": {
				backgroundColor: rowAction ? "clickableHover" : "#EBEAED",
				cursor: rowAction ? "pointer" : "default",
			},
		};

		displayColumns.forEach((col) => {
			if (col.muiTableBodyCellProps) {
				let sx2 = col.muiTableBodyCellProps(undefined, item);
				sx = { ...sx, ...sx2.sx };
			}
		});
		return sx;
	};

	return (
		data && (
			<>
				{isMobile ? (
					<div>
						{!searchBoxVisible ? (
							<Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end", mb: "10px" }}>
								<IconButton onClick={() => setSearchBoxVisible(true)}>
									<Search />
								</IconButton>
							</Box>
						) : (
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", mb: "10px" }}>
								<Input
									autoFocus
									placeholder="Suche"
									value={searchText}
									onChange={(event) => setSearchText(event.target.value)}
									sx={{ backgroundColor: "#ffffff" }}
									inputProps={{
										sx: { backgroundColor: "#ffffff" },
									}}
									startAdornment={
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="end">
											<IconButton onClick={() => setSearchText("")}>
												<Close />
											</IconButton>
										</InputAdornment>
									}
								/>
								<IconButton onClick={hideSearchBox}>
									<SearchOff />
								</IconButton>
							</Box>
						)}
						{filteredDataToDisplay.map((item, index) => (
							<Box key={`tile_item_${index}`} sx={getListSX(displayColumns, item)} onClick={rowAction ? () => rowAction(item) : undefined}>
								{displayColumns.map((col, i) => (
									<Box key={`display_col_${i}`} sx={{ display: "flex", flexDirection: "row", alignItems: "top", mb: "5px" }}>
										<Box sx={{ flex: 1 }}>
											<Typography variant="regular_text_14px_semibold">{col.header}:</Typography>
										</Box>
										<Box sx={{ flex: 2 }}>
											<Typography variant="regular_text_14px_normal">{col.Cell ? col.Cell(undefined, item) : item[col.accessorKey]}</Typography>
										</Box>
									</Box>
								))}
							</Box>
						))}
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Pagination count={Math.ceil(filteredData.length / itemsPerPage)} page={currentPage} onChange={handleChange} />
						</Box>
					</div>
				) : (
					<Box sx={{ mt: "10px" }}>
						<MaterialReactTable
							muiTableRow
							columns={columns}
							data={data}
							muiTablePaperProps={{
								elevation: 0,
								sx: {
									borderStyle: "none",
									boxShadow: "none",
								},
							}}
							muiToolbarAlertBannerProps={{
								sx: { backgroundColor: theme.palette.clickableHover },
							}}
							muiTableProps={{
								sx: {
									tableLayout: "fixed",
								},
							}}
							muiTableHeadCellProps={{
								sx: {
									flex: "0 0 auto",
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#F1F1EF",
									backgroundColor: "#F1F1F1",
								},
								align: "left",
							}}
							muiTableBodyCellProps={{
								sx: {
									flex: "0 0 auto",
									borderWidth: "1px",
									borderStyle: "solid",
									borderColor: "#F1F1EF",
								},
							}}
							sx={{
								padding: "10px",
								width: "100%",
							}}
							enablePagination={true}
							enableColumnActions={true}
							enableColumnOrdering={false}
							enableGrouping={false}
							enableColumnFilters={true}
							enableDensityToggle={false}
							enableHiding={true}
							enableFullScreenToggle={false}
							manualGrouping={false}
							initialState={{
								...initialStateConfig,
								showGlobalFilter: true,
								pagination: { pageIndex: 0, pageSize: 10 },
								columnVisibility: hiddenColumns,
								...(searchword
									? {
											globalFilter: searchword,
											showGlobalFilter: true,
									  }
									: {}),
							}}
							enableGlobalFilter={true}
							enableRowActions={false} //{actions ? true : false}
							enableTopToolbar={true}
							filterFns={{
								lc_exact: (row, id, filterValue) => {
									const value = row.getValue(id);
									return String(value).toLowerCase().includes(filterValue?.toLowerCase());
								},
							}}
							globalFilterFn="lc_exact"
							positionActionsColumn="last"
							localization={MRT_Localization_DE}
							renderRowActions={({ row }) => {
								if (actions) return actions(row);
							}}
							muiTableBodyRowProps={({ row }) => ({
								hover: false,
								onClick: (event) => {
									if (rowAction && !row.id.includes(":")) rowAction(row.original);
								},
							})}
							renderDetailPanel={fnRenderDetailPanel ? ({ row }) => fnRenderDetailPanel(row) : undefined}
							renderTopToolbarCustomActions={({ table }) => (
								<Box
									sx={{
										display: "flex",
										gap: "16px",
										padding: "8px",
										flexWrap: "wrap",
									}}
								>
									{handleCreate && (
										<Button variant="contained" size="small" onClick={handleCreate} startIcon={<Add />}>
											{labelNeu}
										</Button>
									)}
									{showExport2Excel && (
										<Button startIcon={<FileDownload />} variant="outlined" size="small">
											Excel
										</Button>
									)}
								</Box>
							)}
						/>
					</Box>
				)}
			</>
		)
	);
}
