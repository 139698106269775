import { Avatar, Box, Typography, useTheme } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { getDisplayName } from "services/helperService";

Beziehungen.propTypes = {};

export default function Beziehungen() {
	const haushalt = useRecoilValue(haushaltAtom);
	const theme = useTheme();
	let rowOne = [];
	let rowTwo = haushalt?.kinder || [];

	rowOne.push(haushalt?.kunde);
	if (haushalt?.partner && (haushalt?.kunde.familienstand === "verheiratet" || haushalt?.kunde.familienstand === "partnerschaft" || haushalt?.partner.familienstand === "verheiratet" || haushalt?.partner.familienstand === "partnerschaft"))
		rowOne.push(haushalt?.partner);

	const widthStartEndRow1 = 100 / rowOne.length / 2 + 5;
	const widthRow1 = 80 / rowOne.length;

	const widthStartEndRow2 = 100 / rowTwo.length / 2;
	const widthRow2 = 100 / rowTwo.length;

	return (
		<Tile sx={{ height: undefined, backgroundColor: "#FFFCF5" }}>
			<Typography variant="h6">Beziehungen</Typography>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box sx={{ width: "10%", padding: "16px", textAlign: "center" }}></Box>
				{rowOne.map((item, index) => (
					<Box
						sx={{
							width: 80 / rowOne.length + "%",
							padding: "8px",
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
						key={index}
					>
						<Avatar
							sx={{
								width: "64px",
								height: "64px",
								mb: 1,
								bgcolor: "white",
								border: "1px solid rgba(0, 0, 0, 0.12)",
							}}
						>
							<CustomIcon name={item.geschlecht === "weiblich" ? "Femaleperson" : "Person"} size={48} />
						</Avatar>
						<Typography variant="body1">{getDisplayName(item)}</Typography>
						<Typography variant="body2">{index === 0 ? "Kunde" : "Partner"}</Typography>
					</Box>
				))}
				<Box sx={{ width: "10%", padding: "16px", textAlign: "center" }}></Box>
			</Box>
			{rowTwo.length > 0 && (
				<Fragment>
					{rowOne.length === 2 && (
						<Fragment>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Box
									sx={{
										width: widthStartEndRow1 + "%",
										height: "20px",
										borderRight: "1px solid #9E9E9E",
									}}
								></Box>
								<Box
									sx={{
										width: widthRow1 + "%",
										height: "20px",
										borderLeft: "1px solid #9E9E9E",
										borderRight: "1px solid #9E9E9E",
									}}
								></Box>
								<Box
									sx={{
										width: widthStartEndRow1 + "%",
										height: "20px",
										borderLeft: "1px solid #9E9E9E",
									}}
								></Box>
							</Box>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Box
									sx={{
										width: widthStartEndRow1 + "%",
										borderRight: "1px solid #9E9E9E",
									}}
								></Box>
								<Box
									sx={{
										width: widthRow1 + "%",
										borderBottom: "2px solid #9E9E9E",
									}}
								></Box>
								<Box
									sx={{
										width: widthStartEndRow1 + "%",
										borderLeft: "1px solid #9E9E9E",
									}}
								></Box>
							</Box>
						</Fragment>
					)}
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Box
							sx={{
								width: "50%",
								height: "20px",
								borderRight: "1px solid #9E9E9E",
							}}
						></Box>
						<Box
							sx={{
								width: "50%",
								height: "20px",
								borderLeft: "1px solid #9E9E9E",
							}}
						></Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Box
							sx={{
								width: widthStartEndRow2 + "%",
								borderRight: "1px solid #9E9E9E",
							}}
						></Box>
						<Box
							sx={{
								width: 100 - widthStartEndRow2 * 2 + "%",
								borderBottom: "2px solid #9E9E9E",
							}}
						></Box>
						<Box
							sx={{
								width: widthStartEndRow2 + "%",
								borderLeft: "1px solid #9E9E9E",
							}}
						></Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<Box
							sx={{
								width: widthStartEndRow2 + "%",
								height: "20px",
								borderRight: "1px solid #9E9E9E",
							}}
						></Box>
						{rowTwo.map((item, index) =>
							index < rowTwo.length - 1 ? (
								<Box
									key={index}
									sx={{
										width: widthRow2 + "%",
										borderLeft: "1px solid #9E9E9E",
										borderRight: "1px solid #9E9E9E",
										height: "20px",
									}}
								></Box>
							) : null
						)}
						<Box
							sx={{
								width: widthStartEndRow2 + "%",
								height: "20px",
								borderLeft: "1px solid #9E9E9E",
							}}
						></Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						{rowTwo.map((item, index) => (
							<Box
								sx={{
									width: widthRow2 + "%",
									padding: "8px",
									textAlign: "center",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "flex-start",
								}}
								key={index}
							>
								<Avatar
									sx={{
										width: "64px",
										height: "64px",
										mb: 1,
										bgcolor: "white",
										border: "1px solid rgba(0, 0, 0, 0.12)",
									}}
								>
									<CustomIcon name="Child" size={48} />
								</Avatar>
								<Typography variant="body1">{getDisplayName(item)}</Typography>
								<Typography variant="body2">Kind</Typography>
							</Box>
						))}
					</Box>
				</Fragment>
			)}
		</Tile>
	);
}
