export const defTemplateKind = [
	{
		name: "vorname",
		label: "Vorname",
		control: "input",
		default: "",
		cols: 4,
	},
	{
		name: "nachname",
		label: "Nachname",
		control: "input",
		cols: 4,
	},
	{
		name: "geburtsdatum",
		label: "Geburtsdatum",
		control: "date_picker",
		cols: 4,
		default: "",
	},

	{
		name: "geschlecht",
		label: "Geschlecht",
		control: "button_options",
		cols: 12,
		hide_label: true,
		default: "keine_angabe",
		options: [
			{ value: "maennlich", label: "männlich", icon: "Masculin" },
			{ value: "weiblich", label: "weiblich", icon: "Feminin" },
			{ value: "divers", label: "divers", icon: "Diverse" },
		],
	},
	{
		name: "taetigkeitsstatus",
		label: "Tätigkeitsstatus",
		control: "button_options",
		cols: 12,
		options: [
			{ value: "angestellt", label: "Angestellt", icon: "Selfemployed1" },
			{ value: "angestell_oeffentlicher_dienst", label: "Angestellt öffentlicher Dienst", icon: "Civilwork" },
			{ value: "selbstaendig_freiberufler", label: "Selbstständig/ Freiberufler", icon: "Selfemployed" },
			{ value: "beamtet", label: "Beamtet", icon: "Civilwork" },
			{ value: "hausfrau_mann", label: "Hausfrau/-mann", icon: "Home" },
			{ value: "studierend", label: "Schüler/Student", icon: "Study" },
			{ value: "rentner", label: "in Rente", icon: "Elderly" },
		],
	},
	{
		control: "section",
		name: "besondere_merkmale",
		label: "Besondere Merkmale",
		cols: 12,
		children: [
			{
				name: "freiwilligendienst",
				label: "Freiwilligendienst",
				control: "switch",
				label_placement: "start",
				icon: "Married",
				default: false,
				cols: 12,
			},
			{
				name: "behinderung",
				label: "hat eine Behinderung",
				control: "switch",
				label_placement: "start",
				icon: "Married",
				default: false,
				cols: 12,
			},
			{
				name: "verheiratet",
				label: "Verheiratet",
				control: "switch",
				label_placement: "start",
				icon: "Married",
				default: false,
				cols: 12,
			},
		],
	},
	{
		name: "kindergeldanspruch",
		label: "Kindergeldanspruch im Haushalt",
		control: "switch",
		label_placement: "start",
		icon: "Money",
		default: false,
		cols: 12,
	},
];
