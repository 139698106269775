import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { isItemHidden } from "../DMFhelperService";
import { Box } from "@mui/material";

MDDDatepicker.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	size: PropTypes.string,
	trigger: PropTypes.func,
	disabled: PropTypes.bool,
	getValues: PropTypes.func,
};

function MDDDatepicker(props) {
	const { item, variant = "outlined", control, size = "small", trigger, disabled, getValues } = props;
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	return (
		<Box sx={{ display: hidden ? "none" : "block", pb: "8px", pt: "0px" }}>
			<Controller
				name={item.name}
				control={control}
				render={({ field: { onChange, value }, fieldState: { error } }) => {
					return (
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
							<DatePicker
								views={item.views || ["day", "month", "year"]}
								disabled={disabled}
								label={item.label}
								control={control}
								inputFormat="DD-MM-YYYY"
								value={dayjs(value)}
								onChange={onChange}
								variant={variant}
								sx={{ width: "100%" }}
								slotProps={{
									textField: {
										size: size,
										variant: variant,
										error: false,
										helperText: "",
										onBlur: () => {
											trigger(item.name);
										},
									},
								}}
								onAccept={(newValue) => {
									onChange(newValue);
									trigger(item.name);
								}}
								onClose={() => {
									trigger(item.name);
								}}
							/>
						</LocalizationProvider>
					);
				}}
			/>
		</Box>
	);
}

export default MDDDatepicker;
