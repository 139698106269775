import { DeleteForever, Preview } from "@mui/icons-material";
import { Box, Button, TextField, Typography, useTheme, Chip } from "@mui/material/";
import CustomIcon from "components/CustomIcon";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

DnDFileUpload.propTypes = {
	upload: PropTypes.array,
	setUpload: PropTypes.func,
	allowedExtensions: PropTypes.array,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool,
	add_description: PropTypes.bool,
};

export default function DnDFileUpload(props) {
	const { upload = {}, setUpload, allowedExtensions = [], disabled = false, multiple = false, add_description = false } = props;
	const [dragging, setDragging] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const fileInputRef = useRef(null);
	const [documentToView, setDocumentToView] = useState(null);
	const itemHeight = add_description ? "56px" : "36px";
	const theme = useTheme();

	const handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!disabled) setDragging(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!disabled) setDragging(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!disabled) {
			setDragging(false);
			handleFiles(Array.from(e.dataTransfer.files));
		}
	};

	const removeFile = (itemToRemove) => {
		setUpload(upload.filter((item) => item !== itemToRemove));
	};

	const handleFileInputChange = (e) => {
		if (!disabled) handleFiles(Array.from(e.target.files));
	};

	const isExtensionAllowed = (filename) => {
		const extension = filename.split(".").pop().toLowerCase();
		const loweredExtensions = allowedExtensions.map((extension) => extension.toLowerCase());
		return loweredExtensions.includes(extension);
	};

	const handleChangeBezeichnung = (e, item) => {
		setUpload(
			upload.map((singleFile) => {
				if (singleFile.id === item.id) {
					return { ...singleFile, label: e.target.value };
				} else return singleFile;
			})
		);
	};

	const getFileType = (filename) => {
		let filetype = "Download";
		const filenameLower = filename.toLowerCase().trim();
		if (filenameLower.endsWith(".txt")) filetype = "TXT";
		if (filenameLower.endsWith(".pdf")) filetype = "PDF";
		if (filenameLower.endsWith(".msg")) filetype = "MSG";
		if (filenameLower.endsWith(".eml")) filetype = "EML";
		if (filenameLower.endsWith(".png")) filetype = "Image";
		if (filenameLower.endsWith(".jpg")) filetype = "Image";
		if (filenameLower.endsWith(".jpeg")) filetype = "Image";
		if (filenameLower.endsWith(".tif")) filetype = "Image";
		if (filenameLower.endsWith(".tiff")) filetype = "Image";
		if (filenameLower.endsWith(".xls")) filetype = "Download";
		if (filenameLower.endsWith(".xlsx")) filetype = "Download";
		return filetype;
	};

	const handleFiles = async (files) => {
		let newFiles = [...upload];

		const filePromises = files.map((uploadFile) => {
			return new Promise((resolve, reject) => {
				if (isExtensionAllowed(uploadFile.name)) {
					const reader = new FileReader();

					reader.onload = (e) => {
						const fileContent = e.target.result;
						const newFileItem = {
							id: uuidv4(),
							filename: uploadFile.name,
							filesize: uploadFile.size,
							mimetype: uploadFile.type,
							filetype: getFileType(uploadFile.name),
							filedata: fileContent,
							label: add_description ? "" : uploadFile.name,
						};
						if (!multiple) newFiles = [];
						newFiles.push(newFileItem);
						resolve();
					};
					reader.readAsDataURL(uploadFile);
				} else {
					enqueueSnackbar("Ungültige Dateiendung '" + uploadFile.name.split(".").pop().toLowerCase() + "' - Folgende Dateiendungen sind gültig: " + allowedExtensions.join(", "), { variant: "error" });
					reject();
				}
			});
		});

		try {
			await Promise.all(filePromises);
			setUpload(newFiles);
		} catch (error) {
			enqueueSnackbar("Fehler bei der Verarbeitung der hochgeladenen Dateien!", { variant: "error" });
		}
	};

	const openFileDialog = () => {
		if (!disabled && fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const showDocument = (document) => {
		document.success = true;

		setDocumentToView(document);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ display: "flex" }}>
				<Box
					sx={{
						border: "1px",
						borderStyle: "solid",
						borderRadius: "8px",
						borderColor: "#E6E6E6",
						padding: "0px",
						textAlign: "center",
						marginRight: "8px",
						width: "50%",
						":hover": {
							cursor: disabled ? "default" : "pointer",
							backgroundColor: disabled ? theme.palette.grey[300] : theme.palette.grey[100],
						},
						opacity: disabled ? 0.6 : 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
					onDragEnter={handleDragEnter}
					onDragOver={handleDragEnter}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
					onClick={openFileDialog}
				>
					<input type="file" multiple={multiple} ref={fileInputRef} style={{ display: "none" }} onChange={handleFileInputChange} />
					<Box sx={{ padding: "16px" }}>
						<Box sx={{ pb: "32px", height: "72px" }}>
							<Typography variant="body2">{dragging ? "Datei hier ablegen" : "Per Drag & Drop ablegen oder"}</Typography>
						</Box>
						<Button variant="outlined" sx={{ alignSelf: "flex-end", textTransform: "none" }}>
							<CustomIcon name="FileUpload" size={20} />
							<Typography variant="button" sx={{ pl: "8px", textTransform: "none" }}>
								Hochladen
							</Typography>
						</Button>
					</Box>
				</Box>

				<Box
					sx={{
						border: "1px",
						borderStyle: "solid",
						borderRadius: "8px",
						borderColor: "#E6E6E6",
						padding: "0px",
						textAlign: "center",
						width: "50%",

						opacity: 0.6,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					{/*
					sx={{
						border: "1px",
						borderStyle: "solid",
						borderRadius: "8px",
						borderColor: "#E6E6E6",
						padding: "0px",
						textAlign: "center",
						width: "50%",
						":hover": {
							cursor: disabled ? "default" : "pointer",
							backgroundColor: disabled ? "lightgray" : "clickableHover",
						},
						opacity: disabled ? 0.6 : 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				*/}
					<Box sx={{ padding: "8px" }}>
						<Box sx={{ width: "100%", textAlign: "right" }}>
							<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
						</Box>
						<Box sx={{ height: "72px" }}>
							<Typography variant="body2">oder QR-Code scannen und Handyfoto hochladen</Typography>
						</Box>
						<Button variant="outlined" sx={{ alignSelf: "flex-end", textTransform: "none" }}>
							<CustomIcon name="QRCode" size={20} />
							<Typography variant="button" sx={{ pl: "8px", textTransform: "none" }}>
								QR Code
							</Typography>
						</Button>
					</Box>
				</Box>
			</Box>

			<Box sx={{ mt: "20px", pl: "0px", pr: "0px" }}>
				{upload &&
					upload.length > 0 &&
					upload.map((item, index) => (
						<Box
							key={index}
							sx={{
								mt: "5px",
								paddingLeft: "8px",
								paddingRight: "5px",
								paddingTop: "5px",
								paddingBottom: "5px",
								borderColor: "#E6E6E6",
								backgroundColor: "#FCFCFC",
								borderWidth: "1px",
								borderStyle: "solid",
								borderRadius: "4px",
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "space-between",
							}}
						>
							{add_description ? (
								<Box sx={{ flex: 1, height: itemHeight, pr: "10px" }}>
									<TextField
										sx={{ mt: "0px" }}
										InputProps={{ sx: { fontSize: "12px", height: "35px" } }}
										InputLabelProps={{ sx: { fontSize: "12px", top: "-2px", "&.MuiInputLabel-shrink": { top: "-1px" } } }}
										size="small"
										fullWidth
										value={item.label}
										onChange={(e) => handleChangeBezeichnung(e, item)}
										label="Bezeichnung"
										variant="filled"
									/>
									<Typography variant="regular_text_12px_medium">
										{item.filename}; {Math.round(item.filesize / 1024).toLocaleString("de-DE") + " KB"}
									</Typography>
								</Box>
							) : (
								<Box sx={{ display: "flex", alignItems: "center", height: itemHeight }}>
									<Typography variant="regular_text_16px_medium">
										{item.filename}; {Math.round(item.filesize / 1024).toLocaleString("de-DE") + " KB"}
									</Typography>
								</Box>
							)}

							<Box sx={{ display: "flex" }}>
								<Button variant="outlined" sx={{ height: "36px", minWidth: "36px", width: "36px" }} disabled={disabled} onClick={(e) => removeFile(item)}>
									<DeleteForever />
								</Button>
								&nbsp;
								<Button variant="outlined" sx={{ height: "36px", minWidth: "36px", width: "36px" }} disabled={disabled} onClick={(e) => showDocument(item)}>
									<Preview />
								</Button>
							</Box>
						</Box>
					))}
			</Box>
		</Box>
	);
}
