import { Box, Typography, IconButton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { ContentDialog, ContentDialogInit } from "../components/ContentDialog";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { isItemHidden } from "../DMFhelperService";

DMF_Section.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	children: PropTypes.object,
	getValues: PropTypes.func,
};

function DMF_Section(props) {
	const { item, children, control, getValues } = props;
	const { openCD, setOpenCD, tca, showDescription } = ContentDialogInit();
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	return (
		<Box sx={{ pt: "16px", pb: "0px", display: hidden ? "none" : "block" }}>
			<ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} />
			<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				<Typography variant="h6" sx={{ pb: item.description ? "0px" : "16px" }}>
					{item.label}
				</Typography>
				{item.description && (
					<Typography variant="body" sx={{ pb: "16px" }}>
						{item.description}
					</Typography>
				)}
				{item.info && (
					<IconButton onClick={() => showDescription(item)} sx={{ width: "40px", height: "40px" }}>
						<CustomIcon name="Info" />
					</IconButton>
				)}
			</Box>
			{children}
		</Box>
	);
}

export default DMF_Section;
