import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 2,
		fontSize: 8,
		marginTop: 4,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingTop: 2,
		paddingBottom: 2,
	},
	col1: {
		width: "70%",
		paddingLeft: 8,
	},
	col2: {
		width: "30%",
		textAlign: "right",
		paddingRight: 8,
	},
});

export const RahmenparameterTable = ({ data }) => (
	<View style={styles.table}>
		{data.map((row, i) => (
			<View key={`basic_table_row_${i}`} style={[styles.row, i !== 0 && { borderTop: "1px solid gray" }]} wrap={false}>
				<Text style={styles.col1}>{row.label}</Text>
				<Text style={styles.col2}>{row.value}</Text>
			</View>
		))}
	</View>
);

RahmenparameterTable.propTypes = {
	data: PropTypes.array,
};
