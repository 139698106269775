import { Box, Chip, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CustomIcon from "./CustomIcon";
import { getDatumZeit } from "services/formatService";
import { Delete } from "@mui/icons-material";

Notiz.propTypes = {
    notiz: PropTypes.object,
    index: PropTypes.number,
    isLastItem: PropTypes.bool,
    onSelectNote: PropTypes.func,
    isNoteActive: PropTypes.bool,
    onDeleteNote: PropTypes.func
};

export default function Notiz({ notiz, index, isLastItem, onSelectNote, isNoteActive, onDeleteNote }) {
    return (
        <Box
            onClick={() => onSelectNote(notiz)}
            sx={{
                display: "flex",
                flexDirection: "column",
                p: "16px",
                cursor: "pointer",
                borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                borderTop: index === 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "",
                borderTopLeftRadius: index === 1 ? "8px" : "0px",
                borderTopRightRadius: index === 1 ? "8px" : "0px",
                borderBottomRightRadius: isLastItem ? "8px" : "0px",
                borderBottomLeftRadius: isLastItem ? "8px" : "0px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                backgroundColor: isNoteActive ? "rgba(0, 0, 0, 0.12)" : "inherit"
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", mb: "4px" }}>
                <CustomIcon name="Notizen" />
                <Typography variant="h6" sx={{ ml: "8px", color: "rgba(0, 0, 0, 0.87)" }}>{`Notiz #${index}`}</Typography>
                {
                    isNoteActive &&
                    <IconButton sx={{ ml: "auto" }} onClick={() => onDeleteNote(notiz)}>
                        <Delete />
                    </IconButton>
                }
            </Box>
            <Typography variant="caption">{getDatumZeit(notiz.dateCreated)}</Typography>
            <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.87)", mb: "10px" }}>{notiz.notizText}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {
                    notiz.notizTags.map((tag, index) => (
                        <Chip key={`tag_${index}`} label={tag.label} variant="outlined" sx={{ marginRight: "4px" }} />
                    ))
                }
            </Box>
        </Box>
    )
}
