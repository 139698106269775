/* eslint-disable react/prop-types */
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";

const styles = StyleSheet.create({
	frontPage: {
		display: "flex",
		flexDirection: "column",
		fontFamily: "FoundryMonoline",
	},
	frontPageContent: {
		display: "flex",
		flexDirection: "column",
		padding: "30px 20px",
	},
	frontPageHeader: {
		fontWeight: "light",
		fontSize: 40,
		marginBottom: 10,
	},
	frontPageSubHeaderContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		padding: "10px 0px",
		marginBottom: 30,
	},
	frontPageSubHeader: {
		fontSize: 24,
		textTransform: "uppercase",
	},
	frontPageLabelsRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		gap: 20,
	},
	frontPageLabelContainer: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
	frontPageLabel: {
		fontSize: 12,
		color: "#000000",
		opacity: 0.6,
		marginBottom: 5,
	},
	frontPageText: {
		fontSize: 14,
		color: "#000000",
		opacity: 0.87,
	},
	footerContainer: {
		marginTop: "auto",
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
	},
});

export const FrontPage = ({ pdfModel }) => (
	<Page size="A4" style={styles.frontPage} orientation="landscape">
		<View>
			<Image src={pdfModel.company.frontPageImageUrl} />
		</View>
		<View style={{ position: "absolute", top: "20px", right: "20px" }}>
			<Image src="logos/DIN77230_vertikal.png" style={{ width: "100px" }} />
		</View>
		<View style={styles.frontPageContent}>
			<Text style={[styles.frontPageHeader, { color: pdfModel.company.primaryColor }]}>PERSÖNLICHES FINANZKONZEPT</Text>
			<View style={[styles.frontPageSubHeaderContainer, { borderTop: `2px solid ${pdfModel.company.primaryColor}`, borderRight: `2px solid ${pdfModel.company.primaryColor}` }]}>
				<Text style={[styles.frontPageSubHeader, { color: pdfModel.company.secondaryColor }]}>Es gibt immer nur eine richtige Lösung!</Text>
			</View>
			<View style={styles.frontPageLabelsRow}>
				<View style={styles.frontPageLabelContainer}>
					<Text style={styles.frontPageLabel}>Zertifizierte Finanzanalyse von</Text>
					<Text style={styles.frontPageText}>
						{pdfModel.bedarf.kunde.vorname} {pdfModel.bedarf.kunde.nachname}
					</Text>
				</View>
				<View style={styles.frontPageLabelContainer}>
					<Text style={styles.frontPageLabel}>FORMAXX Berater/in</Text>
					<Text style={styles.frontPageText}>
						{pdfModel.berater.vorname} {pdfModel.berater.nachname}
					</Text>
				</View>
				<View style={styles.frontPageLabelContainer}>
					<Text style={styles.frontPageLabel}>Erstellt am</Text>
					<Text style={styles.frontPageText}>{pdfModel.createdAt}</Text>
				</View>
			</View>
		</View>
		<View style={styles.footerContainer}>
			<Footer pdfModel={pdfModel} />
		</View>
	</Page>
);
