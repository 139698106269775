import { atom } from "recoil";

export const appWindowAtom = atom({
  key: "appWindowAtom",
  default: {
    width: 0,
    height: 0,
    contentWidth: 0,
    contentHeight: 0,
    useableWidth: 0,
    useableHeight: 0,
    contentAlign: "left",
    headerHeight: 0,
    isMobile: false,
  },
});
