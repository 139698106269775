import { ContentCopy } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import { useSnackbar } from "notistack";
import { useRecoilValue } from "recoil";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";

Telekommunikation.propTypes = {};

export default function Telekommunikation() {
  const haushalt = useRecoilValue(haushaltAtom);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const telekommunikation = [];
  if (haushalt?.kunde.tel_geschaeftlich) telekommunikation.push({ label: "Telefon geschäftlich", value: haushalt?.kunde.tel_geschaeftlich, icon_name: "Phone" });
  if (haushalt?.kunde.tel_privat) telekommunikation.push({ label: "Telefon privat", value: haushalt?.kunde.tel_privat, icon_name: "Phone" });
  if (haushalt?.kunde.tel_mobil) telekommunikation.push({ label: "Telefon mobil", value: haushalt?.kunde.tel_mobil, icon_name: "Phone" });
  if (haushalt?.kunde.email) telekommunikation.push({ label: "E-Mail", value: haushalt?.kunde.email, icon_name: "Mail" });

  const copyToClipboard = async (label, toCopy) => {
    try {
      await navigator.clipboard.writeText(toCopy);
      enqueueSnackbar("'" + label + "' erfolgreich in die Zwischenablage kopiert.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Fehler beim Kopieren in die Zwischenablage.", {
        variant: "error",
      });
    }
  };

  return (
    <Tile
      sx={{
        width: "100%",
        height: undefined,
        padding: "0px",
        mb: "8px",
      }}
    >
      {telekommunikation.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexGrow: "0",
              alignItems: "center",
              padding: "8px",
              ...(index !== telekommunikation.length - 1 && {
                borderBottom: "1px solid #E6E6E6",
              }),
              ":hover": {
                cursor: "pointer",
                backgroundColor: theme.palette.grey[100],
              },
            }}
            onClick={() => copyToClipboard(item.label, item.value)}
          >
            <CustomIcon name={item.icon_name} size={48} />
            <Box sx={{ pl: "8px", flexGrow: "1" }}>
              <Typography variant="body1" component="div">
                {item.label}
              </Typography>
              <Typography variant="body2" component="span">
                {item.value}
              </Typography>
            </Box>
            <ContentCopy sx={{ width: "24px" }} />
          </Box>
        );
      })}
    </Tile>
  );
}
