import { atom } from "recoil";

export const visualizationAtom = atom({
  key: "visualizationAtom",
  default: {
    background: true,
    oil: false,
    miete: false,
    immobilien: false,
    eigentum: false,
    photovoltaik: false,
    auto: false,
    auto2: false,
    mann: false,
    mann_tasche: false,
    mann_gehstock: false,
    mann_jagd: false,
    frau_gehstock: false,
    frau_tasche: false,
    frau_jagd: false,
    pferd: false,
    boot: false,
    kind_maedchen: false,
    kind_junge: false,
    kind_weitere: false,
    drohne: false,
    ziele: false,
    ziel_fernseher: false,
    ziel_auto: false,
    ziel_appartement: false,
    ziel_ruhestand: false,
    ziel_liquiditaet: false,
    ziel_umzug: false,
    ziel_fortbildung: false,
    ziel_eigentum: false,
    ziel_neues_hobby: false,
  },
});
