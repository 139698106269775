import { Box } from "@mui/material";
import { containerRendererFlex, getNestedValue, itemRendererFlex } from "components/FormRenderer/DMFhelperService";
import FormRenderer from "components/FormRenderer/FormRenderer";
import { fdefDIN77230 } from "formdefs/din";
import BackendLayout from "layouts/BackendLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { visualizationAtom } from "recoil/atoms/visualizationAtom";
import { backendPost, backendGetSync } from "services/dataService";
import { enqueueSnackbar } from "notistack";
import { getAlter } from "services/formatService";
import { getDisplayName } from "services/helperService";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { get } from "lodash";

const isNU = (value) => {
	if (value === null || value === undefined) return true;
	return false;
};

export default function Bedarfsermittlung() {
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const [appWindow, setAppWindow] = useRecoilState(appWindowAtom);
	const [visualization, setVisualization] = useRecoilState(visualizationAtom);
	const [formValues, setFormValues] = useState({});
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setAppWindow({
			...appWindow,
			contentInfo: null,
		});
	}, []);

	const storeBedarf = async (values) => {
		if (values.kinder) values.kinder = values.kinder.filter((kind) => kind.uuid);
		backendPost({ label: "Bedarf", route: "bedarf/store", toSend: values, setResult: setSubmitResult, setIsLoading: setIsLoading, enqueueSnackbar: null });
	};

	const setSubmitResult = (data) => {
		setBedarf(data.bedarf);
		setHaushalt({ kunde: data.bedarf.kunde, partner: data.bedarf.partner, kinder: data.bedarf.kinder });
	};

	const valuesCallBack = useCallback((values, setValue) => {
		console.log("*** valuesCallBack", values);

		for (let index = 0; index < values.kinder?.length; index++) {
			let kind = values.kinder[index];
			if (kind.kindergeldanspruch === null) {
				let anspruch = false;
				if (getAlter(kind.geburtsdatum) < 18) anspruch = true;
				if (getAlter(kind.geburtsdatum) < 25 && kind.taetigkeitsstatus !== null) anspruch = true;
				if (kind.taetigkeitsstatus === "behinderung" && (kind.verheiratet === null || kind.verheiratet === false)) anspruch = true;
				setValue("kinder[" + index + "].kindergeldanspruch", anspruch);
				console.log("*** kindergeldanspruch", anspruch);
			}
		}

		if (values.kunde.taetigkeitsstatus === "angestellt" && isNU(values.kunde.versicherung_kranken_pflege)) {
			setValue("kunde.versicherung_kranken_pflege", "gkv");
		}
		if (values.kunde.taetigkeitsstatus === "selbstaendig_freiberufler" && isNU(values.kunde.versicherung_kranken_pflege)) {
			setValue("kunde.versicherung_kranken_pflege", "pkv");
		}

		if (values.partner.taetigkeitsstatus === "angestellt" && isNU(values.partner.versicherung_kranken_pflege)) {
			setValue("partner.versicherung_kranken_pflege", "gkv");
		}
		if (values.partner.taetigkeitsstatus === "selbstaendig_freiberufler" && isNU(values.partner.versicherung_kranken_pflege)) {
			setValue("partner.versicherung_kranken_pflege", "pkv");
		}

		const getArrayLength0 = (values, aname) => {
			const aKunde = values.kunde?.[aname];
			let lKunde = 0;
			if (aKunde !== undefined) lKunde = aKunde.length;

			const aPartner = values.partner?.[aname];
			let lPartner = 0;
			if (aPartner !== undefined) lPartner = aPartner.length;

			const aHaushalt = values.haushalt?.[aname];
			let lHaushalt = 0;
			if (aHaushalt !== undefined) lHaushalt = aHaushalt.length;

			return lKunde + lPartner + lHaushalt;
		};

		const getArrayLength = (values, aname, field_name, compare) => {
			const aKunde = values.kunde?.[aname]?.find((item) => item[field_name] === compare);
			let lKunde = 0;
			if (aKunde !== undefined) lKunde = 1;

			const aPartner = values.partner?.[aname]?.find((item) => item[field_name] === compare);
			let lPartner = 0;
			if (aPartner !== undefined) lPartner = 1;

			const aHaushalt = values.haushalt?.[aname]?.find((item) => item[field_name] === compare);
			let lHaushalt = 0;
			if (aHaushalt !== undefined) lHaushalt = 1;
			return lKunde + lPartner + lHaushalt > 0;
		};

		const integrateVermoegenswerteVerbindlichkeiten = (items, bezeichnung) => {
			if (items.length === 0) return;
			const newValueVerkehrswert = items.reduce((acc, item) => acc + item.verkehrswert, 0);
			const newArrayVermoegenswerte = [{ bezeichnung: bezeichnung, wert: newValueVerkehrswert, protected: true }].concat(getNestedValue(values, "haushalt.vermoegenswerte").filter((item) => item.bezeichnung !== bezeichnung && item));

			setValue(
				"haushalt.vermoegenswerte",
				newArrayVermoegenswerte.filter((item) => item.wert > 0 || !item.protected)
			);

			const newValueVerbindlichkeit = items.reduce((acc, item) => acc + item.darlehensstand, 0);
			const newArrayVerbindlichkeiten = [{ bezeichnung: bezeichnung, wert: newValueVerbindlichkeit, protected: true }].concat(getNestedValue(values, "haushalt.verbindlichkeiten").filter((item) => item.bezeichnung !== bezeichnung && item));

			setValue(
				"haushalt.verbindlichkeiten",
				newArrayVerbindlichkeiten.filter((item) => item.wert > 0 || !item.protected)
			);
		};

		if (values) {
			//durlaufe alle Wohnsitze und setze für mitojbekte und eigene_immobilien hausrat_wert mit wohnflaeche * 650. Speichere das geänderte Objekt in values
			const wohnsitze = values.haushalt?.wohnsitz;
			wohnsitze.forEach((wohnsitz, index) => {
				if (wohnsitz.auswahl === "eigentum") {
					const eigeneImmobilie = wohnsitze[index].eigene_immobilie;
					if (!eigeneImmobilie.hausrat_manuell) {
						setValue("haushalt.wohnsitz[" + index + "].eigene_immobilie.hausrat_wert", eigeneImmobilie.wohnflaeche * 650);
					}
				}
				if (wohnsitz.auswahl === "zur_miete") {
					const mietobjekt = wohnsitze[index].mietobjekt;
					if (!mietobjekt.hausrat_manuell) {
						setValue("haushalt.wohnsitz[" + index + "].mietobjekt.hausrat_wert", mietobjekt.wohnflaeche * 650);
					}
				}
			});

			const eigentum = values.haushalt?.wohnsitz.filter((wohnsitz) => wohnsitz.auswahl === "eigentum");

			setValue(
				"kunde.nettogesamtvermoegen",
				Number(getNestedValue(values, "kunde.monatseinkommen.nettogehalt")) + Number(getNestedValue(values, "kunde.monatseinkommen.nettovermoegenseinkommen")) + Number(getNestedValue(values, "kunde.monatseinkommen.sonstige_einnahmen"))
			);

			setValue(
				"partner.nettogesamtvermoegen",
				Number(getNestedValue(values, "partner.monatseinkommen.nettogehalt")) +
					Number(getNestedValue(values, "partner.monatseinkommen.nettovermoegenseinkommen")) +
					Number(getNestedValue(values, "partner.monatseinkommen.sonstige_einnahmen"))
			);

			if (values.kinder?.length > 0) {
				values?.kinder?.forEach((kind, index) => {
					setValue(
						"kinder[" + index + "].nettogesamtvermoegen",
						Number(getNestedValue(values, "kinder[" + index + "].monatseinkommen.nettogehalt")) +
							Number(getNestedValue(values, "kinder[" + index + "].monatseinkommen.nettovermoegenseinkommen")) +
							Number(getNestedValue(values, "kinder[" + index + "].monatseinkommen.sonstige_einnahmen"))
					);
				});
			}

			//Vermögenswerte und Verbindlichkeiten
			//automatische Ergänzung Aktiva und Passiva
			//Workaround: alle zuvor geschützten Einträge entfernen

			// als protected einfügen: Rückkaufswerte der LV's, Kontostände
			setValue(
				"haushalt.vermoegenswerte",
				getNestedValue(values, "haushalt.vermoegenswerte").filter((item) => item.protected !== true)
			);
			setValue(
				"haushalt.verbindlichkeiten",
				getNestedValue(values, "haushalt.verbindlichkeiten").filter((item) => item.protected !== true)
			);

			if (eigentum.length > 0) {
				let newValueVerkehrswert = eigentum.reduce((sum, objekt) => sum + objekt.eigene_immobilie.verkehrswert, 0);
				let newValueVerbindlichkeit = eigentum.reduce((sum, objekt) => sum + objekt.eigene_immobilie.darlehensstand, 0);

				const newArrayVermoegenswerte = [{ bezeichnung: "wohnsitze", wert: newValueVerkehrswert, protected: true }].concat(getNestedValue(values, "haushalt.vermoegenswerte").filter((item) => item.bezeichnung !== "wohnsitze" && item));
				setValue("haushalt.vermoegenswerte", newArrayVermoegenswerte);

				const newArrayVerbindlichkeiten = [{ bezeichnung: "wohnsitze", wert: newValueVerbindlichkeit, protected: true }].concat(
					getNestedValue(values, "haushalt.verbindlichkeiten").filter((item) => item.bezeichnung !== "wohnsitze" && item)
				);

				setValue("haushalt.verbindlichkeiten", newArrayVerbindlichkeiten);
			}

			const immobilien = getNestedValue(values, "haushalt.immobilien");
			integrateVermoegenswerteVerbindlichkeiten(immobilien, "weitere_immobilien");

			const grundstuecke = getNestedValue(values, "haushalt.grundstuecke");
			integrateVermoegenswerteVerbindlichkeiten(grundstuecke, "grundstuecke");

			let fahrzeugeVerkehrswert = 0;
			let fahrzeugeDarlehensstand = 0;
			const autos = getNestedValue(values, "haushalt.fahrzeuge.autos");
			const flugzeuge = getNestedValue(values, "haushalt.fahrzeuge.flugzeuge");
			const boote = getNestedValue(values, "haushalt.fahrzeuge.boote");
			if (autos.length > 0) {
				fahrzeugeVerkehrswert += autos.reduce((acc, item) => acc + item.wert, 0);
				fahrzeugeDarlehensstand += autos.reduce((acc, item) => acc + item.finanzierung, 0);
			}
			if (flugzeuge.length > 0) {
				fahrzeugeVerkehrswert += flugzeuge.reduce((acc, item) => acc + item.wert, 0);
				fahrzeugeDarlehensstand += flugzeuge.reduce((acc, item) => acc + item.finanzierung, 0);
			}
			if (boote.length > 0) {
				fahrzeugeVerkehrswert += boote.reduce((acc, item) => acc + item.wert, 0);
				fahrzeugeDarlehensstand += boote.reduce((acc, item) => acc + item.finanzierung, 0);
			}
			if (fahrzeugeVerkehrswert > 0) {
				const newArrayVermoegenswerte = [{ bezeichnung: "fahrzeuge", wert: fahrzeugeVerkehrswert, protected: true }].concat(getNestedValue(values, "haushalt.vermoegenswerte").filter((item) => item.bezeichnung !== "fahrzeuge" && item));

				setValue("haushalt.vermoegenswerte", newArrayVermoegenswerte);
			}

			if (fahrzeugeDarlehensstand > 0) {
				const newArrayVerbindlichkeiten = [{ bezeichnung: "fahrzeuge", wert: fahrzeugeDarlehensstand, protected: true }].concat(
					getNestedValue(values, "haushalt.verbindlichkeiten").filter((item) => item.bezeichnung !== "fahrzeuge" && item)
				);

				setValue("haushalt.verbindlichkeiten", newArrayVerbindlichkeiten);
			}

			setValue("haushalt.liquiditaet", getNestedValue(values, "haushalt.einnahmen").reduce((acc, item) => acc + item.wert, 0) - getNestedValue(values, "haushalt.ausgaben").reduce((acc, item) => acc + item.wert, 0));

			let visualizationUpdates = {};

			//Kunde + Partner
			visualizationUpdates = {
				...visualizationUpdates,
				mann:
					getNestedValue(values, "kunde.geschlecht") === "maennlich" ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && getNestedValue(values, "partner.geschlecht") === "maennlich") ||
					getNestedValue(values, "kunde.geschlecht") === "divers" ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && getNestedValue(values, "partner.geschlecht") === "divers"),
			};
			visualizationUpdates = {
				...visualizationUpdates,
				frau: getNestedValue(values, "kunde.geschlecht") === "weiblich" || (getNestedValue(values, "kunde.familienstand") !== "ledig" && getNestedValue(values, "partner.geschlecht") === "weiblich"),
			};

			//Kinder
			visualizationUpdates = {
				...visualizationUpdates,
				kind_junge: getNestedValue(values, "kinder.0.geschlecht") === "maennlich" || getNestedValue(values, "kinder.1.geschlecht") === "maennlich" || getNestedValue(values, "kinder.2.geschlecht") === "maennlich",
			};
			visualizationUpdates = {
				...visualizationUpdates,
				kind_maedchen: getNestedValue(values, "kinder.0.geschlecht") === "weiblich" || getNestedValue(values, "kinder.1.geschlecht") === "weiblich" || getNestedValue(values, "kinder.2.geschlecht") === "weiblich",
			};
			visualizationUpdates = { ...visualizationUpdates, kind_weitere: values.kinder?.length > 2 && getNestedValue(values, "kinder.2.geschlecht") !== undefined };

			visualizationUpdates = { ...visualizationUpdates, pferd: values.haushalt?.haustiere?.includes("pferd") };
			visualizationUpdates = { ...visualizationUpdates, hund: values.haushalt?.haustiere?.includes("hund") };

			visualizationUpdates = { ...visualizationUpdates, auto: values.haushalt?.fahrzeuge?.autos.length > 0 };
			visualizationUpdates = { ...visualizationUpdates, auto2: values.haushalt?.fahrzeuge?.autos.length > 1 };
			visualizationUpdates = { ...visualizationUpdates, boot: values.haushalt?.fahrzeuge?.boote.length > 0 };
			visualizationUpdates = { ...visualizationUpdates, drohne: values.haushalt?.fahrzeuge?.flugzeuge.length > 0 };

			visualizationUpdates = { ...visualizationUpdates, eigentum: eigentum.length > 0 };

			visualizationUpdates = {
				...visualizationUpdates,
				photovoltaik: eigentum.filter((objekt) => objekt.eigene_immobilie.photovoltaik).length > 0,
			};

			visualizationUpdates = {
				...visualizationUpdates,
				oeltank: eigentum.filter((objekt) => objekt.eigene_immobilie.oeltank).length > 0,
			};

			//falls Eigentum vorhanden ist soll nur das Image für Eigentum angezeigt werden
			if (eigentum.length === 0) {
				const miete = values.haushalt?.wohnsitz.filter((wohnsitz) => wohnsitz.auswahl === "zur_miete");
				visualizationUpdates = { ...visualizationUpdates, miete: miete.length > 0 };
			}

			visualizationUpdates = { ...visualizationUpdates, immobilien: values.haushalt?.immobilien?.length > 0 };

			visualizationUpdates = {
				...visualizationUpdates,
				mann_gehstock:
					(values.kunde.taetigkeitsstatus === "rentner" && values.kunde.geschlecht === "maennlich") ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.taetigkeitsstatus === "rentner" && values.partner.geschlecht === "maennlich"),
			};
			visualizationUpdates = {
				...visualizationUpdates,
				mann_tasche:
					(values.kunde.taetigkeitsstatus !== "rentner" && values.kunde.geschlecht === "maennlich") ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.taetigkeitsstatus !== "rentner" && values.partner.geschlecht === "maennlich"),
			};

			visualizationUpdates = {
				...visualizationUpdates,
				frau_gehstock:
					(values.kunde.taetigkeitsstatus === "rentner" && values.kunde.geschlecht === "weiblich") ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.taetigkeitsstatus === "rentner" && values.partner.geschlecht === "weiblich"),
			};
			visualizationUpdates = {
				...visualizationUpdates,
				frau_tasche:
					(values.kunde.taetigkeitsstatus !== "rentner" && values.kunde.geschlecht === "weiblich") ||
					(getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.taetigkeitsstatus !== "rentner" && values.partner.geschlecht === "weiblich"),
			};

			visualizationUpdates = {
				...visualizationUpdates,
				mann_jagd: values.haushalt?.hobbies_und_aktivitaeten?.includes("jagdsport") && (values.kunde.geschlecht === "maennlich" || (getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.geschlecht === "maennlich")),
			};
			visualizationUpdates = {
				...visualizationUpdates,
				frau_jagd: values.haushalt?.hobbies_und_aktivitaeten?.includes("jagdsport") && (values.kunde.geschlecht === "weiblich" || (getNestedValue(values, "kunde.familienstand") !== "ledig" && values.partner.geschlecht === "weiblich")),
			};

			visualizationUpdates = { ...visualizationUpdates, ziele: getArrayLength0(values, "investitionsziele") > 0 || getArrayLength0(values, "kaufobjekte") > 0 };

			visualizationUpdates = { ...visualizationUpdates, ziel_fernseher: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_fernseher") };
			visualizationUpdates = { ...visualizationUpdates, ziel_auto: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_auto") };
			visualizationUpdates = { ...visualizationUpdates, ziel_appartement: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_appartement") };
			visualizationUpdates = { ...visualizationUpdates, ziel_auszeit: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_auszeit") };
			visualizationUpdates = { ...visualizationUpdates, ziel_ruhestand: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_ruhestand") };
			visualizationUpdates = { ...visualizationUpdates, ziel_weltreise: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_weltreise") };
			visualizationUpdates = { ...visualizationUpdates, ziel_liquiditaet: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_liquiditaet") };
			visualizationUpdates = { ...visualizationUpdates, ziel_umzug: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_umzug") };
			visualizationUpdates = { ...visualizationUpdates, ziel_fortbildung: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_fortbildung") };
			visualizationUpdates = { ...visualizationUpdates, ziel_eigentum: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_eigentum") };
			visualizationUpdates = { ...visualizationUpdates, ziel_neues_hobby: getArrayLength(values, "investitionsziele", "bezeichnung", "ziel_neues_hobby") };
			visualizationUpdates = { ...visualizationUpdates, ziel_immobilienkauf: getArrayLength0(values, "kaufobjekte") > 0 };

			visualizationUpdates = { ...visualizationUpdates, anlage: values.haushalt?.vermoegenswerte?.reduce((acc, item) => acc + item.wert, 0) - values.haushalt?.verbindlichkeiten?.reduce((acc, item) => acc + item.wert, 0) };
			visualizationUpdates = { ...visualizationUpdates, cash: values.haushalt?.einnahmen?.reduce((acc, item) => acc + item.wert, 0) - values.haushalt?.ausgaben?.reduce((acc, item) => acc + item.wert, 0) };
			setVisualization({ ...visualization, ...visualizationUpdates });
		}
	}, []);

	const getGKVs = async (model) => {
		return await backendGetSync({ label: "Deckung", route: "values/gkvs", setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getUnversicherteKinder = async (model) => {
		return model.kinder.filter((kind) => getAlter(kind.geburtsdatum) < 18).map((kind) => ({ label: getDisplayName(kind), value: kind.uuid }));
	};

	const memoizedFormRenderer = useMemo(
		() => (
			<FormRenderer
				containerRenderer={containerRendererFlex}
				itemRenderer={itemRendererFlex}
				fdef={fdefDIN77230}
				variant="filled"
				size="small"
				valuesCallBack={valuesCallBack}
				saveCallBack={storeBedarf}
				model={bedarf} //{convertPunktToNested(getEmptyModel(fdefDIN77230.formdef))}
				services={{
					gkvsService: getGKVs,
					unversicherteKinderService: getUnversicherteKinder,
				}}
			/>
		),
		[valuesCallBack]
	);

	return (
		<BackendLayout>
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>{memoizedFormRenderer}</Box>
		</BackendLayout>
	);
}
