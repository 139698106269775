import { Box, Typography, useTheme, Button } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import PropTypes from "prop-types";

AppModuleTile.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  icon_name: PropTypes.string,
  activeModule: PropTypes.string,
  setActiveModule: PropTypes.func,
  button_label: PropTypes.string,
  background_image: PropTypes.string,
};

export default function AppModuleTile(props) {
  const { label, description, icon_name = null, activeModule, setActiveModule, button_label = null, background_image = null } = props;
  const theme = useTheme();

  return (
    <Tile
      sx={{
        width: "100%",
        padding: "8px",
        mb: "8px",
        backgroundColor: activeModule === label ? theme.palette.grey[200] : "#ffffff",
        ":hover": {
          cursor: "pointer",
          backgroundColor: theme.palette.grey[200],
        },
        ...(background_image && activeModule === label
          ? {
              alignContent: "end",
              height: "152px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `linear-gradient(to bottom, rgba(255,255,255, 0) 50%, rgba(255, 255, 255, 0.6) 100%),  url(${background_image})`,
            }
          : {}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: "0",
        }}
        onClick={() => setActiveModule(label)}
      >
        {icon_name && <CustomIcon name={icon_name} size={48} />}
        <Box sx={{ pl: "8px" }}>
          <Typography variant="body1" component="div">
            {label}
          </Typography>
          <Typography variant="body2" component="span">
            {description}
          </Typography>
        </Box>
      </Box>
      {button_label && (
        <Button variant="contained" sx={{ width: "100%", mt: "16px", textTransform: "none" }} onClick={() => setActiveModule(label)}>
          {button_label}
        </Button>
      )}
    </Tile>
  );
}
