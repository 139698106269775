import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getEuro } from "services/formatService";
import { getChart } from "services/chartService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 10,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderTop: "1px solid gray",
		paddingTop: 4,
		paddingBottom: 4,
	},
	header: {
		borderTop: "none",
		paddingTop: 10,
		paddingBottom: 10,
	},
	bold: {
		fontWeight: "bold",
	},
	col1: {
		width: "6%",
		paddingLeft: 8,
	},
	col2: {
		display: "flex",
		flexDirection: "column",
		width: "23%",
	},
	col3: {
		display: "flex",
		flexDirection: "column",
		width: "24%",
	},
	col4: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "20%",
	},
	col5: {
		display: "flex",
		flexDirection: "column",
		width: "25%",
	},
	flexCol: {
		display: "flex",
		flexDirection: "column",
	},
	abgesichertDurch: {
		padding: "5px 10px 5px 10px",
		border: "1px solid gray",
		borderRadius: 8,
		width: "100%",
		marginBottom: "5px",
		height: "auto",
	},
	abgesichertDurchRow: { display: "flex", flexDirection: "row", alignItems: "center", gap: 4, fontSize: 9, width: "100%" },
});

export const ChartTable = ({ data }) => {
	//sortiere data aufsteigend nach rang
	data.sort((a, b) => a.rang - b.rang);
	return (
		<View style={styles.table}>
			<View style={[styles.row, styles.bold, styles.header]}>
				<Text style={styles.col1}>Rang</Text>
				<Text style={styles.col2}>Bedarfsfeld/Risiko</Text>
				<Text style={styles.col3}>Sollwert/Haushaltsmitglied</Text>
				<Text style={styles.col4}>Bewertung Istwert</Text>
				<Text style={styles.col5}>Abgesichert durch</Text>
			</View>
			{data.map((row, i) => (
				<View key={`chart_index_${i}`} style={styles.row} wrap={false}>
					<Text style={styles.col1}>{row.rang}</Text>
					<View style={styles.col2}>
						<Text>{row.bedarfsfeld_vertrieb}</Text>
						<Text style={{ fontWeight: "bold" }}>{row.finanzthema}</Text>
					</View>
					<View style={styles.col3}>
						<Text>{typeof row.sollwert === "string" ? row.sollwert : getEuro(row.sollwert, 0)}</Text>
						<Text style={{ fontWeight: "bold" }}>
							{Array.isArray(row.haushaltsmitglied)
								? row.haushaltsmitglied.map((mitglied, index) => (
										<Text key={index}>
											{mitglied.name}
											{index < row.haushaltsmitglied.length - 1 ? ", " : ""}
										</Text>
								  ))
								: row.haushaltsmitglied.name}
						</Text>
					</View>
					<View style={styles.col4}>
						<View style={{ alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
							{getChart("bewertung_" + Math.round(row.ist_percent, 0) + "_view", { width: 40, height: 40, marginRight: 5 })}
							<View style={{ position: "absolute" }}>
								<Text style={{ fontSize: 10, color: "rgba(0, 0, 0, 0.60)", marginLeft: -3 }}>{Math.round(row.ist_percent, 0)}%</Text>
							</View>
						</View>
						<View style={styles.flexCol}>
							<Text>{typeof row.ist_wert === "string" ? row.ist_wert : getEuro(row.ist_wert, 0)}</Text>
						</View>
					</View>
					<View style={styles.col5}>
						{row.vertraege.map((vertrag, i) => (
							<View key={i} style={styles.abgesichertDurch}>
								<View style={styles.abgesichertDurchRow}>
									<Image src={`/pg_logos/${vertrag.produktgeber.logo}`} style={{ width: "24px" }} />
									<Text>{vertrag.produkt.produkt}</Text>
								</View>
								<Text style={{ fontSize: "7px" }}>{typeof vertrag.din_wert === "string" ? vertrag.din_wert : getEuro(vertrag.din_wert, 0)}</Text>
							</View>
						))}
					</View>
				</View>
			))}
		</View>
	);
};

ChartTable.propTypes = {
	data: PropTypes.array,
};
