import { Close, Search } from "@mui/icons-material";
import { Autocomplete, Box, Button, IconButton, Input, InputAdornment, TextField, Typography } from "@mui/material";
import Tile from "components/Tile";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { notizenAtom } from "recoil/atoms/notizenAtom";
import CustomIcon from "./CustomIcon";
import Notiz from "./Notiz";
import { v4 as uuidv4 } from "uuid";

const tagOptions = [
    { label: 'Student', value: 1 },
    { label: 'BU', value: 2 },
]

export default function Notizen() {
    const [searchText, setSearchText] = useState("");
    const [notizen, setNotizen] = useRecoilState(notizenAtom);
    const [activeNote, setActiveNote] = useState();
    const { register, control, handleSubmit, reset } = useForm({ defaultValues: { notizText: "", notizTags: [] } });

    const filteredNotes = notizen.filter((note) => {
        const notizTextString = note.notizText;
        const notizTagsString = note.notizTags.map(x => x.label).join(" ");
        const searchString = `${notizTextString} ${notizTagsString}`.toLowerCase();
        return searchText ? searchString.includes(searchText.toLowerCase()) : true;
    });

    const onSelectNote = note => {
        if (activeNote && activeNote.id === note.id) {
            setActiveNote(undefined);
            reset({ notizText: "", notizTags: [] });
        } else {
            setActiveNote(note);
            reset({ ...note, notizTags: note.notizTags.map(x => x.value) });
        }
    }

    const onDeleteNote = note => {
        reset({ notizText: "", notizTags: [] });
        setNotizen(notizen.filter(x => x.id !== note.id));
    }

    const onDiscard = () => {
        reset({ notizText: "", notizTags: [] });
    }

    const onSubmit = (data) => {
        const notizTags = data.notizTags.map(x => tagOptions.find(y => y.value === x));
        if (activeNote) {
            const newNotes = notizen.map(x => {
                if (x.id === activeNote.id) {
                    return { ...data, id: activeNote.id, notizTags, dateCreated: new Date() }
                }

                return { ...x }
            });

            setNotizen(newNotes);
        } else {
            const newNote = { ...data, id: uuidv4(), notizTags, dateCreated: new Date() };
            setNotizen([...notizen, newNote]);
            reset({ notizText: "", notizTags: [] });
        }
    };

    return (
        <Tile
            sx={{
                width: "500px",
                height: undefined,
                margin: "0px",
                padding: "16px",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <TextField
                        label="Ihr Notiz"
                        variant="filled"
                        {...register("notizText", { required: true })}
                        sx={{ mb: "16px", width: "100%" }}
                    />
                    <Controller
                        name="notizTags"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                sx={{ mb: "16px", width: "100%" }}
                                multiple
                                options={tagOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" size="small" sx={{ pt: "2px" }} />}
                                value={tagOptions.filter((option) => field.value.includes(option.value))}
                                onChange={(event, newValue) => field.onChange(newValue.map((option) => option.value))}
                            />
                        )}
                    />
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", mb: "16px" }}>
                        <Button variant="text" color="primary" sx={{ textTransform: "none" }} onClick={onDiscard}>
                            Verwerfen
                        </Button>
                        <Button variant="contained" color="primary" type="submit" sx={{ ml: "16px", textTransform: "none" }}>
                            {activeNote ? "Speichern" : "Notiz hinzufügen"}
                        </Button>
                    </Box>
                </form>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", mb: "20px" }}>
                    <Input
                        placeholder="Notiz suchen"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        sx={{ px: "16px", py: "8px", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.06)", borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                    <IconButton onClick={() => setSearchText("")} sx={{ ml: "16px" }}>
                        <Close />
                    </IconButton>
                </Box>
                {
                    notizen.length === 0 ?
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ mb: "8px" }}>
                                <CustomIcon name="Notizen" size={48} />
                            </Box>
                            <Typography variant="body1" textAlign="center">Schreiben Sie Notizen, um zusätzliche Informationen in Erinnerung zu behalten</Typography>
                        </Box>
                        :
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {
                                filteredNotes.map((notiz, index) => (
                                    <Notiz
                                        key={`notiz_${index}`}
                                        notiz={notiz}
                                        index={index + 1}
                                        isLastItem={notizen.length === index + 1}
                                        onSelectNote={onSelectNote}
                                        onDeleteNote={onDeleteNote}
                                        isNoteActive={activeNote && activeNote.id === notiz.id}
                                    />
                                ))
                            }
                        </Box>
                }
            </Box>
        </Tile>
    );
}
