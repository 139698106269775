import { FormControlLabel, Switch, Box } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import InfoDialog from "../components/InfoDialog";
import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import { isItemHidden } from "../DMFhelperService";

MDDSwitch.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	trigger: PropTypes.func,
	getValues: PropTypes.func,
};

function MDDSwitch(props) {
	const { item, control, trigger, getValues } = props;
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	if (item.update_func) {
		if (item.update_func === "processDeckung") {
			const deckungName = item.name.replace("bool_wert", "deckung_obj").replace("[", ".").replace("]", "");

			const obj = getValues(deckungName);
			if (obj) {
				const deckungDescription = obj.obj;
				if (deckungDescription.field_type === "Switch") {
					item.visible = { func: "const", value: "true" };
					item.label = deckungDescription.label;
				} else item.visible = { func: "const", value: "false" };
			}
		}
	}

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	return (
		<Box sx={{ alignItems: "center", pb: "8px", flexDirection: "column", display: hidden ? "none" : "flex" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || null}
				render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
					if (value === undefined) value = false;
					return (
						<Tile
							sx={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								mb: "0px",
								mt: "0px",
								padding: "4px",
								justifyContent: "space-between",
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
								{item.icon && <CustomIcon name={item.icon} size={48} />}
								<Box sx={{ pl: "8px", flexGrow: 1 }}>{item.label}</Box>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
								<FormControlLabel
									checked={value || false}
									onChange={(e) => {
										onChange(e.target.checked);
										trigger(item.name);
									}}
									control={<Switch />}
									label=""
									sx={{ ml: 0, mr: 0 }}
								/>
								<InfoDialog item={item} />
							</Box>
						</Tile>
					);
				}}
			/>
		</Box>
	);
}

export default MDDSwitch;
