import { FormControl, InputLabel, MenuItem, Select, Box } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

MDDSelect.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	trigger: PropTypes.func,
	getValues: PropTypes.func,
	services: PropTypes.object,
	disabled: PropTypes.bool,
	setValue: PropTypes.func,
};

function MDDSelect(props) {
	const { item, variant = "outlined", control, trigger, services, getValues, disabled = false, setValue } = props;
	const [options, setOptions] = useState(item.options || []);
	const watched = useWatch({ name: item.watch || [undefined], control: control });

	useEffect(() => {
		const fetchOptions = async () => {
			if (item.service) {
				const fetchedOptions = await services[item.service](getValues());
				setOptions(fetchedOptions);
			} else {
				setOptions(item.options || []);
			}
		};

		fetchOptions();
	}, [watched, item.service]);

	const isValidValue = (value) => {
		return options?.some((option) => String(option.value) === String(value));
	};

	return (
		<FormControl fullWidth sx={{ pb: "8px" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || ""}
				render={({ field: { onChange, value } }) => (
					<>
						<InputLabel
							id={`${item.name}-label`}
							sx={{
								transform: value ? "translate(14px, 0px) scale(0.75)" : "translate(14px, 20px) scale(1)",
								transition: "transform 200ms ease-in-out",
							}}
						>
							{item.label}
						</InputLabel>
						<Select
							labelId={`${item.name}-label`}
							id={item.name}
							label={item.label}
							variant={variant}
							size="small"
							value={isValidValue(value) ? value : ""}
							disabled={item.disabled || disabled}
							onChange={(event) => {
								setValue(
									item.name + "_obj",
									options.find((option) => option.value === event.target.value)
								);
								onChange(event.target.value);
								trigger(item.name);
							}}
						>
							{options
								.sort((a, b) => a.label.localeCompare(b.label))
								.map((option) => {
									return (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									);
								})}
						</Select>
					</>
				)}
			/>
		</FormControl>
	);
}

export default MDDSelect;
