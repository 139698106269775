export const fdefVertrag = {
	formdef: [
		{
			control: "tabs",
			name: "vertrag_tabs",
			cols: 12,
			default: "vertragsdetails_tab",
			children: [
				{
					name: "vertragsdetails_tab",
					label: "Vertragsdetails",
					control: "tab",
					cols: 12,
					children: [
						{
							control: "button_options",
							name: "vertragsart",
							default: "Simulationsvertrag",
							cols: 12,
							options: [
								{ label: "Eigenvertrag", value: "Eigenvertrag", icon: "Rent" },
								{ label: "Fremdvertrag", value: "Fremdvertrag", icon: "Rent" },
								{ label: "Simulationsvertrag", value: "Simulationsvertrag", icon: "Rent" },
							],
						},
						{
							name: "produktgeber",
							label: "Produktgeber",
							control: "autocomplete",
							cols: 12,
							service: "produktgeberService",
						},

						{
							name: "sparte",
							label: "Sparte",
							control: "select",
							cols: 12,
							watch: ["produktgeber"],
							service: "spartenService",
						},
						{
							name: "produkt",
							label: "Produkt",
							control: "select",
							cols: 6,
							watch: ["sparte"],
							service: "produkteService",
						},
						{
							name: "tarif",
							label: "Tarif",
							control: "input",
							default: "",
							cols: 6,
						},
						{
							name: "vertragsnummer",
							label: "Vertragsnummer",
							control: "input",
							default: "",
							cols: 12,
						},
						{
							name: "versicherungsnehmer",
							label: "Versicherungsnehmer",
							control: "select",
							default: "",
							cols: 12,
							service: "versicherungsnehmerService",
						},
						{
							control: "multiselect",
							name: "versicherte_personen",
							label: "Versicherte Personen",
							cols: 12,
							addAll: true,
							service: "versichertePersonenService",
						},
						{
							name: "beginn",
							label: "Beginn",
							control: "date_picker",
							cols: 6,
						},
						{
							name: "ablauf",
							label: "Abaluf",
							control: "date_picker",
							cols: 6,
						},
						{
							name: "status",
							label: "Status",
							control: "select",
							cols: 12,
							default: "1",
							options: [
								{ label: "lebend", value: "1" },
								{ label: "lebend / kurzfristig / unterjährig", value: "2" },
								{ label: "ruhend / Anwartschaft", value: "3" },
								{ label: "storniert", value: "4" },
								{ label: "ruhend / beitragsfrei", value: "5" },
								{ label: "beitragsfrei", value: "6" },
								{ label: "beitragsfrei durch Leistung", value: "7" },
								{ label: "Beitragsstundung", value: "8" },
								{ label: "Sonstiges", value: "9" },
								{ label: "In Leistung", value: "A" },
								{ label: "In Abrufphase", value: "B" },
								{ label: "Vorläufige Deckung", value: "C" },
							],
						},
						{
							control: "section",
							name: "zahlung",
							label: "Zahlung",
							cols: 12,
							children: [
								{
									name: "zahlweise",
									label: "Zahlweise",
									control: "select",
									cols: 6,
									options: [
										{ label: "einmalig", value: "einmalig" },
										{ label: "monatlich", value: "monatlich" },
										{ label: "vierteljährlich", value: "vierteljährlich" },
										{ label: "halbjährlich", value: "halbjährlich" },
										{ label: "jährlich", value: "jährlich" },
									],
								},
								{
									name: "beitrag_gem_zahlweise",
									label: "Beitrag gemäß Zahlweise",
									control: "numberinput",
									unit: "€",
									default: 0,
									unit_type: "currency",
									cols: 6,
								},
							],
						},
						{
							control: "expandable",
							label_add: "Deckungen hinzufügen",
							delete_label: "die Deckung",
							name: "deckungen",
							label: "Deckungen",
							icon: "Shield",
							cols: 12,
							children: [
								{
									name: "deckung",
									label: "Deckung",
									control: "select",
									watch: ["produkt"],
									service: "deckungService",
									cols: 12,
								},
								{
									name: "wert",
									label: "Wert",
									unit: "€",
									default: 0,
									min: 0,
									max: 1000000,
									step: 1000,
									unit_type: "currency_no_digit",
									control: "sliderinput",
									cols: 12,
									visible: { func: "const", value: "false" },
									watch: ["deckungen"],
									update_func: "processDeckung",
								},

								{
									name: "bool_wert",
									label: "",
									control: "switch",
									cols: 12,
									label_placement: "start",
									default: true,
									visible: { func: "const", value: "false" },
									watch: ["deckungen"],
									update_func: "processDeckung",
								},
								{
									name: "stand_zum",
									label: "Stand zum",
									control: "date_picker",
									cols: 12,
									watch: ["produktgeber_obj.geschaeft"],
									visible: { field: "produktgeber_obj.geschaeft", func: "equal", value: ["Leben", "Sonstiges", "Kapitalanlage", "Finanzierungen"] },
								},
							],
						},
					],
				},
				{
					name: "dokumente_tab",
					label: "Dokumente",
					control: "tab",
					cols: 12,
					children: [
						{
							control: "upload",
							name: "dokumente",
							label: "Dokumente (Police, Beitragsrechnung, etc.",
							hide_label: true,
							multiple: true,
							dok_types: ["Police", "Beitragsrechnung", "sonstiges"],
						},
					],
				},
			],
		},
	],
	transform_in: [],
	transform_out: [],
};
