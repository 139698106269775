import { Box, Typography, useTheme, Button, Chip } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";
import { getActiveModule } from "services/workflowService";
import { workflowAtom } from "recoil/atoms/workflowAtom";

SubModuleTile.propTypes = {
	submodule: PropTypes.object,
};

export default function SubModuleTile(props) {
	const { submodule } = props;
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const activeModule = getActiveModule(workflow);
	const theme = useTheme();

	if (!submodule) {
		return null;
	}

	const setSubmodule = (submodule) => {
		const newWorkflow = { ...workflow };
		newWorkflow.active_submodule = submodule.uuid;
		setWorkflow(newWorkflow);
	};

	return (
		<Tile
			sx={{
				width: "100%",
				padding: "8px",
				mb: "8px",
				backgroundColor: workflow.active_submodule === submodule.uuid ? theme.palette.grey[200] : "#ffffff",
				borderColor: workflow.active_submodule === submodule.uuid ? "rgba(62, 79, 147, 0.50)" : "#E6E6E6",
				...(submodule.coming_soon
					? {
							opacity: 0.6,
					  }
					: {
							":hover": {
								cursor: "pointer",
								backgroundColor: theme.palette.grey[200],
							},
					  }),

				...(submodule.background_image && activeModule === submodule.uuid
					? {
							alignContent: "end",
							height: "152px",
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundImage: `linear-gradient(to bottom, rgba(255,255,255, 0) 50%, rgba(255, 255, 255, 0.6) 100%),  url(${submodule.background_image})`,
					  }
					: {}),
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexGrow: "0",
				}}
				onClick={() => (submodule.coming_soon ? null : setSubmodule(submodule))}
			>
				{submodule.icon && (
					<Box>
						<CustomIcon name={submodule.icon} size={48} />
					</Box>
				)}
				<Box sx={{ pl: "8px" }}>
					<Typography variant="body1" component="div">
						{submodule.label}
					</Typography>
					<Typography variant="body2" component="span">
						{submodule.description}
					</Typography>
					{submodule.coming_soon && (
						<Box>
							<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
						</Box>
					)}
				</Box>
			</Box>
			{submodule.button_label && (
				<Button variant="contained" sx={{ width: "100%", mt: "16px", textTransform: "none" }} onClick={() => setSubmodule(submodule)}>
					{submodule.button_label}
				</Button>
			)}
		</Tile>
	);
}
