import { Box, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { getNestedValue } from "../DMFhelperService";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { isItemHidden } from "../DMFhelperService";

MDDTabs.propTypes = {
	item: PropTypes.object,
	children: PropTypes.array,
	control: PropTypes.object,
	getValues: PropTypes.func,
};

const executeDynamicFunc = (item, values) => {
	if (item.runtime_func) {
		const rt_func = item.runtime_func;
		switch (rt_func.func) {
			case "setDisplayname":
				var entity = getNestedValue(values, rt_func.entity);
				var dynLabel = (entity?.vorname || "") + " " + (entity?.nachname || "");
				dynLabel = dynLabel.trim();
				if (dynLabel !== "") return dynLabel;
				break;
			default:
		}
	}
	return item.label;
};

//ToDo: watch auf array umstellen (auch in din.js)

function flattenArray(arr) {
	return arr.reduce((acc, val) => {
		if (Array.isArray(val)) {
			return acc.concat(flattenArray(val));
		} else {
			return acc.concat(val);
		}
	}, []);
}

const getToWatch = (item) => {
	const watched = flattenArray(item.children.map((child) => child.watch).filter((entry) => entry !== undefined));
	return watched;
};

function MDDTabs(props) {
	const { item, children, control, getValues } = props;
	const appWindow = useRecoilValue(appWindowAtom);
	const toWatch = getToWatch(item);
	const watched = useWatch({ name: toWatch || [undefined], control: control });
	const [hidden, setHidden] = useState([]);
	const [tabNames, setTabnames] = useState([]);

	const processVisibility = () => {
		let newHidden = [];
		let tabNames = [];
		const values = getValues();
		item.children.forEach((child) => {
			if (child.watch) {
				if (isItemHidden(child, values)) {
					newHidden = [...newHidden, child.name];
				}
			}
			tabNames = [...tabNames, { name: child.name, label: executeDynamicFunc(child, values) }];
		});
		setHidden(newHidden);
		setTabnames(tabNames);
	};

	useEffect(() => {
		processVisibility();
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			processVisibility();
		}
	}, [watched]);

	return (
		<Controller
			name={item.name}
			control={control}
			defaultValue={item.default || ""}
			render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
				const handleChange = (event, newValue) => {
					onChange(newValue);
				};

				return (
					<Fragment>
						<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
							<Box>
								<Tabs value={value} onChange={handleChange} sx={{ mb: "10px", borderBottomWidth: "1px", borderBottomStyle: "solid" }}>
									{item.children.map((child) => {
										return (
											<Tab
												key={child.name}
												sx={{
													display: hidden.includes(child.name) ? "none" : "block",
												}}
												value={child.name}
												label={
													<Box sx={{ display: "flex", alignItems: "center" }}>
														{item.icon && <CustomIcon name={item.icon} size={24} />}
														<Typography variant="button" sx={{ textTransform: "none", pl: "8px" }}>
															{tabNames.find((tabName) => tabName.name === child.name)?.label}
														</Typography>
													</Box>
												}
											/>
										);
									})}
								</Tabs>
								<Box sx={{ flexGrow: 1, overflowY: "auto", height: appWindow.contentHeight - 151 + "px" }}>
									{children.map((child) => {
										return (
											<Box hidden={child.name !== value} key={child.name} sx={{ height: "100%" }}>
												{child.content}
											</Box>
										);
									})}
								</Box>
							</Box>
						</Box>
					</Fragment>
				);
			}}
		/>
	);
}

export default MDDTabs;
