export const defTemplateAdresse = [
  {
    name: "strasse",
    label: "Straße",
    control: "input",
    cols: 9,
  },
  {
    name: "hausnummer",
    label: "Hausnummer",
    control: "input",
    cols: 3,
  },

  {
    name: "plz",
    label: "PLZ",
    control: "input",
    cols: 3,
  },
  {
    name: "ort",
    label: "Ort",
    control: "input",
    cols: 9,
  },
];
