import DynamicComponentLoader from "components/DynamicComponentLoader";
import PropTypes from "prop-types";

DMF_Component.propTypes = {
  item: PropTypes.object,
  control: PropTypes.object,
  children: PropTypes.object,
  getValues: PropTypes.func,
};

function DMF_Component(props) {
  const { item, children, control, getValues } = props;

  return <DynamicComponentLoader config={{ component: item.component, getValues: getValues }} />;
}

export default DMF_Component;
