/* eslint-disable react/prop-types */
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Footer } from "../components/Footer";
import { VSpacer } from "../components/VSpacer";

const styles = StyleSheet.create({
	page: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		padding: 20,
		fontFamily: "FoundryMonoline",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		marginTop: 250,
	},
	text: {
		fontSize: 12,
		color: "rgba(0, 0, 0, 0.6)",
	},
	formaxx: {
		fontSize: 20,
	},
	ag: {
		fontSize: 12,
	},
	iconRow: {
		display: "flex",
		flexDirection: "row",
		gap: 6,
	},
});

export const LastPage = ({ pdfModel: pdfModel }) => (
	<Page size="A4" style={styles.page} orientation="landscape">
		<View style={styles.content}>
			<Text style={styles.text}>Ihr Ansprechpartner</Text>
			<Text>
				{pdfModel.berater.vorname} {pdfModel.berater.nachname}
			</Text>
			<VSpacer height={10} />
			<Text style={styles.text}>Fon: {pdfModel.berater.custom.telefon}</Text>
			<Text style={styles.text}>E-Mail: {pdfModel.berater.email}</Text>
			<VSpacer height={30} />
			<Text style={[styles.formaxx, { color: pdfModel.secondaryColor }]}>
				{pdfModel.company.name}
				{pdfModel.company.name === "FORMAXX" && <Text style={styles.ag}>AG</Text>}
			</Text>
			<VSpacer height={20} />
			<Text style={styles.text}>{pdfModel.company.strasseNummer}</Text>
			<Text style={styles.text}>
				{pdfModel.company.plz} {pdfModel.company.ort}
			</Text>
			<VSpacer height={10} />
			<Text style={styles.text}>Web: {pdfModel.company.website}</Text>
			<Text style={styles.text}>Fon: {pdfModel.company.telefon}</Text>
			<Text style={styles.text}>Fax: {pdfModel.company.fax}</Text>
			<Text style={styles.text}>E-Mail: {pdfModel.company.email}</Text>
			<VSpacer height={10} />
			<Text style={styles.text}>Folgen Sie uns auf</Text>
			<VSpacer height={10} />
			<View style={styles.iconRow}>
				<Image src={import.meta.env.VITE_BACKEND + "/Facebook.png"} style={{ width: 20, height: 20 }} />
				<Image src={import.meta.env.VITE_BACKEND + "/Instagram.png"} style={{ width: 20, height: 20 }} />
				<Image src={import.meta.env.VITE_BACKEND + "/Xing.png"} style={{ width: 20, height: 20 }} />
				<Image src={import.meta.env.VITE_BACKEND + "/Linkedin.png"} style={{ width: 20, height: 20 }} />
			</View>
		</View>
		<Footer pdfModel={pdfModel} />
	</Page>
);
