export const fdefAnteil = {
	formdef: [
		{
			name: "wert",
			label: "Verwendung",
			unit: "€",
			default: 0,
			min: 0,
			max: 1000000,
			step: 1000,
			unit_type: "currency_no_digit",
			control: "sliderinput",
			cols: 12,
		},
	],
	transform_in: [],
	transform_out: [],
};
