import { Box, Checkbox, FormControl, FormControlLabel, Typography, useTheme, IconButton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { ContentDialog, ContentDialogInit } from "../components/ContentDialog";
import Tile from "components/Tile";

DMF_CheckboxGroup.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	trigger: PropTypes.func,
	disabled: PropTypes.bool,
};

function DMF_CheckboxGroup(props) {
	const { item, control, trigger, disabled } = props;
	const { openCD, setOpenCD, tca, setTca, showDescription } = ContentDialogInit();
	const theme = useTheme();

	return (
		<FormControl component="fieldset" sx={{ width: "100%" }}>
			<ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} />
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				{!item.hide_label && <Typography variant="h6">{item.label}</Typography>}
				{item.info && (
					<IconButton onClick={(e) => showDescription(item)} sx={{ width: "40px", height: "40px" }}>
						<CustomIcon name="Info" />
					</IconButton>
				)}
			</Box>
			<Controller
				name={item.name}
				control={control}
				render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
					if (value === undefined) value = [];
					return (
						<Tile
							sx={{
								width: "100%",
								height: undefined,
								margin: "0px",
								padding: "0px",
							}}
						>
							{item.options.map((option, index) => (
								<Box
									key={option.value}
									sx={{
										display: "flex",
										alignItems: "center",
										padding: "8px",
										borderRadius: (index === 0 && "8px 8px 0 0") || (index + 1 === item.options.length && "0 0 8px 8px") || "0",
										backgroundColor: "#ffffff",
										borderBottom: index !== item.options.length ? "1px solid #E6E6E6" : "none",
										":hover": {
											cursor: "pointer",
											backgroundColor: theme.palette.grey[100],
										},
									}}
									onClick={() => {
										if (value && !Array.isArray(value)) return;
										const updatedValue = value && value.includes(option.value) ? value.filter((val) => val !== option.value) : value ? [...value, option.value] : [option.value];
										onChange(updatedValue);
										trigger(item.name);
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												onClick={() => {
													if (value && !Array.isArray(value)) return;
													const updatedValue = value && value.includes(option.value) ? value.filter((val) => val !== option.value) : value ? [...value, option.value] : [option.value];

													onChange(updatedValue);
													trigger(item.name);
												}}
												checked={value ? value.includes(option.value) : false}
												sx={{ padding: "4px", margin: "0px" }}
											/>
										}
										sx={{ alignSelf: "flex-start", marginLeft: "0px" }}
									></FormControlLabel>
									<Typography variant="body1">{option.label}</Typography>
								</Box>
							))}
						</Tile>
					);
				}}
			/>
		</FormControl>
	);
}

export default DMF_CheckboxGroup;
