import { useRef, useState, useCallback, useId } from 'react'
import { MenuItem, Menu } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

const MoreMenuItem = ({ content, children, id, MenuProps, ...other }) => {
    const [isOpen, setIsOpen] = useState(false)
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    const menuItemRef = useRef(null)
    const menuItemId = useId()
    const normMenuItemId = id ?? menuItemId

    return (
        <MenuItem
            {...other}
            ref={menuItemRef}
            sx={[{ justifyContent: 'left', px: "8px" }, isOpen && { backgroundColor: 'action.hover' }]}
            onClick={open}
            onMouseLeave={close}
            id={normMenuItemId}
        >
            <NavigateBeforeIcon  />
            {content}
            <Menu
                TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
                disableRestoreFocus
                sx={{ pointerEvents: 'none', '& .MuiList-root': { pointerEvents: 'auto' } }}
                MenuListProps={{ ...MenuProps?.MenuListProps, 'aria-labelledby': normMenuItemId }}
                anchorEl={menuItemRef.current}
                open={isOpen}
                onClose={close}
                anchorOrigin={MenuProps?.anchorOrigin ?? { vertical: 'center', horizontal: 'left' }}
                transformOrigin={MenuProps?.transformOrigin ?? { vertical: 'center', horizontal: 'right' }}
            >
                {children}
            </Menu>
        </MenuItem>
    )
}

export default MoreMenuItem
