import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import DnDFileUpload from "../components/DnDFileUpload";
import { Box, Typography, IconButton } from "@mui/material";
import Tile from "../../Tile";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import InfoDialog from "../components/InfoDialog";
import { KeyboardArrowUp } from "@mui/icons-material";

MDDUpload.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	trigger: PropTypes.func,
	disabled: PropTypes.bool,
};

function MDDUpload(props) {
	const { item, control, trigger, disabled } = props;
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Controller
			name={item.name}
			control={control}
			defaultValue={item.default || null}
			render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
				return (
					<Tile sx={{ padding: "0px", mt: "16px", mb: "16px" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								pl: "16px",
								pt: "16px",
								pb: "16px",
								boxShadow: "0px 1px 0px #E6E6E6",
								":hover": {
									cursor: disabled ? "default" : "pointer",
									backgroundColor: disabled ? "none" : theme.palette.grey[100],
								},
							}}
							onClick={handleClick}
						>
							<Typography variant="h6">
								{item.label} ({value?.length || 0})
							</Typography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<InfoDialog item={item} />
								<IconButton>
									<KeyboardArrowUp sx={{ width: "24px", height: "24px", ...(!open && { transform: "rotate(180deg)" }) }} />
								</IconButton>
							</Box>
						</Box>
						{open && (
							<Box sx={{ padding: "8px" }}>
								<DnDFileUpload
									upload={value || []}
									multiple={item.multiple || false}
									add_description={item.add_description || true}
									setUpload={(e) => {
										onChange(e);
										trigger(item.name);
									}}
									allowedExtensions={item.allowedExtensions || ["PDF", "JPG", "JPEG", "PNG", "GIF"]}
									disabled={false}
								/>
							</Box>
						)}
					</Tile>
				);
			}}
		/>
	);
}

export default MDDUpload;
