import { defTemplateAdresse } from "./fragments/adresse";
import { defTemplateArbeitgeber } from "./fragments/arbeitgeber";
import { defTemplateKind } from "./fragments/kind";
import { defTemplatePerson } from "./fragments/person";
import { defTemplateTelekommunikation } from "./fragments/telkommunikation";

const getDefinition = (path = "", template) => {
	if (path.length > 0) path += ".";
	return template.map((item) => {
		if (item.control === "expandable") {
			return { ...item, name: path + item.name };
		}
		let newItem = { ...item, name: path + item.name };
		if (newItem.children) newItem = { ...newItem, children: getDefinition(path + item.name, newItem.children) };
		return newItem;
	});
};

export const fdefKunde = {
	formdef: [
		{
			control: "section",
			name: "kunde",
			label: "Persönliche Informationen",
			cols: 12,
			children: getDefinition("kunde", defTemplatePerson),
		},
		{
			control: "section",
			name: "kunde_adresse",
			label: "Adresse",
			cols: 12,
			children: getDefinition("kunde", defTemplateAdresse),
		},
		{
			control: "section",
			name: "kunde_arbeitgeber",
			label: "Arbeitgeber",
			cols: 12,
			children: getDefinition("kunde.custom.arbeitgeber", defTemplateArbeitgeber),
		},
		{
			control: "section",
			name: "kunde_telekommunikation",
			label: "Kontaktdaten",
			cols: 12,
			children: getDefinition("kunde", defTemplateTelekommunikation),
		},
		{
			control: "section",
			name: "kunde_familienstand",
			label: "Familienstand",
			info: {
				title: "Hinweise",
				description: "Der Familienstand ist relevant für die Absicherung der Hinterbliebenen im Todesfall.",
			},
			cols: 12,
			children: [
				{
					name: "kunde.familienstand",
					label: "Familienstand",
					control: "button_options",
					cols: 12,
					hide_label: true,
					options: [
						{ value: "ledig", label: "ledig/verwitwet", icon: "Person" },
						{ value: "verheiratet", label: "verheiratet", icon: "Married" },
						{ value: "partnerschaft", label: "Partnerschaft", icon: "Couple" },
					],
				},
			],
		},
		{
			control: "section",
			name: "partner",
			label: "Partner",
			cols: 12,
			watch: ["kunde.familienstand"],
			visible: { func: "equal", field: "kunde.familienstand", value: ["verheiratet", "partnerschaft"] },
			children: getDefinition("partner", defTemplatePerson),
		},
		{
			control: "section",
			name: "partner_arbeitgeber",
			label: "Arbeitgeber Partner",
			cols: 12,
			watch: ["kunde.familienstand"],
			visible: { func: "equal", field: "kunde.familienstand", value: ["verheiratet", "partnerschaft"] },
			children: getDefinition("partner.custom.arbeitgeber", defTemplateArbeitgeber),
		},
		{
			control: "expandable",
			label_add: "Kind hinzufügen",
			name: "kinder",
			label: "Kinder",
			delete_label: "das Kind",
			icon: "Child",
			show_number: true,
			cols: 12,
			children: getDefinition("", defTemplateKind),
		},
	],
	transform_in: [],
	transform_out: [],
};
