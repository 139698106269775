/* eslint-disable react/prop-types */
import { Image, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
	footer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-end",
		fontSize: 14,
		marginTop: "auto",
		paddingTop: 10,
	},
	horizontalLineFooter: {
		flex: 1,
		marginRight: 10,
	},
});

export const Footer = ({ pdfModel }) => (
	<View style={styles.footer} fixed>
		<View style={[styles.horizontalLineFooter, { borderBottom: `2px solid ${pdfModel.secondaryColor}` }]} />
		<Image src={pdfModel.company.logoUrl} style={{ width: pdfModel.company.logoWidth, height: pdfModel.company.logoHeight }} />
	</View>
);
