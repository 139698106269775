import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layer, Stage } from "react-konva";
import { v4 as uuidv4 } from "uuid";
import Arrow from "./Drawing/Arrow";
import Circle from "./Drawing/Circle";
import Ellipse from "./Drawing/Ellipse";
import Line from "./Drawing/Line";
import Rectangle from "./Drawing/Rectangle";
import Text from "./Drawing/Text";
import { Box, Slider } from "@mui/material";
import { IconButton } from "@mui/material";
import { Fragment } from "react";
import MoreMenuItem from "./components/MoreMenuItem";
import { Close } from "@mui/icons-material";
import { createChunks } from "services/helperService";
import CustomIcon from "components/CustomIcon";
import Triangle from "./Drawing/Triangle";
import { useRecoilState } from "recoil";
import { presentationAtom } from "recoil/atoms/presentationAtom";
import PencilTool from "./tools/PencilTool";
import MarkerTool from "./tools/MarkerTool";
import PenTool from "./tools/PenTool";

const itemsColor = [{ key: "#3E4F93" }, { key: "#E5CC9C" }, { key: "#4CAF50" }, { key: "#B80F0A" }, { key: "#FFEB3B" }, { key: "#2196F3" }, { key: "#424242" }, { key: "#FFFFFF" }];
const itemsColorChunks = createChunks(itemsColor, 2);

const thicknessSizes = [
	{ key: "xsmall", thickness: 2, icon: "ThicknessXSmall", fontsize: 12 },
	{ key: "small", thickness: 4, icon: "ThicknessSmall", fontsize: 14 },
	{ key: "medium", thickness: 6, icon: "ThicknessMedium", fontsize: 16 },
	{ key: "large", thickness: 8, icon: "ThicknessLarge", fontsize: 24 },
	{ key: "xlarge", thickness: 10, icon: "ThicknessXLarge", fontsize: 32 },
];

const getThicknessKeyFont = (thickness) => {
	switch (thickness) {
		case 2:
			return {
				key: "xsmall",
				fontsize: 12,
			};
		case 4:
			return {
				key: "small",
				fontsize: 14,
			};
		case 6:
			return {
				key: "medium",
				fontsize: 16,
			};
		case 8:
			return {
				key: "large",
				fontsize: 24,
			};
		case 10:
			return {
				key: "xlarge",
				fontsize: 32,
			};
		default:
			return {
				key: "medium",
				fontsize: 16,
			};
	}
};

export default function Sketchpad(props) {
	const { width, height, pzoom = 1, activePage } = props;
	const stageEl = React.createRef();
	const layerEl = React.createRef();
	const [presentation, setPresentation] = useRecoilState(presentationAtom);
	const [selectedId, setSelectedId] = useState(null);
	const [shapes, setShapes] = useState(new Map());
	const [isPencilDrawing, setIsPencilDrawing] = useState(true);
	const [isDrawingToolActive, setIsDrawingToolActive] = useState(false);
	const [isPencilSelected, setIsPencilSelected] = useState(false);
	const [localZoom, setLocalZoom] = useState(0);
	const [selectedDrawingTool, setSelectedDrawingTool] = useState({ icon: "ToolMouse", title: "Formulareingabe", key: "mouse" });
	const [selectedOpacity, setSelectedOpacity] = useState({ key: 0.8 });
	const [selectedSize, setSelectedSize] = useState({ icon: "ThicknessMedium", title: "mittel", thickness: 6, fontsize: 24, key: "medium" });
	const [selectedColor, setSelectedColor] = useState({ key: "#3E4F93" });

	useEffect(() => {
		setLocalZoom(pzoom);
	}, [pzoom]);

	useEffect(() => {
		let shapesFromPage = new Map();
		if (activePage?.shapes) {
			shapesFromPage = new Map(activePage?.shapes);
		}
		setShapes(shapesFromPage);
	}, [activePage]);

	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = originalStyle);
	}, []);

	const setMySelectedId = (id) => {
		const shape = shapes.get(id);
		if (shape) {
			//props.setCanCursorNavigation(shape.type !== "text");
		} else {
			//props.setCanCursorNavigation(false);
		}

		setSelectedId(id);
	};

	const scaleShape = (shape, scaleFactor) => {
		const scaledShape = { ...shape };

		if (scaledShape.x) scaledShape.x = scaledShape.x * scaleFactor;
		if (scaledShape.y) scaledShape.y = scaledShape.y * scaleFactor;
		if (scaledShape.strokeWidth) scaledShape.strokeWidth = scaledShape.thickness * scaleFactor;
		if (scaledShape.width) scaledShape.width = scaledShape.width * scaleFactor;
		if (scaledShape.height) scaledShape.height = scaledShape.height * scaleFactor;
		if (scaledShape.radius) scaledShape.radius = scaledShape.radius * scaleFactor;
		if (scaledShape.fontSize) scaledShape.fontSize = scaledShape.fontSize * scaleFactor;
		if (scaledShape.pointerLength) scaledShape.pointerLength = scaledShape.pointerLength * scaleFactor;
		if (scaledShape.pointerWidth) scaledShape.pointerWidth = scaledShape.pointerWidth * scaleFactor;
		if (scaledShape.points) {
			const newPoints = [...scaledShape.points];
			newPoints.forEach((value, index) => {
				newPoints[index] = Number(value) * scaleFactor;
			});
			scaledShape.points = newPoints;
		}
		return scaledShape;
	};

	const mapCopy = (map) => {
		return new Map([...map.entries()]);
	};

	const getZoomed = (value) => {
		return value * localZoom;
	};

	const getRandomInt = () => {
		return 200 + Math.floor(Math.random() * Math.floor(40));
	};

	const handleSizeSelect = (newSize) => {
		setSelectedSize(newSize);
		if (selectedId) {
			const shape = shapes.get(selectedId);
			if (shape.type === "text") {
				const newFontSize = getZoomed(newSize.fontsize);
				if (shape["fontSize"] === shape["fontSizeSave"]) {
					shape["fontSize"] = newFontSize;
				}
				shape["fontSizeSave"] = newFontSize;
			} else {
				shape["strokeWidth"] = getZoomed(newSize.thickness);
			}
			shape["thickness"] = newSize.thickness;
			storeShape(shape, selectedId);
		}
	};

	const handleOpacitySelect = (e, newOpacity) => {
		setSelectedOpacity({ key: newOpacity });
		if (selectedId) {
			const shape = shapes.get(selectedId);
			shape["opacity"] = newOpacity;
			storeShape(shape, selectedId);
		}
	};

	const handleColorSelect = (newColor) => {
		setSelectedColor(newColor);
		if (selectedId) {
			const shape = shapes.get(selectedId);
			switch (shape.type) {
				case "text":
					shape["fill"] = newColor.key;
					break;
				case "arrow":
					shape["fill"] = newColor.key;
					shape["stroke"] = newColor.key;
					break;
				default:
					shape["stroke"] = newColor.key;
			}

			storeShape(shape, selectedId);
		}
	};

	const setActiveDrawingTool = (isDrawingTool, isPencilDrawing, isPencilSelected, drawingTool) => {
		setIsDrawingToolActive(isDrawingTool);
		setIsPencilDrawing(isPencilDrawing);
		setIsPencilSelected(isPencilSelected);
		setSelectedDrawingTool(drawingTool);
	};

	const handleDrawingToolSelect = (newDrawingTool) => {
		let activeDrawingTool = "";
		switch (newDrawingTool.key) {
			case "mouse":
				activeDrawingTool = "shape";
				break;
			case "rubber":
				activeDrawingTool = "rubber";
				break;
			case "pencil":
				setShapeConfig({ stroke: selectedColor.key, thickness: 4, opacity: 0.8, fontsize: 14, thicknessKey: "small" });
				activeDrawingTool = "pencil";
				break;
			case "pen":
				setShapeConfig({ stroke: selectedColor.key, thickness: 6, opacity: 0.7, fontsize: 16, thicknessKey: "medium" });
				activeDrawingTool = "pencil";
				break;
			case "marker":
				setShapeConfig({ stroke: selectedColor.key, thickness: 8, opacity: 0.6, fontsize: 24, thicknessKey: "large" });
				activeDrawingTool = "pencil";
				break;
			case "square":
				addRectangle();
				activeDrawingTool = "shape";
				break;
			case "text":
				addText();
				activeDrawingTool = "shape";
				break;
			case "circle":
				addCircle();
				activeDrawingTool = "shape";
				break;
			case "ellipse":
				addEllipse();
				activeDrawingTool = "shape";
				break;
			case "arrow":
				addArrow();
				activeDrawingTool = "shape";
				break;
			case "triangle":
				addTriangle();
				activeDrawingTool = "shape";
				break;
		}
		switch (activeDrawingTool) {
			case "shape":
				setActiveDrawingTool(true, false, false, { icon: "ToolMouse", title: "Zeichnen", key: "mouse" });
				//props.setCanSwipe(true);
				break;
			case "pencil":
				setActiveDrawingTool(true, false, true, newDrawingTool);
				//props.setCanSwipe(false);
				break;
			case "rubber":
				setActiveDrawingTool(true, false, false, { icon: "RubberToggle", title: "Radierer", key: "rubber" });
				//props.setCanSwipe(false);
				break;
		}
	};

	const setShapeConfig = (shapeConfig) => {
		setSelectedColor(itemsColor.find((item) => item.key === shapeConfig.stroke));
		setSelectedOpacity({ key: shapeConfig.opacity });
		setSelectedSize({ thickness: shapeConfig.thickness, fontsize: shapeConfig.fontsize || 24, key: shapeConfig.thicknessKey || "medium" });
	};

	const addShape = (shape) => {
		const shapeId = uuidv4();
		shape["id"] = shapeId;
		if (shape.type !== "line") {
			shape["x"] = getRandomInt();
			shape["y"] = getRandomInt();
		}
		if (shape.type !== "text") {
			shape["strokeWidth"] = getZoomed(selectedSize.thickness);
			shape["stroke"] = selectedColor.key;
		}
		shape["thickness"] = selectedSize.thickness;
		shape["opacity"] = selectedOpacity.key;
		const shs = mapCopy(shapes);
		shs.set(shapeId, shape);
		setShapes(shs);
		const newPresentationMeta = presentation.meta.map((x) => (x.index === activePage.index ? { ...activePage, shapes: shs } : x));
		setPresentation({ ...presentation, meta: [...newPresentationMeta] });
		return shape;
	};

	const addRectangle = () => {
		return addShape({ type: "rectangle", width: 100, height: 100 });
	};

	const addCircle = () => {
		return addShape({ type: "circle", radius: 70 });
	};

	const addEllipse = () => {
		return addShape({ type: "ellipse", width: 150, height: 100 });
	};

	const addArrow = () => {
		return addShape({ type: "arrow", points: [0, 0, 100, 0], fill: selectedColor.key, pointerLength: 20, pointerWidth: 20 });
	};

	const addTriangle = () => {
		return addShape({ type: "triangle", points: [0, 0, 200, 0, 100, -150], closed: true });
	};

	const addLine = () => {
		return addShape({ type: "line", points: [] });
	};

	const addText = () => {
		return addShape({
			type: "text",
			width: 250,
			align: "left",
			fontSizeSave: getZoomed(selectedSize.fontsize),
			thickness: selectedSize.thickness,
			fontSize: 32,
			fill: selectedColor.key,
			fontFamily: "Arial",
			text: "Text hier eingeben",
		});
	};

	const transformShape = (newAttrs, key) => {
		storeShape(scaleShape(newAttrs, 1.0 / localZoom), key);
	};

	const storeShape = (shape, key) => {
		const shs = mapCopy(shapes);
		shs.set(key, shape);
		setShapes(shs);
	};

	const drawShape = (shape) => {
		const scaledShape = scaleShape(shape, localZoom);
		const id = scaledShape.id;
		const commonProps = {
			shapeProps: scaledShape,
			isSelected: id === selectedId,
			onSelect: () => setSelectedShape(id),
			onChange: (newAttrs) => transformShape(newAttrs, id),
			isRubberSelected: selectedDrawingTool.key === "rubber",
			isPencilSelected: isPencilSelected,
			onDeleteShape: (elementRef, isSelected) => deleteShape(elementRef, isSelected, id),
		};
		switch (scaledShape.type) {
			case "rectangle":
				return <Rectangle key={id} {...commonProps} />;
			case "circle":
				return <Circle key={id} {...commonProps} />;
			case "ellipse":
				return <Ellipse key={id} {...commonProps} />;
			case "arrow":
				return <Arrow key={id} {...commonProps} />;
			case "triangle":
				return <Triangle key={id} {...commonProps} />;
			case "line":
				return <Line key={id} {...commonProps} isPencilSelected={isPencilSelected} isSelected={id === selectedId && !isPencilDrawing} />;
			case "text":
				return <Text key={id} {...commonProps} layerRef={layerEl} stageRef={stageEl} />;
		}
	};

	const deleteShape = (element, isSelected, elementId) => {
		if (isSelected) {
			setMySelectedId(null);
		}
		element.destroy();
		const shapesCopy = mapCopy(shapes);
		shapesCopy.delete(elementId);
		setShapes(shapesCopy);
		const newPresentationMeta = presentation.meta.map((x) => (x.index === activePage.index ? { ...activePage, shapes: shapesCopy } : x));
		setPresentation({ ...presentation, meta: [...newPresentationMeta] });
	};

	const setSelectedShape = (id) => {
		if (isDrawingToolActive && !isPencilSelected) {
			const shape = shapes.get(id);
			setShapeConfig({ stroke: shape?.stroke || shape?.fill, thickness: shape["thickness"], opacity: shape["opacity"], fontsize: getThicknessKeyFont(shape["thickness"]).fontsize, thicknessKey: getThicknessKeyFont(shape["thickness"]).key });
			setMySelectedId(id);
			//props.setCanCursorNavigation(shape.type !== "text");
		} else {
			setMySelectedId(null);
		}
	};

	const handleMouseDown = (e) => {
		if (e.target === e.target.getStage()) {
			setMySelectedId(null);
		}
		if (isDrawingToolActive && isPencilSelected) {
			setIsPencilDrawing(true);
			const selectedShape = addLine();
			setMySelectedId(selectedShape.id);
			const pos = e.target.getStage().getPointerPosition();
			const newAttrs = selectedShape;
			newAttrs.points.push(pos.x, pos.y);
			transformShape(newAttrs, selectedShape.id);
		}
	};

	const handleMouseMove = (e) => {
		if (isDrawingToolActive && isPencilDrawing) {
			const selectedShape = shapes.get(selectedId);
			const pos = e.target.getStage().getPointerPosition();
			const scaledShape = scaleShape(selectedShape, localZoom);
			scaledShape.points.push(pos.x, pos.y);
			transformShape(scaledShape, selectedId);
		}
	};

	const handleMouseUp = async () => {
		setIsPencilDrawing(false);
		//props.setCanCursorNavigation(true);
	};

	const activateZIndex = () => {
		return isDrawingToolActive;
	};

	const [menuOpen, setMenuOpen] = useState(false);
	const handleMenuOpen = () => {
		setMenuOpen(!menuOpen);
	};

	const getSelectedToolIcon = (toolKey) => {
		switch (toolKey) {
			case "pen":
				return <PenTool width={40} height={40} fill={selectedColor.key} />;
			case "pencil":
				return <PencilTool width={40} height={40} fill={selectedColor.key} />;
			case "marker":
				return <MarkerTool width={40} height={40} fill={selectedColor.key} />;
			default:
				return <CustomIcon name={selectedDrawingTool.icon} size={40} />;
		}
	};

	return (
		<Fragment>
			<>
				<IconButton sx={{ position: "fixed", bottom: 8, right: 8, boxShadow: 3, zIndex: 1002 }} onClick={handleMenuOpen}>
					{getSelectedToolIcon(selectedDrawingTool.key)}
				</IconButton>
				{menuOpen && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							position: "fixed",
							bottom: 72,
							right: 8,
							zIndex: 1002,
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							boxShadow: 2,
							backgroundColor: "#FFFFFF",
							opacity: 0.9,
						}}
					>
						<IconButton sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", width: "100%", borderRadius: 0 }} onClick={() => handleDrawingToolSelect({ key: "mouse" })}>
							<CustomIcon name="ToolMouse" />
						</IconButton>
						<MoreMenuItem content={<CustomIcon name="ToolText" />}>
							<IconButton onClick={() => handleDrawingToolSelect({ key: "text" })}>
								<CustomIcon name="ToolText" />
							</IconButton>
						</MoreMenuItem>
						<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
							<MoreMenuItem content={<CustomIcon name="ToolShape" />}>
								<Box sx={{ display: "flex", flexDirection: "column", gap: "8px", mx: "10px" }}>
									<Box sx={{ display: "flex", flexDirection: "row" }}>
										<IconButton onClick={() => handleDrawingToolSelect({ key: "square" })}>
											<CustomIcon name="ToolSquare" />
										</IconButton>
										<IconButton onClick={() => handleDrawingToolSelect({ key: "circle" })}>
											<CustomIcon name="ToolCircle" />
										</IconButton>
										<IconButton onClick={() => handleDrawingToolSelect({ key: "ellipse" })}>
											<CustomIcon name="ToolEllipse" />
										</IconButton>
										<IconButton onClick={() => handleDrawingToolSelect({ key: "triangle" })}>
											<CustomIcon name="ToolTriangle" />
										</IconButton>
										<IconButton onClick={() => handleDrawingToolSelect({ key: "arrow" })}>
											<CustomIcon name="ToolArrow" />
										</IconButton>
									</Box>
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<Slider
											value={selectedOpacity.key}
											onChange={handleOpacitySelect}
											min={0}
											max={1}
											step={0.1}
											sx={{
												width: "85%",
												"& .MuiSlider-thumb": {
													color: "#3E4F93",
												},
												"& .MuiSlider-track": {
													background: "linear-gradient(90deg, rgba(62, 79, 147, 0.00) 0%, #3E4F93 100%)",
												},
												"& .MuiSlider-rail": {
													background: "linear-gradient(90deg, rgba(62, 79, 147, 0.00) 0%, #3E4F93 100%)",
												},
											}}
										/>
									</Box>
									<Box sx={{ display: "flex", flexDirection: "row", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px" }}>
										{thicknessSizes.map((x, index) => (
											<IconButton
												key={x.key}
												sx={{
													borderRadius: 0,
													borderRight: index !== thicknessSizes.length - 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
													backgroundColor: selectedSize.key === x.key ? "rgba(0, 0, 0, 0.04)" : "#ffffff",
												}}
												onClick={() => handleSizeSelect(x)}
											>
												<CustomIcon name={x.icon} />
											</IconButton>
										))}
									</Box>
								</Box>
							</MoreMenuItem>
						</Box>
						<Box sx={{ position: "relative", display: "flex", flexDirection: "column", width: "72px", height: "181px", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
							<Box sx={{ position: "absolute", width: "20px", height: "45px", top: "0px", left: "73px", zIndex: 1003, background: "rgba(255, 252, 245, 1)" }} />
							<Box sx={{ position: "absolute", width: "20px", height: "45px", top: "45px", left: "73px", zIndex: 1003, background: "rgba(255, 252, 245, 1)" }} />
							<Box sx={{ position: "absolute", width: "20px", height: "45px", top: "90px", left: "73px", zIndex: 1003, background: "rgba(255, 252, 245, 1)" }} />
							<Box sx={{ position: "absolute", width: "20px", height: "45px", top: "135px", left: "73px", zIndex: 1003, background: "rgba(255, 252, 245, 1)" }} />
							<Box
								onClick={() => handleDrawingToolSelect({ key: "pen", icon: "PenToggle" })}
								sx={{ position: "absolute", left: selectedDrawingTool.key === "pen" ? "0px" : "20px", top: 0, cursor: "pointer", height: "45px", ":hover": { background: "rgba(0, 0, 0, 0.04)" } }}
							>
								<PenTool width={72} height={45} fill={selectedColor.key} />
							</Box>
							<Box
								onClick={() => handleDrawingToolSelect({ key: "pencil", icon: "PencilToggle" })}
								sx={{ position: "absolute", left: selectedDrawingTool.key === "pencil" ? "0px" : "20px", top: 45, cursor: "pointer", height: "45px", ":hover": { background: "rgba(0, 0, 0, 0.04)" } }}
							>
								<PencilTool width={72} height={45} fill={selectedColor.key} />
							</Box>
							<Box
								onClick={() => handleDrawingToolSelect({ key: "marker", icon: "MarkerToggle" })}
								sx={{ position: "absolute", left: selectedDrawingTool.key === "marker" ? "0px" : "20px", top: 90, cursor: "pointer", height: "45px", ":hover": { background: "rgba(0, 0, 0, 0.04)" } }}
							>
								<MarkerTool width={72} height={45} fill={selectedColor.key} />
							</Box>
							<Box
								onClick={() => handleDrawingToolSelect({ key: "rubber", icon: "RubberToggle" })}
								sx={{ position: "absolute", left: selectedDrawingTool.key === "rubber" ? "0px" : "20px", top: 135, cursor: "pointer", height: "45px", ":hover": { background: "rgba(0, 0, 0, 0.04)" } }}
							>
								<CustomIcon name="RubberToggle" width={72} height={45} size={null} />
							</Box>
						</Box>
						<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
							{itemsColorChunks.map((chunk, index) => (
								<Box key={`color_chunk_${index}`} sx={{ display: "flex", flexDirection: "row" }}>
									{chunk.map((item) => (
										<IconButton
											key={item.key}
											sx={{
												height: `${item.key === "#FFFFFF" ? "22px" : "24px"}`,
												width: `${item.key === "#FFFFFF" ? "22px" : "24px"}`,
												borderRadius: "50%",
												margin: "6px",
												border: `2px solid ${item.key === "#FFFFFF" ? "#BDBDBD" : "white"}`,
												boxShadow: `0 0 0 1px ${selectedColor.key === item.key ? item.key : "white"}`,
												backgroundColor: item.key,
												"&:hover": {
													backgroundColor: item.key,
													borderRadius: "50%",
													border: `2px solid ${item.key === "#FFFFFF" ? "#BDBDBD" : "white"}`,
													boxShadow: "0 0 0 1px " + item.key,
												},
											}}
											onClick={() => handleColorSelect(item)}
										/>
									))}
								</Box>
							))}
						</Box>
						<IconButton onClick={handleMenuOpen} sx={{ display: "flex", justifyContent: "center", cursor: "pointer", width: "100%", borderRadius: 0 }}>
							<Close />
						</IconButton>
					</Box>
				)}
			</>

			<div style={{ position: "absolute", zIndex: activateZIndex() ? 1001 : 1000, cursor: selectedDrawingTool.key === "rubber" ? "url('/images/ab/default/rubber_1.png') 11 30, crosshair" : "auto" }}>
				<Stage width={width} height={height} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onTouchStart={handleMouseDown} onTouchMove={handleMouseMove} onTouchEnd={handleMouseUp} ref={stageEl}>
					<Layer ref={layerEl}>
						{Array.from(shapes).map((shape) => {
							return drawShape(shape[1]);
						})}
					</Layer>
				</Stage>
			</div>
		</Fragment>
	);
}
