export const defTemplateHuF = [
	{
		control: "expandable",
		label_add: "Wohnsitz hinzufügen",
		name: "wohnsitz",
		label: "Wohnsitz(e)",
		delete_label: "der Wohnsitz",
		icon: "Home",
		cols: 12,
		children: [
			{
				name: "section_label_wohnsituation",
				label: "Wohnsitz {index}",
				control: "section_label",
				cols: 9,
			},
			{
				name: "strasse",
				label: "Straße",
				control: "input",
				cols: 9,
			},
			{
				name: "hausnummer",
				label: "Hausnummer",
				control: "input",
				cols: 3,
			},

			{
				name: "plz",
				label: "PLZ",
				control: "input",
				cols: 3,
			},
			{
				name: "ort",
				label: "Ort",
				control: "input",
				cols: 9,
			},
			{
				name: "auswahl",
				label: "Wohnsituation",
				control: "button_options",
				cols: 12,
				options: [
					{ value: "zur_miete", label: "zur Miete", icon: "Rent" },
					{ value: "eigentum", label: "im Eigentum", icon: "Homeownership" },
				],
			},
			{
				control: "section",
				name: "eigene_immobilie",
				label: "Eigene Immobilie",
				watch: ["haushalt.wohnsitz[{index}].auswahl"],
				visible: { func: "equal", field: "haushalt.wohnsitz[{index}].auswahl", value: "eigentum" },
				cols: 12,
				children: [
					{
						name: "wohnflaeche",
						label: "Wohnfläche",
						control: "sliderinput",
						unit: "qm",
						default: 0,
						min: 0,
						max: 250,
						step: 1,
						unit_type: "integer",
						cols: 12,
						prefix: "über",
					},
					{
						name: "neubausumme",
						label: "Neubausumme",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 2000000,
						step: 10000,
						unit_type: "currency_no_digit",
						cols: 12,
						prefix: "über",
						info: {
							title: "Hinweise",
							description:
								"Neubausumme bezeichnet den Betrag, der erforderlich ist, um ein Gebäude nach einem Totalschaden vollständig neu zu errichten, einschließlich Baukosten, Material, Arbeitslohn und Nebenkosten wie Architektenhonorare und Genehmigungsgebühren.",
						},
					},
					{
						name: "verkehrswert",
						label: "Verkehrswert",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 2000000,
						step: 10000,
						unit_type: "currency_no_digit",
						cols: 12,
						prefix: "über",
						info: {
							title: "Hinweise",
							description: "Verkehrswert ist der geschätzte Marktwert einer Immobilie, der unter normalen Verkaufsbedingungen auf dem freien Markt erzielt werden kann, basierend auf Lage, Zustand und aktuellen Marktverhältnissen.",
						},
					},
					{
						name: "darlehensstand",
						label: "Darlehensstand",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 2000000,
						step: 10000,
						unit_type: "currency_no_digit",
						cols: 12,
						prefix: "über",
					},
					{
						name: "baujahr",
						label: "Baujahr / letzte Sanierung",
						control: "year_picker",
						cols: 6,
						info: {
							title: "Hinweise",
							description:
								"Die Berechung und die Einschätzung des Haushalltsrisikos basiert auf dem Jahr der letzten Sanierung. Ist das Jahr der letzten Sanierung nicht bekannt oder wurde eine Sanierung nach dem Bau des Hauses noch nicht durchgeführt, so ist das Baujahr einzutragen.",
						},
					},
					{
						name: "sanierung_geplant",
						label: "Sanierung geplant",
						control: "switch",
						label_placement: "start",
						icon: "Refurbish",
						cols: 6,
						info: {
							title: "Hinweise",
							description: "Sofern Sie aktuell oder innerhalb der nächsten 12 Monate den Neubau bzw. den Umbau/Sanierung einer Immobilie durchführen bzw. planen, so erfassen Sie diese Information in einem Immobilienbaustein.",
						},
					},

					{
						name: "photovoltaik",
						label: "Photovoltaik",
						control: "switch",
						cols: 6,
						label_placement: "start",
						icon: "Solarpannel",
						info: {
							title: "Hinweise",
							description: "Sofern Sie Eigentümer einer Photovoltaik-Anlage sind, geben Sie dies an. Die Angabe wird benötigt, um ein Haftungsrisiko bezüglich der Anlage einschätzen zu können.",
						},
					},
					{
						name: "oeltank",
						label: "Öltank",
						control: "switch",
						cols: 6,
						label_placement: "start",
						icon: "Oiltank",
						info: {
							title: "Hinweise",
							description: "Sofern Sie Eigentümer eines Heizöltanks sind, geben Sie dies an. Dadurch können Haftungsrisiken zum Gewässerschutz eingeschätzt werden. Darunter fallen auch alle anderen Anlagen mit wasserschädlichen Stoffen.",
						},
					},
					{
						name: "hausrat_manuell",
						label: "Wert des Hausrats manuell festlegen",
						control: "switch",
						cols: 12,
						label_placement: "end",
						info: {
							title: "Hinweise",
							description: "Der Wert des Hausrats liegt im Schnitt bei 650€ pro Quadratmeter. Dieser Wert wird automatisch berechnet. Sie können den Wert jedoch auch manuell festlegen.",
						},
					},
					{
						name: "hausrat_wert",
						label: "Wert des Hausrats",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 200000,
						step: 1000,
						unit_type: "currency_no_digit",
						cols: 12,
						prefix: "über",
						watch: ["haushalt.wohnsitz"],
						visible: { func: "equal_bool", field: "hausrat_manuell", value: "true" },
					},
				],
			},
			{
				control: "section",
				name: "mietobjekt",
				label: "Mietobjekt",
				watch: ["haushalt.wohnsitz[{index}].auswahl"],
				visible: { func: "equal", field: "haushalt.wohnsitz[{index}].auswahl", value: "zur_miete" },
				cols: 12,
				children: [
					{
						name: "wohnflaeche",
						label: "Wohnfläche",
						control: "sliderinput",
						unit: "qm",
						default: 0,
						min: 0,
						max: 350,
						step: 1,
						unit_type: "integer",
						cols: 12,
					},

					{
						name: "monatliche_miete",
						label: "monatliche Miete",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 10000,
						step: 100,
						unit_type: "currency_no_digit",
						cols: 12,
						info: {
							title: "Hinweise",
							description: "Die monatliche Miete inkl. Nebenkosten ist relevant, um einen Überblick über ihre freie Liquidität zu erhalten.",
						},
					},
					{
						name: "hausrat_manuell",
						label: "Wert des Hausrats manuell festlegen",
						control: "switch",
						cols: 12,
						label_placement: "end",
						info: {
							title: "Hinweise",
							description: "Der Wert des Hausrats liegt im Schnitt bei 650€ pro Quadratmeter. Dieser Wert wird automatisch berechnet. Sie können den Wert jedoch auch manuell festlegen.",
						},
					},
					{
						name: "hausrat_wert",
						label: "Wert des Hausrats",
						control: "sliderinput",
						unit: "€",
						default: 0,
						min: 0,
						max: 200000,
						step: 1000,
						unit_type: "currency_no_digit",
						cols: 12,
						prefix: "über",
						watch: ["haushalt.wohnsitz"],
						visible: { func: "equal_bool", field: "hausrat_manuell", value: "true" },
					},
				],
			},
		],
	},
	{
		control: "expandable",
		label_add: "Immobilie hinzufügen",
		name: "immobilien",
		label: "Fremdgenutzte Immobilien",
		icon: "Home",
		cols: 12,
		children: [
			{
				name: "strasse",
				label: "Straße",
				control: "input",
				cols: 9,
			},
			{
				name: "hausnummer",
				label: "Hausnummer",
				control: "input",
				cols: 3,
			},

			{
				name: "plz",
				label: "PLZ",
				control: "input",
				cols: 3,
			},
			{
				name: "ort",
				label: "Ort",
				control: "input",
				cols: 9,
			},
			{
				name: "neubausumme",
				label: "Neubausumme",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description:
						"Neubausumme bezeichnet den Betrag, der erforderlich ist, um ein Gebäude nach einem Totalschaden vollständig neu zu errichten, einschließlich Baukosten, Material, Arbeitslohn und Nebenkosten wie Architektenhonorare und Genehmigungsgebühren.",
				},
			},
			{
				name: "verkehrswert",
				label: "Verkehrswert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description: "Verkehrswert ist der geschätzte Marktwert einer Immobilie, der unter normalen Verkaufsbedingungen auf dem freien Markt erzielt werden kann, basierend auf Lage, Zustand und aktuellen Marktverhältnissen.",
				},
			},
			{
				name: "darlehensstand",
				label: "Darlehensstand",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "baujahr",
				label: "Baujahr / letzte Sanierung",
				control: "year_picker",
				cols: 6,
				info: {
					title: "Hinweise",
					description:
						"Die Berechung und die Einschätzung des Haushalltsrisikos basiert auf dem Jahr der letzten Sanierung. Ist das Jahr der letzten Sanierung nicht bekannt oder wurde eine Sanierung nach dem Bau des Hauses noch nicht durchgeführt, so ist das Baujahr einzutragen.",
				},
			},
			{
				name: "sanierung_geplant",
				label: "Sanierung geplant",
				control: "switch",
				label_placement: "start",
				icon: "Refurbish",
				cols: 6,
				info: {
					title: "Hinweise",
					description: "Sofern Sie aktuell oder innerhalb der nächsten 12 Monate den Neubau bzw. den Umbau/Sanierung einer Immobilie durchführen bzw. planen, so erfassen Sie diese Information in einem Immobilienbaustein.",
				},
			},

			{
				name: "photovoltaik",
				label: "Photovoltaik",
				control: "switch",
				cols: 6,
				label_placement: "start",
				icon: "Solarpannel",
				info: {
					title: "Hinweise",
					description: "Sofern Sie Eigentümer einer Photovoltaik-Anlage sind, geben Sie dies an. Die Angabe wird benötigt, um ein Haftungsrisiko bezüglich der Anlage einschätzen zu können.",
				},
			},
			{
				name: "oeltank",
				label: "Öltank",
				control: "switch",
				cols: 6,
				label_placement: "start",
				icon: "Oiltank",
				info: {
					title: "Hinweise",
					description: "Sofern Sie Eigentümer eines Heizöltanks sind, geben Sie dies an. Dadurch können Haftungsrisiken zum Gewässerschutz eingeschätzt werden. Darunter fallen auch alle anderen Anlagen mit wasserschädlichen Stoffen.",
				},
			},
		],
	},

	{
		control: "expandable",
		label_add: "Grundstück hinzufügen",
		name: "grundstuecke",
		label: "Grundstücke",
		icon: "Home",
		cols: 12,
		children: [
			{
				name: "strasse",
				label: "Straße",
				control: "input",
				cols: 9,
			},
			{
				name: "hausnummer",
				label: "Hausnummer",
				control: "input",
				cols: 3,
			},

			{
				name: "plz",
				label: "PLZ",
				control: "input",
				cols: 3,
			},
			{
				name: "ort",
				label: "Ort",
				control: "input",
				cols: 9,
			},
			{
				name: "verkehrswert",
				label: "Verkehrswert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description: "Verkehrswert ist der geschätzte Marktwert einer Immobilie, der unter normalen Verkaufsbedingungen auf dem freien Markt erzielt werden kann, basierend auf Lage, Zustand und aktuellen Marktverhältnissen.",
				},
			},
			{
				name: "darlehensstand",
				label: "Darlehensstand",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "photovoltaik",
				label: "Photovoltaik",
				control: "switch",
				cols: 6,
				label_placement: "start",
				icon: "Solarpannel",
				info: {
					title: "Hinweise",
					description: "Sofern Sie Eigentümer einer Photovoltaik-Anlage sind, geben Sie dies an. Die Angabe wird benötigt, um ein Haftungsrisiko bezüglich der Anlage einschätzen zu können.",
				},
			},
			{
				name: "oeltank",
				label: "Öltank",
				control: "switch",
				cols: 6,
				label_placement: "start",
				icon: "Oiltank",
				info: {
					title: "Hinweise",
					description: "Sofern Sie Eigentümer eines Heizöltanks sind, geben Sie dies an. Dadurch können Haftungsrisiken zum Gewässerschutz eingeschätzt werden. Darunter fallen auch alle anderen Anlagen mit wasserschädlichen Stoffen.",
				},
			},
		],
	},

	{
		control: "expandable",
		label_add: "Kaufobjekt hinzufügen",
		name: "kaufobjekte",
		label: "Immobilienkauf geplant",
		icon: "Home",
		info: {
			title: "Hinweise",
			description: "Sofern heute oder in Zukunft der Erwerb oder Bau einer eigengenutzten Immobilie geplant ist, sollten hier Angaben erfasst werden.",
		},
		cols: 12,
		children: [
			{
				name: "wert",
				label: "Wert der Immobilie",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 10000,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "erwerb_zum",
				label: "Erwerb geplant zum",
				control: "year_month_picker",
				cols: 6,
			},
		],
	},
];
