export const getThemeColor = (theme, key) => {
	if (theme.palette[key]) return theme.palette[key].main;
	return "#FF0000";
};

export const getDisplayName = (person) => {
	if (person === null || person === undefined) return "";
	if (person.nachname === null) {
		if (person.vorname === null) return "./.";
		return person.vorname;
	}
	return person.vorname + " " + person.nachname;
};

export const doReplace = (label, kunde, berater) => {
	return label?.replace("{kunde.displayname}", getDisplayName(kunde?.kunde)).trim();
};

export const createExtendedPerson = (person) => {
	let newObj = { ...person };
	delete newObj.custom;

	for (let key in person.custom) {
		if (person.custom.hasOwnProperty(key) && !newObj.hasOwnProperty(key)) {
			newObj[key] = person.custom[key];
		}
	}

	return newObj;
};

export const createChunks = (items, chunkSize) => {
	return items.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / chunkSize);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [];
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);
};
