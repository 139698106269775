import { Box, Dialog, Typography, DialogContent, DialogActions, Button, Divider } from "@mui/material";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./SwiperDialog.css";

SwiperDialog.propTypes = {
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	activeItem: PropTypes.object,
};

function SwiperDialog(props) {
	const { isOpen, handleClose, activeItem } = props;
	const appWindow = useRecoilValue(appWindowAtom);
	const [images, setImages] = useState([]);

	useEffect(() => {
		if (activeItem)
			setImages(
				activeItem.info.images.map((image) => {
					return { original: image, thumbnail: image, originalHeight: appWindow.contentHeight * 0.75, originalWidth: appWindow.contentWidth * 0.8 };
				})
			);
	}, [activeItem]);

	if (!activeItem) return <></>;
	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="80%" PaperProps={{ sx: { borderRadius: "8px", padding: "16px", pr: "42px", pl: "42px" } }}>
			<DialogContent sx={{ margin: 0, padding: 0, height: "80vh" }}>
				<Box sx={{ display: "flex", width: "100%", p: 0, m: 0 }}>
					<Box sx={{ flex: "3", width: "60%", marginRight: "16px", display: "flex", flexDirection: "column" }}>
						<ImageGallery items={images} renderItem={(item) => <img src={item.original} alt={item.originalAlt} style={{ objectFit: "contain", width: appWindow.contentWidth * 0.8 + "px", height: appWindow.contentHeight * 0.75 }} />} />
					</Box>
					<Box sx={{ flex: "1", width: "40%", p: 0, m: 0 }}>
						<Box sx={{ overflowY: "auto", maxHeight: appWindow.contentHeight }}>
							<Typography variant="h4">{activeItem.info.title}</Typography>
							<Typography variant="body1" dangerouslySetInnerHTML={{ __html: activeItem.info.description }}></Typography>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ alignItems: "center" }}>
				<Button onClick={handleClose}>schließen</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SwiperDialog;
