import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  listItem: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
  },
});

export const ListItem = ({ children }) => (
  <View>
    <Text style={styles.listItem}>{children}</Text>
  </View>
);

ListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
