import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

GaugeChart.propTypes = {
	value: PropTypes.number,
	width: PropTypes.number,
};

export default function GaugeChart(props) {
	const { value, width } = props;
	const theme = useTheme();

	return (
		<Gauge
			value={value}
			startAngle={-90}
			endAngle={90}
			width={width}
			sx={{
				[`& .${gaugeClasses.valueText}`]: {
					fontSize: 40,
					transform: "translate(0px, -20px)",
				},
				[`& .${gaugeClasses.valueArc}`]: {
					fill: "#52b202",
				},
			}}
			text={({ value, valueMax }) => `${value}%`}
		/>
	);
}
