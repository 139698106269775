import { FormControl, TextField, Autocomplete, Chip } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

MDDMultiSelect.propTypes = {
	item: PropTypes.object.isRequired,
	variant: PropTypes.string,
	control: PropTypes.object.isRequired,
	trigger: PropTypes.func,
	getValues: PropTypes.func,
	services: PropTypes.object,
	disabled: PropTypes.bool,
};

const getWatch = (toWatch) => {
	if (!toWatch) return toWatch;
	return toWatch.map((watch) => {
		if (watch.indexOf(".*") !== -1) {
			return watch.split(".*").pop();
		} else if (watch.indexOf("*") === 0) {
			return watch.slice(1);
		} else {
			return watch;
		}
	});
};

function MDDMultiSelect(props) {
	const { item, variant = "outlined", control, trigger, services, getValues, disabled } = props;

	const [options, setOptions] = useState(item.options || []);
	const watched = useWatch({ name: getWatch(item.watch) || [undefined], control: control });
	const addAll = item.addAll || false;

	useEffect(() => {
		const fetchOptions = async () => {
			let newOptions = [];
			if (item.service) {
				newOptions = await services[item.service](getValues());
			} else {
				newOptions = item.options;
			}
			if (addAll) {
				newOptions.unshift({ label: "Alle", value: "**alle" });
			}
			setOptions(newOptions);
		};

		fetchOptions();
	}, [item.service, watched]);

	return (
		<FormControl fullWidth sx={{ pb: "8px" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || []}
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						multiple
						disabled={disabled}
						options={options}
						getOptionLabel={(option) => option.label}
						renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} key={option.value} />)}
						renderInput={(params) => <TextField {...params} label={item.label} variant={variant} size="small" sx={{ pt: "2px" }} disabled={disabled} />}
						value={options.filter((option) => value.includes(option.value))}
						onChange={(event, newValue) => {
							if (newValue.some((option) => option.value === "**alle")) {
								const allValues = options.filter((option) => option.value !== "**alle").map((option) => option.value);
								onChange(allValues);
							} else {
								onChange(newValue.map((option) => option.value));
							}
							trigger(item.name);
						}}
					/>
				)}
			/>
		</FormControl>
	);
}

export default MDDMultiSelect;
