export const storeAuthToken = (authToken, refrehToken, expires = 99) => {
  const authData = { authToken: authToken, refrehToken: refrehToken, expires: new Date().getTime() + (expires - 60) * 1000 };
  sessionStorage.setItem("authData", JSON.stringify(authData));
};

export const getAuthToken = async () => {
  const authData = JSON.parse(sessionStorage.getItem("authData"));
  if (!authData) return null;
  const now = new Date().getTime();
  /*if (Number(authData.expires) < Number(now)) {
    console.log("doRefresh");
    try {
      const res = await axios.post(import.meta.env.VITE_BACKEND + "/api/auth/refresh", { token: authData.token });
      if (res.status === 200) {
        storeAuthToken(res.data.access_token, res.data.expires_in);
        return res.data.access_token;
      } else {
        console.log("OK not 200", res);
      }
    } catch (e) {
      console.log("error", e);
      return null;
    }
  }*/
  return authData.authToken;
};

export const setUser = (user) => {
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

export const logout = async () => {
  /* axios
    .get(import.meta.env.VITE_BACKEND + "/api/auth/me", {
      headers: { Authorization: "bearer " + (await getAuthToken()) },
    })
    .then((res) => {
      if (res.status === 200) {
        sessionStorage.removeItem("authData");
      } else {
        console.log("OK not 200", res);
      }
    })
    .catch((e) => {
      console.log("error", e);
    });*/
};
