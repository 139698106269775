import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { isItemHidden } from "../DMFhelperService";

DMF_SectionLabel.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	getValues: PropTypes.func,
};

function DMF_SectionLabel(props) {
	const { item, control, getValues } = props;
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	return (
		<Box sx={{ pt: "0px", pb: "0px", display: hidden ? "none" : "block" }}>
			<Typography variant="h6" sx={{ pb: item.description ? "0px" : "16px" }}>
				{item.label}
			</Typography>
		</Box>
	);
}

export default DMF_SectionLabel;
