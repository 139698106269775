/* eslint-disable react/prop-types */
import { pdf } from "@react-pdf/renderer";
import { getCompanyInfo } from "mock/pdf_test";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";
import { backendGet } from "services/dataService";
import { calcIst, getFamilienScore } from "services/DINIstCalculationService";
import { calcSoll } from "services/DINSollCalculationService";
import { FinanzkonzeptCharts, FinanzkonzeptPDF } from "./FinanzkonzeptPDF";

import { userAtom } from "recoil/atoms/userAtom";
import { getDatum } from "services/formatService";
import PropTypes from "prop-types";

export default function AnalyseDownload() {
	const bedarf = useRecoilValue(bedarfAtom);
	const user = useRecoilValue(userAtom);
	const [vertraege, setVertraege] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [vertragszuordnung, setVertragszuordnung] = useRecoilState(vertragszuordnungAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const [calculatedDisplaywerte, setCalculatedDisplaywerte] = useState(calcIst(calculatedSollwerte, [], bedarf));

	const familienScore = getFamilienScore(calculatedDisplaywerte);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoadedVertragszuordnung, setIsLoadedVertragszuordnung] = useState(false);
	const [isLoadedVertraege, setIsLoadedVertraege] = useState(false);

	const setResultVertragszuordnung = (data) => {
		if (data?.zuordnung) {
			setVertragszuordnung(data.zuordnung);
		}
		setIsLoadedVertragszuordnung(true);
	};

	useEffect(() => {
		setCalculatedDisplaywerte(calcIst(calculatedSollwerte, vertragszuordnung, bedarf));
	}, [vertragszuordnung]);

	useEffect(() => {
		backendGet({ label: "Kunden", route: "vertrag/haushalt/" + bedarf.kunde.haushalt_id, setResult: setVertragsspiegelData, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
		backendGet({ label: "Vertragszuordnung", route: "vertragszuordnung/" + bedarf.kunde.haushalt_id, setResult: setResultVertragszuordnung, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	}, [bedarf]);

	const setVertragsspiegelData = (data) => {
		setVertraege(data);
		setIsLoadedVertraege(true);
	};

	useEffect(() => {
		if (isLoaded && isLoadedVertraege && isLoadedVertragszuordnung) {
			generatePDFBase64();
		}
	}, [isLoaded, isLoadedVertraege, isLoadedVertragszuordnung]);

	const generatePDFBase64 = async () => {
		const blob = await pdf(
			<FinanzkonzeptPDF
				pdfModel={{
					company: getCompanyInfo("Formaxx"),
					createdAt: getDatum(new Date()),
					norms: [
						{ icon: "LineiconSearch", header: "Transparenz", text: "Alle angelegten Standards und Normen sind nachvollziehbar und öffentlich einsehbar." },
						{ icon: "LineiconScience", header: "Wissenschaftlichkeit", text: "Die Prämissen und die Rahmenparameter sind wissenschaftlich abgesichert und werden regelmäßig evaluiert." },
						{ icon: "LineiconCouple", header: "Individualität", text: "Eine objektive und neutrale Analyse ist die Basis für individuelle Prioritätensetzung." },
						{ icon: "LineiconRuler", header: "Einheitlichkeit", text: "Nur eine einheitliche Vorgehensweise bei der Analyse schafft vergleichbare Ergebnisse." },
						{
							icon: "LineiconChart",
							header: "Effizienz",
							text: "Ziel der Standardisierung in der Finanzberatung ist, alle relevanten Aspekte unkompliziert und schnell zu erfassen, zu verarbeiten und verfügbar zu machen.",
						},
						{ icon: "LineiconBank", header: "DIN-Standard & DIN-Normen", text: "Die DIN-Standards und die DIN-Normen schaffen Orientierung, Sicherheit und Verlässlichkeit." },
					],
					inhalte: [
						"Ihre Ziele und Wünsche",
						"Einnahmen-Ausgaben-Rechnung",
						"Vermögensbilanz",
						"Persönlicher Finanzscore",
						"Übersicht Ihrer Finanzthemen",
						"Ihr Finanzfahrplan",
						"Abschließender Ausblick",
						"Vertragsspiegel",
						"Ihre persönlichen Angaben",
						"Rahmenparameter & Haftung",
					],
					bedarf: bedarf,
					vertraege: vertraege,
					berater: user.berater,
					displaywerte: calculatedDisplaywerte,
					familienScore: familienScore,
				}}
			/>
		).toBlob();

		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = "Analyse.pdf";
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	return <FinanzkonzeptCharts familienScore={familienScore} setIsLoaded={setIsLoaded} ausgaben={bedarf.haushalt.ausgaben} />;
}

AnalyseDownload.propTypes = {};
