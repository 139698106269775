import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import PropTypes from "prop-types";

YesNoComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  onYes: PropTypes.func,
};

export default function YesNoComponent(props) {
  const { open, onClose, title, message, onYes } = props;

  const handleYesClick = () => {
    if (onYes) {
      onYes();
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYesClick} variant="contained">
          Ja
        </Button>
        <Button onClick={onClose} variant="contained">
          Nein
        </Button>
      </DialogActions>
    </Dialog>
  );
}
