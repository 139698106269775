import { TextField, Box } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { isItemHidden } from "../DMFhelperService";

MDDInput.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	size: PropTypes.string,
	trigger: PropTypes.func,
	disabled: PropTypes.bool,
	getValues: PropTypes.func,
};

function MDDInput(props) {
	const { item, variant = "outlined", control, size = "small", trigger, getValues, disabled } = props;
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	if (item.update_func) {
		if (item.update_func === "processDeckung") {
			const deckungName = item.name.replace("wert", "deckung_obj").replace("[", ".").replace("]", "");
			const obj = getValues(deckungName);
			if (obj) {
				const deckungDescription = obj.obj;
				if (deckungDescription.field_type === "SliderInput") {
					item.visible = { func: "const", value: "true" };
					item.max = deckungDescription.slider_max || 1000000;
				} else item.visible = { func: "const", value: "false" };
			}
		}
	}

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	return (
		<Box sx={{ pb: "8px", flexDirection: "column", display: hidden ? "none" : "flex" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || ""}
				render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
					return (
						<Box display="flex" alignItems="center">
							{item.icon && <Box sx={{ pr: "8px" }}>{item.icon && <CustomIcon name={item.icon} size={48} />}</Box>}
							<TextField disabled={disabled} onChange={onChange} value={value} label={item.label} variant={variant} fullWidth size={size} {...(invalid && { error: true, helperText: error.message })} onBlur={() => trigger(item.name)} />
						</Box>
					);
				}}
			/>
		</Box>
	);
}
export default MDDInput;
