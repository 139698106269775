import { getDatum } from "services/formatService";

export const getCompanyInfo = (companyName) => {
	switch (companyName) {
		case "Mayflower":
			return {
				company: "Mayflower",
				primaryColor: "#000000",
				secondaryColor: "#B5152B",
				frontPageImageUrl: import.meta.env.VITE_BACKEND + "/ImageHeaderMayflower.png",
				logoUrl: import.meta.env.VITE_BACKEND + "/Mayflower-logo.png",
				strasseNummer: "Mergenthalerallee 15-21",
				plz: "D-65760",
				ort: "Eschborn",
				website: "mayflower.de",
				telefon: "+49 (0) 6196 5868470",
				fax: "+49 (0) 6196 5868471",
				email: "service@formaxx.ag",
				footerLineRight: 100,
				logoWidth: 70,
				logoHeight: 35,
			};
		case "Defino":
			return {
				company: "Defino",
				primaryColor: "#000000",
				secondaryColor: "#0E3A5C",
				frontPageImageUrl: import.meta.env.VITE_BACKEND + "/ImageHeaderDefino.png",
				logoUrl: import.meta.env.VITE_BACKEND + "/Defino-logo.png",
				strasseNummer: "Mergenthalerallee 15-21",
				plz: "D-65760",
				ort: "Eschborn",
				website: "defino.de",
				telefon: "+49 (0) 6196 5868470",
				fax: "+49 (0) 6196 5868471",
				email: "service@formaxx.ag",
				footerLineRight: 120,
				logoWidth: 100,
				logoHeight: 27,
			};
		case "Formaxx":
			return {
				company: "FORMAXX",
				primaryColor: "#2C4282",
				secondaryColor: "#D6B38B",
				frontPageImageUrl: import.meta.env.VITE_BACKEND + "/ImageHeaderFormaxx.png",
				logoUrl: import.meta.env.VITE_BACKEND + "/Formaxx-Logo.png",
				strasseNummer: "Mergenthalerallee 15-21",
				plz: "D-65760",
				ort: "Eschborn",
				website: "formaxx.de",
				telefon: "+49 (0) 6196 5868470",
				fax: "+49 (0) 6196 5868471",
				email: "service@formaxx.ag",
				footerLineRight: 200,
				logoWidth: 176,
				logoHeight: 20,
			};
		default:
			return {
				company: "FORMAXX",
				primaryColor: "#2C4282",
				secondaryColor: "#D6B38B",
				frontPageImageUrl: import.meta.env.VITE_BACKEND + "/ImageHeaderFormaxx.png",
				logoUrl: import.meta.env.VITE_BACKEND + "/Formaxx-logo.png",
				strasseNummer: "Mergenthalerallee 15-21",
				plz: "D-65760",
				ort: "Eschborn",
				website: "formaxx.de",
				telefon: "+49 (0) 6196 5868470",
				fax: "+49 (0) 6196 5868471",
				email: "service@formaxx.ag",
				footerLineRight: 200,
				logoWidth: 176,
				logoHeight: 20,
			};
	}
};

export const pdfModel = {
	// change company name to test: Mayflower || Defino || Formaxx
	...getCompanyInfo("Formaxx"),
	customer: {
		andere: "Herr",
		vorname: "Michael",
		nachname: "Schubert",
	},
	advisor: {
		andere: "Herr",
		vorname: "Marvin",
		nachname: "Kalmbach",
		telefon: "+49 30 901820",
		email: "Marvin.kalmbach@definet.de",
	},
	createdAt: getDatum(new Date()),
	norms: [
		{ icon: "LineiconSearch", header: "Transparenz", text: "Alle angelegten Standards und Normen sind nachvollziehbar und öffentlich einsehbar." },
		{ icon: "LineiconScience", header: "Wissenschaftlichkeit", text: "Die Prämissen und die Rahmenparameter sind wissenschaftlich abgesichert und werden regelmäßig evaluiert." },
		{ icon: "LineiconCouple", header: "Individualität", text: "Eine objektive und neutrale Analyse ist die Basis für individuelle Prioritätensetzung." },
		{ icon: "LineiconRuler", header: "Einheitlichkeit", text: "Nur eine einheitliche Vorgehensweise bei der Analyse schafft vergleichbare Ergebnisse." },
		{
			icon: "LineiconChart",
			header: "Effizienz",
			text: "Ziel der Standardisierung in der Finanzberatung ist, alle relevanten Aspekte unkompliziert und schnell zu erfassen, zu verarbeiten und verfügbar zu machen.",
		},
		{ icon: "LineiconBank", header: "DIN-Standard & DIN-Normen", text: "Die DIN-Standards und die DIN-Normen schaffen Orientierung, Sicherheit und Verlässlichkeit." },
	],
	inhalte: [
		"Ihre Ziele und Wünsche",
		"Einnahmen-Ausgaben-Rechnung",
		"Vermögensbilanz",
		"Persönlicher Finanzscore",
		"Übersicht Ihrer Finanzthemen",
		"Ihr Finanzfahrplan",
		"Abschließender Ausblick",
		"Vertragsspiegel",
		"Ihre persönlichen Angaben",
		"Rahmenparameter & Haftung",
	],
	investitionsziele: [
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2025", wunschbetrag: 25000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2021", wunschbetrag: 35000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2022", wunschbetrag: 45000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2023", wunschbetrag: 55000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2015", wunschbetrag: 15000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2014", wunschbetrag: 41000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2017", wunschbetrag: 22000 },
		{ ziel: "Erwerb Kfz", zielzeitpunkt: "August 2025", wunschbetrag: 77000 },
	],
	persoenliche_und_berufliche_ziele: [
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
		{ ziel: "", zielzeitpunkt: "", wunschbetrag: null },
	],

	einnahmen: [
		{ name: "Gehalt", price: 25000 },
		{ name: "Gehalt", price: 3200 },
		{ name: "Gehalt", price: 5700 },
		{ name: "Gehalt", price: 1000 },
	],
	ausgaben: [
		{ name: "Miete", price: 700 },
		{ name: "Miete", price: 300 },
		{ name: "Miete", price: 600 },
		{ name: "Miete", price: 200 },
		{ name: "Miete", price: 600 },
		{ name: "Miete", price: 300 },
	],
	visualisierung: [
		{ label: "Wohnen", price: 500, bulletColor: "#C62828" },
		{ label: "Urlaub", price: 500, bulletColor: "#F9A825" },
		{ label: "Label", price: 200, bulletColor: "#0057B2" },
		{ label: "Label", price: 900, bulletColor: "#512DA8" },
		{ label: "Label", price: 700, bulletColor: "#2E7D32" },
	],
	aktiva: [
		{ name: "Hauptwohnsitz", price: 500000 },
		{ name: "BMW X3", price: 60000 },
		{ name: "Guthaben Girokonto", price: 10000 },
		{ name: "Sonstige Sach- / Vermögenswerte", price: 100000 },
		{ name: "Sonstige Sach- / Vermögenswerte", price: 100000 },
		{ name: "Sonstige Sach- / Vermögenswerte", price: 100000 },
	],
	passiva: [
		{ name: "Darlehen Hauptwohnsitz", price: 250000 },
		{ name: "Konsumkredit", price: 25000 },
		{ name: "Sonstige Verbindlichkeit", price: -350000 },
		{ name: "Sonstige Verbindlichkeit", price: 5000 },
	],
	familienScore: [
		{ percentage: 92, trackColor: "#2E7D32" },
		{ percentage: 8, trackColor: "#D32F2F" },
	],
	chartTableItems: [
		{
			segmentLabel: "Arbeitskraftverlust",
			segmentName: "Erwerbstätigkeit",
			renteLabel: "Empfohlene Rente",
			renteValue: "1.564 €/ Mo",
			versicherungssummeLabel: "Rente",
			versicherungssummeValue: "1.500 €/ Mo",
			abgesichertDurch: "Haftpflichtversicherung",
		},
		{
			segmentLabel: "Arbeitskraftverlust",
			segmentName: "Berufs-/ Dienstunfähigkeit",
			renteLabel: "Empfohlene Rente",
			renteValue: "1.150.111 €",
			versicherungssummeLabel: "Rente",
			versicherungssummeValue: "1.000 €/ Mo",
			abgesichertDurch: "Berufsunfähigkeitsversicherung",
		},
		{
			segmentLabel: "Arbeitskraftverlust",
			segmentName: "Berufs-/ Dienstunfähigkeit",
			renteLabel: "Empfohlenes Krankengeld",
			renteValue: "1.564 €/ Mo",
			versicherungssummeLabel: "Krankengeld",
			versicherungssummeValue: "1.500 €/ Mo",
			abgesichertDurch: "Berufsunfähigkeitsversicherung",
		},
		{
			segmentLabel: "Arbeitskraftverlust",
			segmentName: "Erwerbstätigkeit",
			renteLabel: "Empfohlene Versicherungssumme",
			renteValue: "20.000 €",
			versicherungssummeLabel: "Versicherungssumme",
			versicherungssummeValue: "1.500 €/ Mo",
			abgesichertDurch: "Krankentagegeldversicherung",
		},
		{
			segmentLabel: "Name DIN Segment",
			segmentName: "Folgekosten Unfall/ Krankheit",
			renteLabel: "Empfohlene Versicherungssumme",
			renteValue: "1.564 €/ Mo",
			versicherungssummeLabel: "Versicherungssumme",
			versicherungssummeValue: "1.500 €/ Mo",
			abgesichertDurch: "Krankentagegeldversicherung",
		},
	],
	anhang: [
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
		{
			gesellschaft: "Allianz Versicherungs AG",
			vertragsnr: "123456789",
			sparte: "Sach",
			art: "Privathaftpflicht",
			vertragsbeginn: "01.05.2022 - 30.04.2023",
			beitrag: "90,00 € jährlich",
			versicherungsnehmer: "Max Muster",
			versicherte: "Max Muster; Susi Muster",
			leistungen: "Personenschäden Versicherungssumme 10. Mio.",
		},
	],
};
