import { Box, Typography } from "@mui/material";
import GaugeChart from "components/GaugeChart";
import Tile from "components/Tile";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { haushaltAtom, kundeDisplayNameSelector } from "recoil/atoms/haushaltAtom";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";
import { backendGet } from "services/dataService";
import { calcIst, getFamilienScore } from "services/DINIstCalculationService";
import { calcSoll } from "services/DINSollCalculationService";
import { getDatum } from "services/formatService";
import Beziehungen from "./Beziehungen";
import CustomIcon from "./CustomIcon";
import Telekommunikation from "./Telekommunikation";
import Chart from "components/Chart";
import { getDisplayName } from "services/helperService";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";

const getScores = (werte) => {
	const relevant = werte.filter((wert) => wert.relevant);
	const stufe1 = werte.filter((wert) => wert.bedarfsstufe === "1");
	const stufe2 = werte.filter((wert) => wert.bedarfsstufe === "2");

	return {
		gesamt: Math.round(relevant.reduce((acc, curr) => acc + curr.ist_percent, 0) / relevant.length),
		stufe1: Math.round(stufe1.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe1.length),
		stufe2: Math.round(stufe2.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe2.length),
	};
};

AnalyseErgebnisse.propTypes = {
	hideTitle: PropTypes.bool,
};

export default function AnalyseErgebnisse({ hideTitle = false }) {
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const kundeDisplayName = useRecoilValue(kundeDisplayNameSelector);
	const appWindow = useRecoilValue(appWindowAtom);
	const [isLoading, setIsLoading] = useState(true);
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const { param } = useParams();
	const [vertragszuordnung, setVertragszuordnung] = useRecoilState(vertragszuordnungAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const [calculatedDisplaywerte, setCalculatedDisplaywerte] = useState(calcIst(calculatedSollwerte, [], bedarf));

	const relevant = calculatedDisplaywerte.filter((wert) => wert.relevant);
	const familienScores = getScores(calculatedDisplaywerte);

	useEffect(() => {
		setCalculatedDisplaywerte(calcIst(calculatedSollwerte, vertragszuordnung, bedarf));
	}, [vertragszuordnung]);

	const setResultVertragszuordnung = (data) => {
		if (data?.zuordnung) {
			setVertragszuordnung(data.zuordnung);
		}
	};

	useEffect(() => {
		backendGet({ label: "Vertragszuordnung", route: "vertragszuordnung/" + bedarf.kunde.haushalt_id, setResult: setResultVertragszuordnung, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	}, [bedarf]);

	const getPieChart = (percent) => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "64px", width: "100%" }}>
				<Box sx={{ flex: "1 1 40%", position: "relative", alignContent: "center" }}>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Chart
							chartDef={{
								unit: "%",
								size: "48",
								chart_type: "doughnut",
								data: [
									{
										value: percent,
										color: "#4caf50",
										label: "Istwert",
									},
									{
										value: 100 - percent,
										color: "#EF5350",
										label: "fehlt",
									},
								],
							}}
						/>
					</Box>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Typography variant="caption">{Math.round(percent, 1)}%</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	const PersonRow = ({ person, rolle = "haushaltsverantwortlich" }) => {
		if (person === null || person === undefined) return null;
		const personCalculatedDisplaywerte = calculatedDisplaywerte.filter((wert) => wert.haushaltsmitglied?.person_uuid === person.uuid);
		const personScores = getScores(personCalculatedDisplaywerte);
		const cellSX = { borderBottom: "1px solid #E6E6E6", p: 0, m: 0 };

		let icon = person.geschlecht === "weiblich" ? "Femaleperson" : "Person";
		if (rolle === "kind") icon = "Child";
		return (
			<TableRow sx={{ margin: "0px" }}>
				<TableCell sx={{ ...cellSX, pl: "16px" }}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CustomIcon name={icon} size={32} />
						<Box sx={{ pl: "8px" }}>
							<b>{getDisplayName(person)}</b>
						</Box>
					</Box>
				</TableCell>
				<TableCell sx={cellSX}>{getPieChart(personScores.stufe1)}</TableCell>
				<TableCell sx={cellSX}>{getPieChart(personScores.stufe2)}</TableCell>
				<TableCell sx={cellSX}>{getPieChart(personScores.gesamt)}</TableCell>
			</TableRow>
		);
	};

	PersonRow.propTypes = {
		person: PropTypes.object,
		rolle: PropTypes.string,
	};

	return (
		<Box sx={{ width: "100%", padding: "24px" }}>
			{!hideTitle && (
				<Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", margin: "0px", pb: "32px" }}>
					<Typography variant="h6" sx={{ pb: "16px" }}>
						DIN 77230 Ergebnisse
					</Typography>
				</Box>
			)}
			<Box sx={{ display: "flex" }}>
				<Box sx={{ width: "40%", mr: "24px" }}>
					<Tile sx={{ height: "100%", mb: "16px", pb: "0px", textAlign: "center", backgroundColor: "#FFFCF5" }}>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h6">Familienscore</Typography>
							<Typography variant="body2">{getDatum(new Date())}</Typography>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
							<GaugeChart value={familienScores.gesamt} width={300} />
						</Box>
					</Tile>
				</Box>
				<Box sx={{ width: "30%", mr: "24px" }}>
					<Tile sx={{ height: "100%", mb: "16px", pb: "0px", textAlign: "center", backgroundColor: "#FFFCF5" }}>
						{getPieChart(familienScores.stufe1)}
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body">Sicherung des finanziellen Grundbedarfs</Typography>
						</Box>
					</Tile>
				</Box>
				<Box sx={{ width: "30%" }}>
					<Tile sx={{ height: "100%", mb: "16px", pb: "0px", textAlign: "center", backgroundColor: "#FFFCF5" }}>
						{getPieChart(familienScores.stufe2)}
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body">Erhaltung des Lebensstandards</Typography>
						</Box>
					</Tile>
				</Box>
			</Box>
			<Box sx={{ mt: "16px" }}>
				<Typography variant="h6">DIN-Scores aller Familienmitglieder</Typography>
				<TableContainer component={Paper} sx={{ borderRadius: "12px", overflow: "hidden", border: "1px solid #E6E6E6", boxShadow: "none" }}>
					<Table sx={{ width: "100%", boxShadow: "none" }}>
						<TableHead>
							<TableRow>
								<TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #E6E6E6" }}>Person</TableCell>
								<TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #E6E6E6" }}>Sicherung des finanziellen Grundbedarfs</TableCell>
								<TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #E6E6E6" }}>Erhaltung des Lebensstandards</TableCell>
								<TableCell sx={{ fontWeight: "bold", borderBottom: "1px solid #E6E6E6" }}>Gesamtscore</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<PersonRow person={haushalt.kunde} />
							<PersonRow person={haushalt.partner} />
							{haushalt.kinder.map((kind, index) => (
								<PersonRow key={index} person={kind} rolle="kind" />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
}
