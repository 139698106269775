export const defTemplateAV = [
	{
		control: "expandable",
		label_add: "gesetzliche Altersversorgung hinzufügen",
		name: "altersvorsorge",
		label: "Altersvorsorge",
		icon: "MoneySpending",
		cols: 12,
		info: {
			title: "Hinweise",
			description:
				"Die Angabe des Renten-/Versorgungsträger und des zu zahlenden Beitrages in Kombination mit dem Rentenbeginn dient der Analyse des zu erwartenden Rentenanspruches. Der Rententräger ergibt sich aus ihrem Tätigkeitsstatus.",
		},
		default: [
			{
				bezeichnung: "deutsche_rentenversicherung",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: [
					{ label: "Deutsche Rentenversicherung", value: "deutsche_rentenversicherung" },
					{ label: "Beamtenversorgung", value: "beamtenversorgung" },
					{ label: "Berufsständisches Versorgungswerk", value: "berufsstaendisches_versorgungswerk" },
					{ label: "andere Versorgungseinrichtung", value: "andere_versorgungseinrichtung" },
					{ label: "Zusatzversorgungskasse", value: "zusatzversorgungskasse" },
				],
			},
			{
				name: "wert",
				label: "Beitrag",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 1000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
		],
	},

	{
		control: "upload",
		name: "rentenbescheid",
		label: "Renteninformation",
		hide_label: true,
		multiple: true,
		dok_types: ["Renteninformation"],
	},
	{
		name: "ruhestandsbeginn",
		label: "Ruhestandsbeginn",
		control: "year_month_picker",
		cols: 12,
	},
	{
		name: "wunschrente",
		label: "Wunschrente",
		control: "sliderinput",
		unit: "€",
		default: 0,
		min: 0,
		max: 3443,
		step: 50,
		unit_type: "currency_no_digit",
		cols: 12,
		prefix: "über",
	},
];
