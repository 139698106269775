import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";

Tile.propTypes = {
  radius: PropTypes.number,
  margin: PropTypes.number,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default function Tile(props) {
  const { children, sx } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        ...{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#E6E6E6",
          borderRadius: (props.radius || 8) + "px",
          height: "100%",
          width: "100%",
          pl: "16px",
          pr: "16px",
          pt: "16px",
          pb: "16px",
          margin: "0px",
          overflow: "unset",
        },
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}
