import { Box, Button, Step, StepLabel, Stepper, Typography, Collapse, IconButton, useTheme } from "@mui/material";
import Tile from "components/Tile";
import Visualization from "pages/DIN/Visualization";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { getValue } from "../DMFhelperService";
import SwiperDialog from "./SwiperDialog";
import { useNavigate } from "react-router-dom";
import { getNewWorkflow } from "services/workflowService";
import { useRecoilState } from "recoil";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { Menu, MenuOpen } from "@mui/icons-material/";

MDDStepper.propTypes = {
	item: PropTypes.object,
	children: PropTypes.array,
	control: PropTypes.object,
	disabled: PropTypes.bool,
	getValues: PropTypes.func,
	saveCallBack: PropTypes.func,
};

function MDDStepper(props) {
	const { item, children, control, disabled, getValues, saveCallBack = null } = props;
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const appWindow = useRecoilValue(appWindowAtom);
	const [activeStep, setActiveStep] = useState(getValue(getValues(), item.name) || 0);
	const [activeItem, setActiveItem] = useState(null);
	const navigate = useNavigate();
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const [visible, setVisible] = useState(true);
	const theme = useTheme();
	const navWidth = 320;

	const handleOpenDialog = (item) => {
		setActiveItem(item);
		setIsDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	const getUsableWidth = () => {
		if (visible) return appWindow.contentWidth;
		return appWindow.contentWidth + navWidth;
	};

	const setActiveModule = (uuid) => {
		const newModule = workflow.workflow.modules.find((module) => module.uuid === uuid);
		setWorkflow({ ...getNewWorkflow(workflow, uuid), active_submodule: "92211BCF-BE56-4165-B33E-BE12C1A40816" });

		navigate("/" + newModule.module);
	};

	return (
		<Controller
			name={item.name}
			control={control}
			defaultValue={item.default || ""}
			render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
				const handleChange = (newValue) => {
					if (saveCallBack) {
						saveCallBack(getValues());
					}
					onChange(newValue);
					setActiveStep(newValue);
				};

				return (
					<Fragment>
						<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
							<Box>
								{!visible && (
									<Box
										sx={{
											margin: "0px",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "flex-start",
										}}
									>
										<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
											<Menu />
										</IconButton>
									</Box>
								)}

								<Collapse
									in={visible}
									orientation="horizontal"
									sx={{
										width: visible ? `${navWidth}px` : "0px",
										transition: theme.transitions.create("width", {
											duration: theme.transitions.duration.standard,
											easing: theme.transitions.easing.easeInOut,
										}),
									}}
								>
									<Tile sx={{ width: navWidth + "px", padding: "0px", margin: "0px", height: appWindow.contentHeight + "px", overflowY: "auto" }}>
										<Box
											sx={{
												margin: "16px",
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography variant="h5" sx={{ pt: "24px" }}>
												{item.label}
											</Typography>
											<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
												<MenuOpen />
											</IconButton>
										</Box>
										<Stepper activeStep={activeStep} orientation="vertical" sx={{ ml: "16px", mb: "0px" }}>
											{item.children.map((child, index) => (
												<Step
													key={child.name}
													sx={{
														marginLeft: "-4px",
													}}
												>
													{child.backgroundImage && index === activeStep ? (
														<Box
															sx={{
																position: "relative",
																p: "0px",
																m: "0px",
																width: `${navWidth - 24}px`,
																height: "152px",
																borderRadius: "8px",
																backgroundImage: `linear-gradient(to bottom, rgba(255,255,255, 0) 50%, rgba(255, 255, 255, 0.6) 100%),  url(${child.backgroundImage})`,
																backgroundSize: "cover",
																backgroundPosition: "center",
																boxShadow: "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
																":hover": {
																	cursor: disabled ? "default" : "pointer",
																},
															}}
															onClick={() => handleOpenDialog(child)}
														>
															<StepLabel
																sx={{
																	position: "absolute",
																	bottom: "0px", // Abstand zum unteren Rand der Box
																	left: "4px",
																	textAlign: "left",
																}}
																onClick={(e) => handleChange(index)}
															>
																{child.label}
															</StepLabel>
														</Box>
													) : (
														<StepLabel
															sx={{
																marginLeft: "4px",
																":hover": {
																	cursor: disabled ? "default" : "pointer",
																},
															}}
															onClick={() => handleChange(index)}
														>
															{child.label}
														</StepLabel>
													)}
												</Step>
											))}
										</Stepper>
									</Tile>
								</Collapse>
							</Box>
							<Tile sx={{ ml: "16px", mr: "0px", width: "700px", pt: "16px", pb: "16px", height: appWindow.contentHeight + "px" }}>
								<Box sx={{ height: `${appWindow.contentHeight - 75}px`, padding: "0px", margin: "0px", overflowY: "auto" }}>
									{children.map((child, index) => (
										<Box key={child.name} hidden={index !== activeStep}>
											{child.content}
										</Box>
									))}
								</Box>
								<Box sx={{ pt: "0px", pb: "24px", pl: "0px", pr: "0px", margin: "0px", display: "flex", justifyContent: "space-between" }}>
									<Button variant="contained" disabled={activeStep === 0} onClick={(e) => handleChange(activeStep - 1)}>
										zurück
									</Button>
									{activeStep === item.children.length - 1 ? (
										<Button variant="contained" onClick={(e) => setActiveModule("AFB2B8FC-D83F-4FDB-8B79-16037A8F1CE1")}>
											DIN 77230
										</Button>
									) : (
										<Button variant="contained" onClick={(e) => handleChange(activeStep + 1)}>
											weiter
										</Button>
									)}
								</Box>
							</Tile>
							<Box
								sx={{
									height: appWindow.contentHeight + "px",
									width: getUsableWidth() - 700 - navWidth + "px",
									position: "relative",
								}}
							>
								<Visualization show={{ background: true, boot: true, mann: true, eigentum: true, jagd_mann: true }} width={getUsableWidth() - 700 - navWidth} height={appWindow.contentHeight} />
								<Box
									sx={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
										backgroundColor: "rgba(244, 230, 197, 0.1)",
									}}
								/>
							</Box>
						</Box>
						<SwiperDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} activeItem={activeItem} />
					</Fragment>
				);
			}}
		/>
	);
}

export default MDDStepper;
