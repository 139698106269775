import { atom } from "recoil";
import { emptyCardsMap, shuffleCards, spielKarten } from "services/DINSpielService";

export const cardsDeckAtom = atom({
    key: "cardsDeckAtom",
    default: shuffleCards(spielKarten),
});

export const cardsMapAtom = atom({
    key: "cardsMapAtom",
    default: emptyCardsMap,
});