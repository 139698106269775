import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

MDDNumberInput.propTypes = {
  item: PropTypes.object,
  variant: PropTypes.string,
  control: PropTypes.object,
  size: PropTypes.string,
  trigger: PropTypes.func,
  disabled: PropTypes.bool,
};

function MDDNumberInput(props) {
  const { item, variant = "outlined", control, size = "small", trigger, disabled } = props;

  return (
    <Controller
      name={item.name}
      control={control}
      defaultValue={item.default || ""}
      render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
        let thousandsGroupStyle = null;
        let decimalSeparator = ",";
        let thousandSeparator = ".";

        switch (item.unit_type) {
          case "currency_no_digit":
            thousandsGroupStyle = "thousand";
            break;
          case "currency":
            thousandsGroupStyle = "thousand";
            break;
          case "percent":
            thousandSeparator = null;
            break;
          case "integer":
            break;
        }

        return (
          <NumericFormat
            allowNegative={item.allowNegative || false}
            suffix={" " + item.unit || ""}
            thousandsGroupStyle={thousandsGroupStyle}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            customInput={TextField}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            value={value}
            label={item.label}
            variant={variant}
            disabled={disabled}
            fullWidth
            size={size}
            {...(invalid && { error: true, helperText: error.message })}
            {...{
              InputProps: {
                sx: {
                  "& input": {
                    textAlign: "right",
                  },
                },
              },
            }}
            onBlur={() => trigger(item.name)}
          />
        );
      }}
    />
  );
}

export default MDDNumberInput;
