import PropTypes from "prop-types";
import { Suspense, useEffect, useState } from "react";

DynamicComponentLoader.propTypes = {
	config: PropTypes.object,
};

const components = import.meta.glob("./*.jsx");

const ErrorComponent = ({ componentName }) => <div>Fehler beim Laden der Komponente &apos;{componentName}&apos; </div>;

ErrorComponent.displayName = "ErrorComponent";

export default function DynamicComponentLoader({ config }) {
	const [Component, setComponent] = useState(null);

	useEffect(() => {
		if (!config) return;

		const componentPath = `./${config.component}.jsx`;
		const loadComponent = components[componentPath];

		if (loadComponent) {
			loadComponent()
				.then((mod) => setComponent(() => mod.default))
				.catch((err) => {
					console.error("Fehler beim Laden:", err);
					setComponent(() => () => <ErrorComponent componentName={config.component} />);
				});
		} else {
			setComponent(() => () => <ErrorComponent componentName={config.component} />);
		}
	}, [config]);

	if (!config) return null;

	return <Suspense fallback={<div>Lädt...</div>}>{Component ? <Component config={config} /> : <div>Komponente nicht gefunden</div>}</Suspense>;
}
