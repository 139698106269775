import { Box, Button, Typography } from "@mui/material";
import CardContainer from "./CardContainer";
import { spielKarten, spielKartenMap } from "services/DINSpielService";
import SpielKarte from "./SpielKarte";
import { DragDropContext } from "@hello-pangea/dnd";
import { useRecoilState, useResetRecoilState } from "recoil";
import { cardsDeckAtom, cardsMapAtom } from "recoil/atoms/spielGameAtom";
import { useState } from "react";

const firstRow = 8;
const secondRow = 7;
const thirdRow = 1;

export default function DINSpielGame() {
	const [checkAnswers, setCheckAnswers] = useState(false);
	const [cardDeck, setCardDeck] = useRecoilState(cardsDeckAtom);
	const [cardsMap, setCardsMap] = useRecoilState(cardsMapAtom);
	const resetCardsDeck = useResetRecoilState(cardsDeckAtom);
	const resetCardsMap = useResetRecoilState(cardsMapAtom);

	const onDragEnd = (result) => {
		const { source, destination } = result;

		// if card is dropped outside the container do nothing (taken card is returned to deck)
		if (!destination || destination.droppableId.includes("deck")) {
			return;
		}

		// if there is already a card inside the card container do nothing (taken card is returned to deck)
		if (cardsMap[destination.droppableId]) {
			return;
		}

		// get the card that user is holding and set it to map in state
		const cardTakenFromDeck = spielKarten.find((x) => x.index === source.index);
		setCardsMap({ ...cardsMap, [destination.droppableId]: cardTakenFromDeck });

		// remove placed card from the deck
		const newCardDeck = cardDeck.filter((card) => card.index !== cardTakenFromDeck.index);
		setCardDeck(newCardDeck);
	};

	const resetGame = () => {
		resetCardsDeck();
		resetCardsMap();
		setCheckAnswers(false);
	};

	const showGameSolution = () => {
		setCardsMap(spielKartenMap);
	};

	const onCheckAnswers = () => {
		setCheckAnswers(true);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<Typography variant="h4">DIN 77230 Kartenspiel</Typography>
				<Box sx={{ display: "flex", gap: "12px" }}>
					<Button variant="outlined" onClick={resetGame}>
						Spiel neu starten
					</Button>
					<Button variant="outlined" onClick={showGameSolution}>
						Lösungen anzeigen
					</Button>
					<Button variant="contained" onClick={onCheckAnswers}>
						Antworten prüfen
					</Button>
				</Box>
			</Box>
			<DragDropContext onDragEnd={onDragEnd}>
				<Box>
					<Typography variant="h5" sx={{ pt: "10px" }}>
						Stufe 1: Sicherung des finanziellen Grundbedarfs
					</Typography>
					<Box sx={{ display: "flex", gap: "12px", pt: "10px", flexWrap: "wrap" }}>
						{[...Array(firstRow)].map((e, i) => (
							<CardContainer key={`firstRow_card_${i}`} stufe={spielKarten.find((karte) => karte.index === i + 1).stufe} cardSolution={`first_row_${i + 1}`} cardsMap={cardsMap} checkAnswers={checkAnswers} />
						))}
					</Box>
					<Typography variant="h5" sx={{ pt: "10px" }}>
						Stufe 2: Erhaltung des Lebensstandards
					</Typography>
					<Box sx={{ display: "flex", gap: "12px", pt: "10px", flexWrap: "wrap" }}>
						{[...Array(secondRow)].map((e, i) => (
							<CardContainer key={`secondRow_card_${i}`} stufe={spielKarten.find((karte) => karte.index === i + 9).stufe} cardSolution={`second_row_${i + 1}`} cardsMap={cardsMap} checkAnswers={checkAnswers} />
						))}
					</Box>
					<Typography variant="h5" sx={{ pt: "10px" }}>
						Stufe 3: Verbesserung des Lebensstandards
					</Typography>
					<Box sx={{ display: "flex", gap: "12px", pt: "10px", flexWrap: "wrap" }}>
						{[...Array(thirdRow)].map((e, i) => (
							<CardContainer key={`thirdRow_card_${i}`} stufe={spielKarten.find((karte) => karte.index === i + 16).stufe} cardSolution={`third_row_${i + 1}`} cardsMap={cardsMap} checkAnswers={checkAnswers} />
						))}
					</Box>
				</Box>
				<Box sx={{ display: "flex", marginLeft: "auto", marginTop: "auto", position: "relative" }}>
					{cardDeck.map((spielKarte, i) => (
						<SpielKarte key={`card_index_${i}`} spielKarte={spielKarte} spielKarteIndex={i + 1} />
					))}
				</Box>
			</DragDropContext>
		</Box>
	);
}
