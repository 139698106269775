export const spielKarten = [
	{ index: 1, stufe: "1", solution: "first_row_1", imageName: "Altersvorsorge_beide_Stufen", headerText: "ALTERS - VORSORGE", bodyText: "80 % v. Nettoerwerbs-einkommen zzgl. Steuern & Sozialabgaben + Inflation" },
	{ index: 2, stufe: "2", solution: "first_row_2", imageName: "KV_Zahn", headerText: "KRANKEN - VERSICHERUNG", bodyText: "Zusatzleistungen (Zahn)" },
	{ index: 3, stufe: "4", solution: "first_row_3", imageName: "Zielspare_Invest", headerText: "ZIELSPAREN & INVESTITIONEN", bodyText: "Wohneigentum, individuelle Ziele" },
	{ index: 4, stufe: "6", solution: "first_row_4", imageName: "KV_Grundschutz", headerText: "KRANKEN - VERSICHERUNG", bodyText: "Grundschutz" },
	{ index: 5, stufe: "7", solution: "first_row_5", imageName: "LIquiditatsreserve_beide_Stufen", headerText: "LIQUIDITÄTS - RESERVE 2", bodyText: "6 x mtl. Nettogesamt-einkommen" },
	{ index: 6, stufe: "18", solution: "first_row_6", imageName: "Privathaftpflicht", headerText: "PRIVAT - HAFTPFLICHT", bodyText: "10.000.000 €" },
	{ index: 7, stufe: "20", solution: "first_row_7", imageName: "BU_beide_Karten", headerText: "BERUFS - UNFÄHIGKEIT", bodyText: "80 % v. Nettoerwerbs-einkommen" },
	{ index: 8, stufe: "22", solution: "first_row_8", imageName: "Rechtsschutz", headerText: "RECHTSSCHUTZ - VERSICHERUNG", bodyText: "Privat, Verkehr, Beruf, Wohnung/Grundstück" },
	{ index: 9, stufe: "24", solution: "second_row_1", imageName: "LIquiditatsreserve_beide_Stufen", headerText: "LIQUIDITÄTS - RESERVE 1", bodyText: "3 x Mindest-bedarfsgröße" },
	{ index: 10, stufe: "27", solution: "second_row_2", imageName: "HInterbliebenenschutz", headerText: "HINTER - BLIEBENENSCHUTZ", bodyText: "5 x Jahres-Mindestbedarfsgröße + 3 x Jahres-Mindestbedarfsgröße + alle offenen Darlehen" },
	{ index: 11, stufe: "29", solution: "second_row_3", imageName: "Pflegebedurftigkeit_Grundschutz", headerText: "PFLEGE - BEDÜRFTIGKEIT", bodyText: "Grundschutz" },
	{ index: 12, stufe: "32", solution: "second_row_4", imageName: "BU_beide_Karten", headerText: "BERUFS - UNFÄHIGKEIT", bodyText: "Mindestbedarfsgröße" },
	{ index: 13, stufe: "35", solution: "second_row_5", imageName: "Unfallversicherung", headerText: "UNFALL - VERSICHERUNG", bodyText: "6 x mtl. Nettogesamt-einkommen (Minimum 20.000 €)" },
	{ index: 14, stufe: "36", solution: "second_row_6", imageName: "Altersvorsorge_beide_Stufen", headerText: "ALTERS - VORSORGE", bodyText: "Mindest-bedarfsgröße + Inflation" },
	{ index: 15, stufe: "39", solution: "second_row_7", imageName: "Hausrat", headerText: "HAUSRAT", bodyText: "650 €/qm Wohnfläche" },
	{ index: 16, stufe: "41 & 42", solution: "third_row_1", imageName: "KV_Ausland", headerText: "KRANKEN - VERSICHERUNG", bodyText: "Ausland" },
];

export const spielKartenMap = spielKarten.reduce((map, obj) => {
	map[obj.solution] = obj;
	return map;
}, {});

export const emptyCardsMap = spielKarten.reduce((map, obj) => {
	map[obj.solution] = undefined;
	return map;
}, {});

export const shuffleCards = (cards) => {
	for (let i = cards.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[cards[i], cards[j]] = [cards[j], cards[i]];
	}

	return cards;
};
