import dayjs from "dayjs";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.locale("de");
dayjs.extend(utc);
dayjs.extend(timezone);
const not_available = "- - -";

export const getDisplayName = (person) => {
	if (person === null || person === undefined) return "";
	if (person.nachname === null) {
		if (person.vorname === null) return "./.";
		return person.vorname;
	}
	return person.vorname + " " + person.nachname;
};

export const getEuro = (wert, nachkommastellen = 2) => {
	if (!wert && typeof wert !== "number") return not_available;
	wert = Number(wert);
	return wert.toLocaleString("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: nachkommastellen, maximumFractionDigits: nachkommastellen });
};

export const getProzent = (wert, nachkommastellen = 2) => {
	if (!wert && typeof wert !== "number") return not_available;
	wert = Number(wert);
	return wert.toLocaleString("de-DE", { style: "percent", minimumFractionDigits: nachkommastellen, maximumFractionDigits: nachkommastellen });
};

export const getJaNein = (wert) => {
	if (wert === null || wert === undefined) return "keine Angabe";
	return wert ? "Ja" : "Nein";
};

export const getDatum = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("DD.MM.YYYY");
	return result;
};

export const getJahr = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("YYYY");
	return result;
};

export const getMonatJahr = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("MM.YYYY");
	return result;
};

export const getDatumSortable = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("yyyyMMdd");
	return result;
};

export const getDatumZeit = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("DD.MM.YYYY - HH:mm");
	return result;
};

export const getAlter = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return 0;
	return dayjs().tz("Europe/Berlin").diff(dayjs.utc(timestamp).tz("Europe/Berlin"), "year");
};

export const getAlterMonate = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return 0;
	return dayjs().tz("Europe/Berlin").diff(dayjs.utc(timestamp).tz("Europe/Berlin"), "month");
};

export const getErsterTagImMonat = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return not_available;
	return dayjs.utc(timestamp).tz("Europe/Berlin").startOf("month");
};

export const getBezeichnung = (bezeichnung) => {
	switch (bezeichnung) {
		case "ziel_auto":
			return "Erwerb Kfz";
		case "ziel_appartement":
			return "Kapitalanlage Immobilie";
		case "ziel_ruhestand":
			return "früherer Ruhestand";
		case "ziel_liquiditaet":
			return "Aufbau weitere Liquidität";
		case "ziel_weltreise":
			return "Weltreise";
		case "ziel_auszeit":
			return "Berufliche Auszeit";
		case "ziel_umzug":
			return "Umzug";
		case "ziel_fortbildung":
			return "Fortbildung";
		case "ziel_fernseher":
			return "Erwerb von Konsumgütern";
		case "ziel_neues_hobby":
			return "neues Hobby";
		case "pauschale_einnahmen":
			return "pauschales Haushaltseinkommen";
		case "selbstaendige_arbeit":
			return "Nettoeinkommen aus selbständiger Arbeit";
		case "nicht_selbstaendige_arbeit":
			return "Nettoeinkommen aus nicht selbständiger Arbeit";
		case "vermoegen":
			return "Einnahmen aus Vermögen";
		case "oeffentliche_transferzahlungen":
			return "Einkommen aus öffentlichen Transferzahlungen";
		case "nicht_oeffentliche_transferzahlungen":
			return "nicht öffentliche Transferzahlungen und Untervermietung";
		case "sonstige_einnahmen":
			return "sonstige Einnahmen";
		case "pauschale_ausgaben":
			return "pauschale Lebenshaltungskosten";
		case "nahrung":
			return "Nahrungsmittel, Getränke, Tabakwaren";
		case "bekleidung":
			return "Bekleidung und Schuhe";
		case "wohnen":
			return "Wohnen, Energie, Wohnungsinstandhaltung";
		case "innenausstattung":
			return "Innenausstattung, Haushaltsgeräte und -gegenstände";
		case "gesundheit":
			return "Gesundheit";
		case "verkehr":
			return "Verkehr";
		case "information":
			return "Information und Kommunikation";
		case "freizeit":
			return "Freizeit, Sport und Kultur";
		case "bildung":
			return "Bildungsdienstleistungen";
		case "gastronomie":
			return "Gastronomie- und Beherbungsdienstleistungen";
		case "versicherungen":
			return "Versicherungs- und Finanzdienstleistungen";
		case "andere":
			return "andere Waren und Dienstleistungen";
		case "sonstige":
			return "Sonstiges";
		case "unterhalt":
			return "Unterhalt";
		case "girokonto":
			return "Girokonto";
		case "geldschulden":
			return "Geldschulden gegenüber dem Haushalt";
		case "spareinlagen":
			return "Spareinlagen";
		case "wertpapiere":
			return "festverzinsliche Wertpapiere";
		case "bausparvertraege":
			return "Bausparverträge";
		case "lebensversicherungen":
			return "Lebensversicherungen";
		case "aktien":
			return "Börsennotierte Aktien";
		case "investmentfonds":
			return "Investmentfonds";
		case "unternehmenseigentum":
			return "Unternehmenseigentum";
		case "wertgegenstaende":
			return "Wertgegenstände";
		case "fahrzeuge":
			return "Fahrzeuge";
		case "hauptwohnsitz":
			return "Hauptwohnsitz";
		case "weitere_immobilien":
			return "weitere Immobilien";
		case "sonstige":
			return "Sonstige";
		case "wohnsitze":
			return "Wohnsitze";
		case "grundstuecke":
			return "Grundstücke";
		case "darlehen_hauptwohnsitz":
			return "Darlehen Hauptwohnsitz";
		case "darlehen_weitere_immobilien":
			return "Darlehen weitere Immobilien";
		case "darlehen_fahrzeuge":
			return "Darlehen Fahrzeuge";
		case "sonstige_kredite":
			return "Sonstige Kredite";
		case "konsumentenkredit":
			return "Konsumentenkredit";
		case "kreditkarten":
			return "Kreditkarten Schulden";
		case "ueberziehungkredit":
			return "Überziehungskredite";
		case "gkv":
			return "gesetzliche Krankenversicherung";
		case "pkv":
			return "private Krankenversicherung";
		case "heilfuersorge":
			return "Heilfürsorge";
		default:
			return "keine Angabe";
	}
};
