import { NavigateBeforeOutlined, NavigateNextOutlined } from "@mui/icons-material/";
import { Box, IconButton, Slider, Typography, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { Document, Page, Thumbnail, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { backendGet } from "services/dataService";
import Sketchpad from "./Sketchpad/Sketchpad";
import Spinner from "./Spinner";
import { presentationAtom } from "recoil/atoms/presentationAtom";
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

PDFViewer.propTypes = {
	config: PropTypes.object,
};

export default function PDFViewer(props) {
	const { config } = props;
	const theme = useTheme();
	const [presentation, setPresentation] = useRecoilState(presentationAtom);
	const [pageNumber, setPageNumber] = useState(0);
	const appWindow = useRecoilValue(appWindowAtom);
	const wCorrection = config.widthCorrection;
	const hCorrection = config.heightCorrection;
	const [scaleValue, setScaleValue] = useState(1.0);
	const [isLoading, setIsLoading] = useState(true);

	const getActivePageNumber = () => {
		return presentation.meta.find((item) => item.index === pageNumber).pagenumber;
	};

	const handleChange = (event, newValue) => {
		setScaleValue(newValue);
	};

	const [useable, setUseable] = useState({ width: 0, height: 0 });

	const calcUseable = (appWindow, width, height, wCorrection, hCorrection) => {
		const windowWidth = appWindow.contentWidth - wCorrection - 64;
		let windowHeight = appWindow.contentHeight - hCorrection;
		if (config.controls?.showThumbnails) windowHeight = windowHeight - config.controls?.thumbnailHeight;

		const contentAspectRatio = width / height;
		const windowAspectRatio = windowWidth / windowHeight;
		let result = {};
		if (contentAspectRatio > windowAspectRatio) {
			const scaleFactor = windowWidth / width;
			const newWidth = windowWidth;
			const newHeight = height * scaleFactor;
			result = { width: newWidth, height: newHeight };
		} else {
			const scaleFactor = windowHeight / height;
			const newWidth = width * scaleFactor;
			const newHeight = windowHeight;
			result = { width: newWidth, height: newHeight };
		}
		return result;
	};

	const calcWidth = () => {
		const windowWidth = appWindow.contentWidth - wCorrection;
		let width = useable.width * scaleValue;
		if (width > windowWidth) width = windowWidth;
		return width;
	};

	useEffect(() => {
		const scrollToCurrentPage = () => {
			const currentPageElement = document.querySelector("#page-" + presentation.meta[pageNumber].pagenumber);

			if (currentPageElement) {
				currentPageElement.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		};

		if (presentation) scrollToCurrentPage();
	}, [pageNumber]);

	useEffect(() => {
		if (config.controls?.callback && presentation)
			config.controls?.callback(
				<Fragment>
					<Box sx={{ display: "flex", mt: "16px", justifyContent: "center", alignItems: "center", overflowY: "auto" }}>
						<IconButton onClick={() => navPage(-1)} disabled={pageNumber === 0} sx={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#E6E6E6" }}>
							<NavigateBeforeOutlined fontSize="small" />
						</IconButton>
						<Box sx={{ ml: "15px" }}>
							<Typography variant="h4">{getActivePageNumber() + "/" + presentation.meta.filter((item) => !item.animation).length}</Typography>
						</Box>
						<IconButton onClick={() => navPage(1)} disabled={pageNumber === presentation.meta.length - 1} sx={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#E6E6E6", ml: "15px" }}>
							<NavigateNextOutlined fontSize="small" />
						</IconButton>
					</Box>
					<Box
						sx={{
							height: appWindow.contentHeight - 200 + "px",
							overflowY: "auto",
							mt: "16px",
							borderStyle: "solid",
							borderWidth: "1px",
							borderColor: "#E6E6E6",
						}}
					>
						<Document file={presentation.pdf}>
							{presentation.meta.map(
								(page, index) =>
									!page.animation && (
										<Box
											key={index}
											id={"page-" + page.pagenumber}
											sx={{
												display: "flex",
												flexDirection: "row",
												pb: "4px",
												pt: "4px",
												pr: "4px",
												justifyContent: "flex-end",
												backgroundColor: page.pagenumber === getActivePageNumber() ? theme.palette.grey[200] : undefined,
												":hover": {
													cursor: "pointer",
													backgroundColor: theme.palette.grey[200],
												},
											}}
										>
											<Box
												sx={{
													marginRight: "4px",
												}}
											>
												<Typography variant="subtitle1">{page.pagenumber}</Typography>
											</Box>
											<Box sx={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#E6E6E6", borderRadius: "4px", padding: "4px" }} onClick={() => gotoPage(index)}>
												<Thumbnail width={config.controls?.thumbnailWidth} height={config.controls?.thumbnailHeight} pageNumber={index + 1} />
											</Box>
										</Box>
									)
							)}
						</Document>
					</Box>
				</Fragment>
			);
	}, [config.controls?.callback, presentation, pageNumber]);

	useEffect(() => {
		if (presentation) setUseable(calcUseable(appWindow, presentation.meta[pageNumber].width, presentation.meta[pageNumber].height, wCorrection, hCorrection));
	}, [appWindow]);

	const setLoadResult = (data) => {
		setPresentation(data);
		setUseable(calcUseable(appWindow, data.meta[0].width, data.meta[0].height, wCorrection, hCorrection));
	};

	useEffect(() => {
		backendGet({ label: "PDF", setIsLoading: setIsLoading, route: "pdf/complete/" + config.file, setResult: setLoadResult, enqueueSnackbar: enqueueSnackbar });
	}, [config]);

	const navPage = (modifier) => {
		gotoPage(pageNumber + modifier);
	};

	const gotoPage = (newPageNumber) => {
		setPageNumber(newPageNumber);
		setUseable(calcUseable(appWindow, presentation.meta[newPageNumber].width, presentation.meta[newPageNumber].height, wCorrection, hCorrection));
	};

	if (!config) return;
	return (
		<Fragment>
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				{!presentation ? (
					<Spinner />
				) : (
					<Fragment>
						<Box
							sx={{
								position: "relative",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: calcWidth() + "px",
								maxWidth: calcWidth() + "px",
								height: "100%",
							}}
						>
							{config.controls?.showPrevNext && (
								<IconButton
									onClick={() => navPage(-1)}
									disabled={pageNumber === 0}
									sx={{
										borderRadius: 0,
										height: "64px",
										width: "64px",
										"&:hover": {
											backgroundColor: "transparent", // setzt die Hintergrundfarbe auf transparent im Hover-Zustand
										},
									}}
								>
									<NavigateBeforeOutlined
										sx={{
											fontSize: "96px",
											"&:hover": {
												color: theme.palette.primary.main, // setzt die Farbe des Icons auf Blau im Hover-Zustand
											},
										}}
									/>
								</IconButton>
							)}
							<Document file={presentation.pdf} error="PDF konnte nicht geladen werden" loading="PDF wird geladen...">
								<Box
									sx={{
										display: "flex",
										width: calcWidth() + "px",
										maxWidth: calcWidth() + "px",
										justifyContent: "center",
										alignItems: "center",
										height: useable.height + "px",
										/* borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#E6E6E6",
                    borderRadius: "0px",*/
										overflow: "auto",
									}}
								>
									<Page width={useable.width * scaleValue} height={useable.height} pageNumber={pageNumber + 1} />
								</Box>
							</Document>
							{config.controls?.showPrevNext && (
								<IconButton
									onClick={() => navPage(1)}
									disabled={pageNumber === presentation.meta.length - 1}
									sx={{
										borderRadius: 0,
										height: "64px",
										width: "64px",
										"&:hover": {
											backgroundColor: "transparent", // setzt die Hintergrundfarbe auf transparent im Hover-Zustand
										},
									}}
								>
									<NavigateNextOutlined
										sx={{
											fontSize: "96px",
											"&:hover": {
												color: theme.palette.primary.main, // setzt die Farbe des Icons auf Blau im Hover-Zustand
											},
										}}
									/>
								</IconButton>
							)}
							{(config.controls?.showSketchpad || 1 === 1) && <Sketchpad width={useable.width} height={useable.height} activePage={presentation.meta[pageNumber]} pzoom={scaleValue} />}
						</Box>
						<Box sx={{ position: "relative", mt: "8px", left: "0px", bottom: "0px", display: "flex", justifyContent: "space-between", zIndex: 999, width: "100%" }}>
							<Box sx={{ flex: 1 }}></Box>
							<Box sx={{ flex: 2, display: "flex", justifyContent: "center", width: calcWidth() + "px" }}>
								{config.controls?.showThumbnails && config.controls?.position === "bottom" && (
									<Document file={presentation.pdf} error="PDF konnte nicht geladen werden" loading="PDF wird geladen...">
										<Box sx={{ display: "flex", flexDirection: "row", overflowX: "auto", width: calcWidth() + "px" }}>
											{presentation.meta.map(
												(page, index) =>
													!page.animation && (
														<Box
															key={index}
															id={"page-" + page.pagenumber}
															sx={{
																display: "flex",
																flexDirection: "row",
																padding: "4px",
																justifyContent: "flex-end",
																backgroundColor: page.pagenumber === getActivePageNumber() ? theme.palette.grey[200] : undefined,
																":hover": {
																	cursor: "pointer",
																	backgroundColor: theme.palette.grey[200],
																},
															}}
														>
															<Box sx={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#E6E6E6", borderRadius: "4px", padding: "4px" }} onClick={() => gotoPage(index)}>
																<Thumbnail height={config.controls?.thumbnailHeight} pageNumber={index + 1} />
															</Box>
														</Box>
													)
											)}
										</Box>
									</Document>
								)}
								{config.controls?.position === "bottom" && config.controls?.showPagination && (
									<Fragment>
										<IconButton onClick={() => navPage(-1)} disabled={pageNumber === 0} sx={{ height: "32px", width: "32px", borderStyle: "solid", borderWidth: "2px", borderColor: "#E6E6E6" }}>
											<NavigateBeforeOutlined fontSize="small" />
										</IconButton>
										<Box sx={{ ml: "15px" }}>
											<Typography variant="h6">{pageNumber + 1 + "/" + presentation.meta.length}</Typography>
										</Box>
										<IconButton onClick={() => navPage(1)} disabled={pageNumber === presentation.meta.length - 1} sx={{ height: "32px", width: "32px", borderStyle: "solid", borderWidth: "2px", borderColor: "#E6E6E6", ml: "15px" }}>
											<NavigateNextOutlined fontSize="small" />
										</IconButton>
									</Fragment>
								)}
							</Box>
							<Box sx={{ flex: 1, display: "flex", pl: "16px", justifyContent: "space-between", alignItems: "end" }}>
								<Box sx={{ display: "flex", flex: "1", flexDirection: "row", alignItems: "center" }}>
									<Typography id="slider-label">Zoom: {Math.round(scaleValue * 100) + "%"}</Typography>
								</Box>
								<Box sx={{ display: "flex", flex: "2", flexDirection: "row", alignItems: "left" }}>
									<Slider value={scaleValue} min={0.5} max={2.0} step={0.01} onChange={handleChange} aria-labelledby="slider-label" />
								</Box>
							</Box>
						</Box>
					</Fragment>
				)}
			</Box>
		</Fragment>
	);
}
