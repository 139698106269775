import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

DMF_Display.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	getValues: PropTypes.func,
	services: PropTypes.object,
	setValue: PropTypes.func,
};

function DMF_Display(props) {
	const { item, control, getValues, services, setValue } = props;
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [displayValue, setDisplayValue] = useState("");

	useEffect(() => {
		const fetchOptions = async () => {
			if (item.service) {
				const value = await services[item.service](getValues(), getValues(item.watch[0]));
				setDisplayValue(value);
				setValue(item.name, value);
			} else {
				setValue(item.name, item.default);
				setDisplayValue(item.default);
			}
		};

		fetchOptions();
	}, [item.service, watched]);

	return (
		<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
			<Typography variant="body" sx={{ pb: item.description ? "0px" : "16px" }}>
				{item.label}: {displayValue}
			</Typography>
		</Box>
	);
}

export default DMF_Display;
