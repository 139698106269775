import { Box, Typography } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import BackendLayout from "layouts/BackendLayout";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import DINSpielGame from "./components/DINSpielGame";

export default function DINSpiel() {
	const appWindow = useRecoilValue(appWindowAtom);
	const navigate = useNavigate();

    return (
        <BackendLayout>
            <Box sx={{ display: "flex", width: appWindow.contentWidth }}>
                <Box sx={{ padding: "0px", mr: "48px", width: "160px", display: "flex", flexDirection: "column", justifyContent: "flex-start", height: appWindow.contentHeight, overflowY: "auto" }}>
                    <Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px", cursor: "pointer" }} onClick={() => navigate("/home")}>
                        <CustomIcon name="Clientprofile" size={48} />
                        <Typography variant="body2">Kunden</Typography>
                    </Box>
                    <Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px" }}>
                        <CustomIcon name="Presentation" size={48} />
                        <Typography variant="body2">Beratungsmappe</Typography>
                    </Box>
                    <Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px" }}>
                        <CustomIcon name="Calculator" size={48} />
                        <Typography variant="body2">Rechner</Typography>
                    </Box>
                    <Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px" }}>
                        <CustomIcon name="Comments" size={48} />
                        <Typography variant="body2">News</Typography>
                    </Box>
                    <Box
                        sx={{
                            mb: "4px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "8px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "#E6E6E6",
                            borderRadius: "8px",
                            backgroundColor: "rgba(62, 79, 147, 0.08)",
                        }}
                    >
                        <CustomIcon name="CardGame" size={48} />
                        <Typography variant="body2">DIN-Spiel</Typography>
                    </Box>
                </Box>

                <Box style={{ width: "100%" }}>
                    <DINSpielGame />
                </Box>
            </Box>
        </BackendLayout>
    )
}
