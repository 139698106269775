import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	card: {
		display: "flex",
		flexDirection: "row",
		marginRight: 40,
	},
	cardContent: {
		display: "flex",
		flexDirection: "column",
		marginLeft: 4,
	},
	headerText: {
		fontSize: 16,
		color: "#2C4282",
		marginBottom: 6,
	},
	bodyText: {
		fontSize: 14,
		color: "rgba(0, 0, 0, 0.87)",
	},
});

export const CardItem = ({ icon, header, text }) => (
	<View style={styles.card}>
		<Image src={import.meta.env.VITE_BACKEND + `/${icon}.png`} style={{ width: 36, height: 36 }} />
		<View style={styles.cardContent}>
			<Text style={styles.headerText}>{header}</Text>
			<Text style={styles.bodyText} wrap>
				{text}
			</Text>
		</View>
	</View>
);

CardItem.propTypes = {
	icon: PropTypes.string,
	header: PropTypes.string,
	text: PropTypes.string,
};
