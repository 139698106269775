import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getDatum, getEuro, getDisplayName } from "services/formatService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 9,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderTop: "1px solid gray",
		paddingTop: 4,
		paddingBottom: 4,
	},
	alignTop: {
		alignItems: "top",
	},
	header: {
		borderTop: "none",
		paddingTop: 10,
		paddingBottom: 10,
	},
	bold: {
		fontWeight: "bold",
	},
	col0: {
		width: "4%",
		paddingLeft: 4,
		paddingRight: 4,
		alignContent: "center",
	},
	col1: {
		width: "17%",
		paddingLeft: 4,
		paddingRight: 4,
		display: "flex",
		flexDirection: "column",
	},
	col2: {
		paddingLeft: 4,
		paddingRight: 4,
		display: "flex",
		flexDirection: "column",
		width: "20%",
	},
	col3: {
		paddingLeft: 4,
		paddingRight: 4,
		width: "11%",
		display: "flex",
		flexDirection: "column",
	},
	col4: {
		paddingLeft: 4,
		paddingRight: 4,
		width: "10%",
		display: "flex",
		flexDirection: "column",
	},
	col5: {
		paddingLeft: 4,
		paddingRight: 4,
		width: "12%",
	},
	col6: {
		paddingLeft: 4,
		paddingRight: 4,
		width: "12%",
	},
	col7: {
		paddingLeft: 4,
		paddingRight: 4,
		width: "11%",
		display: "flex",
		flexDirection: "column",
	},
});

export const BigTable = ({ data }) => (
	<View style={styles.table}>
		<View style={[styles.row, styles.bold, styles.header]}>
			<Text style={styles.col0}> </Text>
			<View style={styles.col1}>
				<Text>Gesellschaft</Text>
				<Text>Versicherungsnummer</Text>
			</View>
			<View style={styles.col2}>
				<Text>Sparte</Text>
				<Text>Produkt</Text>
			</View>
			<View style={styles.col3}>
				<Text>Vertragsbeginn</Text>
				<Text>Vertragsende</Text>
			</View>
			<View style={styles.col4}>
				<Text>Beitrag</Text>
				<Text>Zahlweise</Text>
			</View>
			<Text style={styles.col5}>Versicherungsnehmer</Text>
			<Text style={styles.col6}>Versicherte Person</Text>
			<Text style={styles.col7}>Leistungen inkl. Wert</Text>
		</View>
		{data?.map((row, i) => (
			<View key={i} style={[styles.row, styles.alignTop]} wrap={false}>
				<View style={styles.col0}>
					<Image src={"/pg_logos/" + row.produktgeber.logo} style={{ width: "24px" }} />
				</View>
				<View style={styles.col1}>
					<Text>{row.produktgeber.name}</Text>
					<Text>{row.vertragsnummer}</Text>
				</View>
				<View style={styles.col2}>
					<Text>{row.produkt.geschaeft}</Text>
					<Text>{row.produkt.produkt}</Text>
				</View>
				<View style={styles.col3}>
					<Text>{getDatum(row.beginn)}</Text>
					<Text>{getDatum(row.ablauf)}</Text>
				</View>
				<View style={styles.col4}>
					<Text>{getEuro(row.beitrag_gem_zahlweise)}</Text>
					<Text>{row.zahlweise}</Text>
				</View>
				<Text style={styles.col5}>{getDisplayName(row.versicherungsnehmer)}</Text>
				{/* iteriere versicher_personen und wandle sie mit getdisplayname um und trenne sie durch komma */}
				<View style={styles.col6}>
					{row.versicherte_personen.map((person, i) => {
						return <Text key={i}>{getDisplayName(person)}</Text>;
					})}
				</View>
				<View style={styles.col7}>
					{row.deckungen.map((deckung, index) => (
						<View key={index}>
							<Text style={{ fontWeight: "bold" }}>{deckung.deckung}:</Text>
							<Text>
								{getEuro(deckung.wert, 0)}
								{index < row.deckungen.length - 1 ? ", " : ""}
							</Text>
						</View>
					))}
				</View>
			</View>
		))}
	</View>
);

BigTable.propTypes = {
	data: PropTypes.array,
};
