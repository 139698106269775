import PropTypes from "prop-types";

MarkerTool.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string
};

export default function MarkerTool(props) {
    const { width = 80, height = 48, fill = "#3E4F93" } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_489_16637)">
                <g filter="url(#filter0_d_489_16637)">
                    <path d="M14.9505 29.2778V17.7593L5.90375 18.2279C5.1124 18.2688 4.41499 18.786 4.11427 19.5549L1.07738 27.319C0.807454 28.0091 1.27564 28.7701 1.98637 28.7965L14.9505 29.2778Z" fill={fill} />
                    <path d="M14.9505 29.2778V17.7593L5.90375 18.2279C5.1124 18.2688 4.41499 18.786 4.11427 19.5549L1.07738 27.319C0.807454 28.0091 1.27564 28.7701 1.98637 28.7965L14.9505 29.2778Z" fill="url(#paint0_linear_489_16637)" />
                    <path d="M14.9505 29.2778V17.7593L5.90375 18.2279C5.1124 18.2688 4.41499 18.786 4.11427 19.5549L1.07738 27.319C0.807454 28.0091 1.27564 28.7701 1.98637 28.7965L14.9505 29.2778Z" fill="url(#paint1_linear_489_16637)" />
                    <path d="M80 35.0372V12.0001L39.794 12.0001C38.1362 12.0001 36.503 12.4145 35.0343 13.2079L30.7351 15.5302C29.6324 16.1259 28.4129 16.455 27.1687 16.4928L15.4374 16.9423C15.0213 16.955 14.6903 17.3067 14.6903 17.7364L14.6903 29.301C14.6903 29.7306 15.0213 30.0824 15.4374 30.095L27.1687 30.5445C28.4129 30.5823 29.6324 30.9115 30.7351 31.5071L35.0343 33.8294C36.503 34.6228 38.1362 35.0372 39.794 35.0372L80 35.0372Z" fill="white" />
                    <path d="M80 35.0372V12.0001L39.794 12.0001C38.1362 12.0001 36.503 12.4145 35.0343 13.2079L30.7351 15.5302C29.6324 16.1259 28.4129 16.455 27.1687 16.4928L15.4374 16.9423C15.0213 16.955 14.6903 17.3067 14.6903 17.7364L14.6903 29.301C14.6903 29.7306 15.0213 30.0824 15.4374 30.095L27.1687 30.5445C28.4129 30.5823 29.6324 30.9115 30.7351 31.5071L35.0343 33.8294C36.503 34.6228 38.1362 35.0372 39.794 35.0372L80 35.0372Z" fill="url(#paint2_linear_489_16637)" />
                    <path d="M80 35.0372V12.0001L39.794 12.0001C38.1362 12.0001 36.503 12.4145 35.0343 13.2079L30.7351 15.5302C29.6324 16.1259 28.4129 16.455 27.1687 16.4928L15.4374 16.9423C15.0213 16.955 14.6903 17.3067 14.6903 17.7364L14.6903 29.301C14.6903 29.7306 15.0213 30.0824 15.4374 30.095L27.1687 30.5445C28.4129 30.5823 29.6324 30.9115 30.7351 31.5071L35.0343 33.8294C36.503 34.6228 38.1362 35.0372 39.794 35.0372L80 35.0372Z" fill="url(#paint3_radial_489_16637)" />
                    <path d="M52.3701 35.0371L52.3701 12L48.2745 12L48.2745 35.0371H52.3701Z" fill={fill} />
                    <path d="M52.3701 35.0371L52.3701 12L48.2745 12L48.2745 35.0371H52.3701Z" fill="url(#paint4_linear_489_16637)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_489_16637" x="-8" y="-7" width="96" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_489_16637" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_489_16637" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_489_16637" x1="7.79094" y1="19.9675" x2="7.34419" y2="29.4204" gradientUnits="userSpaceOnUse">
                    <stop offset="0.7" stopOpacity="0" />
                    <stop offset="0.9" stopOpacity="0.16" />
                    <stop offset="1" stopOpacity="0.24" />
                </linearGradient>
                <linearGradient id="paint1_linear_489_16637" x1="9.52336" y1="24.2552" x2="2.97276" y2="22.324" gradientUnits="userSpaceOnUse">
                    <stop offset="0.75" stopOpacity="0" />
                    <stop offset="0.95" stopOpacity="0.08" />
                    <stop offset="1" stopOpacity="0.16" />
                </linearGradient>
                <linearGradient id="paint2_linear_489_16637" x1="53.8203" y1="12.1325" x2="53.8203" y2="35.0372" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.2" />
                    <stop offset="0.1" stopOpacity="0.12" />
                    <stop offset="0.2" stopOpacity="0.04" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.65" stopOpacity="0.04" />
                    <stop offset="0.8" stopOpacity="0.16" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <radialGradient id="paint3_radial_489_16637" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.0216 35.0372) rotate(-86.4945) scale(23.0803 76.2695)">
                    <stop offset="0.15" stopOpacity="0.24" />
                    <stop offset="0.35" stopOpacity="0.04" />
                    <stop offset="0.55" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint4_linear_489_16637" x1="51.5791" y1="12.1324" x2="51.5791" y2="35.0371" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.24" />
                    <stop offset="0.1" stopOpacity="0.16" />
                    <stop offset="0.2" stopOpacity="0.04" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.04" />
                    <stop offset="0.9" stopOpacity="0.16" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <clipPath id="clip0_489_16637">
                    <rect width="80" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
