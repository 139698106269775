/* eslint-disable react/prop-types */
import { Page, StyleSheet, View } from "@react-pdf/renderer";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	page: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		padding: 20,
		fontFamily: "FoundryMonoline",
	},
	content: {
		display: "flex",
		flexDirection: "column",
	},
});

export const BasicPage = ({ pdfModel, children }) => (
	<Page size="A4" style={styles.page} orientation="landscape">
		<Header pdfModel={pdfModel} />
		<View style={styles.content}>{children}</View>
		<Footer pdfModel={pdfModel} />
	</Page>
);

BasicPage.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
