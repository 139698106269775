export const defTemplateSdG = [
	{
		name: "versicherung_kranken_pflege",
		label: "Kranken- und Pflegeversicherung",
		control: "button_options",
		cols: 12,
		options: [
			{ value: "gkv", label: "gesetzlich", icon: "Healthcare" },
			{ value: "pkv", label: "privat", icon: "Healthinsurance" },
			{ value: "heilfuersorge", label: "Heilfürsorge", icon: "Healthservice" },
		],
	},
	{
		name: "versicherung_kranken_pflege_beitrag",
		label: "monatlicher Beitrag",
		control: "sliderinput",
		unit: "€",
		default: 0,
		min: 0,
		max: 1100,
		step: 10,
		unit_type: "currency_no_digit",
		cols: 12,
		prefix: "über",
		info: {
			title: "Hinweise",
			description: "Bei der gesetzlichen Krankenversicherung ist der vom Kunden zu tragende Arbeitnehmeranteil, bei privat Versicherten, Selbstständigen oder freiwillig Versicherten der zu zahlende Monatsbeitrag zu erfassen.",
		},
	},
	{
		name: "versicherung_kranken_pflege_ppv",
		label: "monatlicher Beitrag Pflegeversicherung",
		control: "sliderinput",
		unit: "€",
		default: 0,
		min: 0,
		max: 300,
		step: 10,
		unit_type: "currency_no_digit",
		cols: 12,
		prefix: "über",
		info: {
			title: "Hinweise",
			description: "Bei der gesetzlichen Pflegeversicherung ist der vom Kunden zu tragende Arbeitnehmeranteil, bei privat Versicherten, Selbstständigen oder freiwillig Versicherten der zu zahlende Monatsbeitrag zu erfassen.",
		},
	},
	{
		name: "gkv",
		label: "gesetzliche Krankenversicherung",
		control: "autocomplete",
		cols: 12,
		service: "gkvsService",
		watch: ["versicherung_kranken_pflege"],
		visible: { field: "versicherung_kranken_pflege", func: "equal", value: "gkv" },
	},

	{
		control: "multiselect",
		name: "versicherte_kinder",
		label: "Mitversicherte Kinder",
		cols: 12,
		addAll: true,
		watch: ["*kinder"],
		service: "unversicherteKinderService",
	},
];
