import { IconButton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { ContentDialog, ContentDialogInit } from "../components/ContentDialog";

InfoDialog.propTypes = {
	item: PropTypes.object,
};

export default function InfoDialog(props) {
	const { item } = props;
	const { openCD, setOpenCD, tca, showDescription } = ContentDialogInit();

	return (
		<Fragment>
			<IconButton onClick={() => showDescription(item)} sx={{ width: "40px", height: "40px" }}>
				{item.info && <CustomIcon name="Info" />}
			</IconButton>

			<ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} />
		</Fragment>
	);
}
