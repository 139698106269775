import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
    subHeader: {
        fontSize: 16,
        color: "rgba(0, 0, 0, 0.87)"
    },
});

export const ContentSubHeader = ({ children }) => (
    <View>
        <Text style={styles.subHeader}>{children}</Text>
    </View>
)

ContentSubHeader.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};