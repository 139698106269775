import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

SpielKarte.propTypes = {
    spielKarte: PropTypes.object,
    spielKarteIndex: PropTypes.number
}

export default function SpielKarte(props) {
    const { spielKarteIndex } = props;
    const { index, solution, imageName, headerText, bodyText } = props.spielKarte;

    return (
        <Droppable droppableId={`deck_${index}`}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <Draggable
                        key={solution}
                        draggableId={solution}
                        index={index}
                    >
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                    cursor: "grab",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                    border: "1px solid #E5CC9C",
                                    bgcolor: "#FFFFFF",
                                    width: "152px",
                                    height: "220px",
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    borderRadius: "8px",
                                    zIndex: spielKarteIndex
                                }}
                            >
                                <img src={`images/${imageName}.jpg`} style={{ width: "150px", height: "95px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
                                <Typography variant="subtitle2" sx={{ px: '4px', lineHeight: '18px' }}>{headerText}</Typography>
                                <Typography variant="caption" sx={{ px: '4px', lineHeight: '13.11px' }}>{bodyText}</Typography>
                            </Box>
                        )}
                    </Draggable>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
