import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	bodyText: {
		fontSize: 11,
		color: "rgba(0, 0, 0, 0.87)",
		marginTop: 4,
		lineHeight: 1.3,
	},
	bodyTextNoMargin: {
		fontSize: 11,
		lineHeight: 1.3,
		color: "rgba(0, 0, 0, 0.87)",
	},
});

export const BodyText = ({ noMargin = false, children }) => (
	<View>
		<Text style={noMargin ? styles.bodyTextNoMargin : styles.bodyText}>{children}</Text>
	</View>
);

BodyText.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	noMargin: PropTypes.bool,
};
