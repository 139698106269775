import { Box, Typography, Chip } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import BackendLayout from "layouts/BackendLayout";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { useState } from "react";
import PDFViewer from "components/PDFViewer";
import { useTheme } from "@mui/material/styles";

export default function Beratungsmappe() {
	const appWindow = useRecoilValue(appWindowAtom);
	const [showPDF, setShowPDF] = useState(null);
	const [controls, setControls] = useState("");
	const theme = useTheme();
	console.log("Beratungsmappe.jsx: appWindow: ", appWindow);
	const navWidth = 440;

	const repo = [
		{ key: "00", label: "AV Allgemein", version: "04/24", thumbnail: "AV_allgemein.jpg.JPG", tags: [] },
		{ key: "01", label: "AV Beamte", version: "04/24", thumbnail: "AV_Beamte.jpg.JPG", tags: [] },
		{ key: "02", label: "AV Gesprächsvertiefende Inhalte", version: "04/24", thumbnail: "AV_Gesprächsvertiefende Inhalte.jpg.JPG", tags: [] },
	];

	return (
		<BackendLayout>
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
				<Tile sx={{ width: navWidth + "px", padding: "24px", mr: "16px", height: appWindow.contentHeight + "px", overflowY: "auto" }}>
					<Box sx={{ margin: "0px", padding: "0px" }}>
						<Box sx={{ pl: "8px", pt: "8px", pb: "24px", display: "flex", alignItems: "left", flexDirection: "column" }}>
							{showPDF ? (
								<Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											":hover": {
												cursor: "pointer",
												backgroundColor: theme.palette.grey[100],
											},
										}}
										onClick={() => setShowPDF(null)}
									>
										<Box sx={{ pr: "8px", display: "flex", alignItems: "center" }}>
											<CustomIcon name="ArrowBack" size={20} />
										</Box>
										<Typography
											variant="body1"
											sx={{
												whiteSpace: "nowrap",
											}}
										>
											zurück
										</Typography>
									</Box>
									<Box>{controls}</Box>
								</Box>
							) : (
								<>
									<Typography variant="h5" component="div">
										Verzeichnis
									</Typography>
								</>
							)}
							<Box sx={{ width: "100%" }}>
								<Tile sx={{ mt: "8px", mb: "8px" }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<CustomIcon name="Folder" />
										<Box sx={{ pl: "8px" }}>Unternehmensvorlagen</Box>
									</Box>
								</Tile>
								<Tile sx={{ opacity: 0.6, mt: "8px", mb: "8px" }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<CustomIcon name="Folder" />
										<Box sx={{ pl: "8px" }}>Persönliche Vorlagen</Box>
									</Box>
									<Box>
										<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
									</Box>
								</Tile>
								<Tile sx={{ mt: "8px", mb: "8px", opacity: 0.6 }}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<CustomIcon name="Folder" />
										<Box sx={{ pl: "8px" }}>Präsentationen mit Kundenbezug</Box>
									</Box>
									<Box>
										<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
									</Box>
								</Tile>
							</Box>
						</Box>
					</Box>
				</Tile>
				<Tile sx={{ display: "flex", flexDirection: "column", flexGrow: "0", padding: "8px", margin: "0px", height: appWindow.contentHeight + "px" }}>
					{showPDF ? (
						<PDFViewer
							setControls={setControls}
							config={{
								widthCorrection: navWidth,
								heightCorrection: 0,
								file: showPDF,
								controls: {
									showSketchpad: true,
									showZoom: true,
									showPagination: false,
									showThumbnails: false,
									showPrevNext: true,
									position: "bottom",
									thumbnailWidth: 256,
									thumbnailHeight: 32,
									callback: setControls,
								},
							}}
						/>
					) : (
						<>
							<Box sx={{ width: "100%", marginBottom: "16px" }}>
								<Typography variant="h6">Unternehmensvorlagen</Typography>
							</Box>

							<Box sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: "16px", width: "100%" }}>
								{repo.map((item) => (
									<Tile key={item.key} sx={{ display: "flex", flexDirection: "column", padding: "8px" }}>
										<Box onClick={() => setShowPDF(item.key)}>
											<Typography
												variant="body1"
												sx={{
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
												}}
											>
												{item.label}
											</Typography>
											<Typography variant="body2">{item.version}</Typography>
											<img src={"preview/" + item.thumbnail} alt={item.label} style={{ width: "100%", height: "auto" }} />
										</Box>
									</Tile>
								))}
							</Box>
						</>
					)}
				</Tile>
			</Box>
		</BackendLayout>
	);
}
