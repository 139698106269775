import { ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import Tile from "components/Tile";
import { useRecoilState } from "recoil";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { useNavigate } from "react-router-dom";
import { getNewWorkflow } from "services/workflowService";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { useRecoilValue } from "recoil";
import { doReplace } from "services/helperService";

export default function Beratungsprozess() {
	const haushalt = useRecoilValue(haushaltAtom);
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const theme = useTheme();
	const navigate = useNavigate();

	const getMaxLabelWidth = () => {
		let maxWidth = 0;
		workflow.workflow.modules.forEach((module) => {
			const labelWidth = module.label.length * 8 + 48;
			maxWidth = Math.max(maxWidth, labelWidth);
		});
		return maxWidth;
	};

	const maxLabelWidth = getMaxLabelWidth();

	const setActiveModule = (uuid) => {
		const newModule = workflow.workflow.modules.find((module) => module.uuid === uuid);
		setWorkflow(getNewWorkflow(workflow, uuid));

		navigate("/" + newModule.module);
	};

	return (
		<Tile
			sx={{
				width: "500px",
				height: undefined,
				margin: "0px",
				padding: "0px",
			}}
		>
			{workflow.workflow.modules.map((module) => {
				return (
					<Box
						key={module.uuid}
						sx={{
							display: "flex",
							alignItems: "center",
							padding: "8px",
							borderRadius: (module.index === 1 && "8px 8px 0 0") || (module.index === workflow.workflow.modules.length && "0 0 8px 8px") || "0", // Fallback-Wert, falls keiner der Bedingungen erfüllt ist
							backgroundColor: module.index === workflow.active_step ? theme.palette.grey[100] : "#ffffff",
							borderBottom: module.index !== workflow.workflow.modules.length ? "1px solid #E6E6E6" : "none",
							":hover": {
								cursor: "pointer",
								backgroundColor: theme.palette.grey[100],
							},
						}}
						onClick={() => setActiveModule(module.uuid)}
					>
						<Box
							sx={{
								width: "48px",
								height: "48px",
								borderRadius: "50%",
								border: "1px solid #E6E6E6",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexShrink: 0,
							}}
						>
							<Typography variant="h5">{module.index}</Typography>
						</Box>
						<Box sx={{ pl: "8px", width: `${maxLabelWidth}px` }}>
							<Typography variant="body1" component="div">
								{doReplace(module.label, haushalt)}
							</Typography>
							<Typography variant="body2" component="span">
								{module.description}
							</Typography>
						</Box>
						<Box sx={{ flexGrow: 1 }}>
							<Chip label={module.status} variant="outlined" />
						</Box>
						<ArrowForwardOutlined sx={{ width: "24px" }} />
					</Box>
				);
			})}
		</Tile>
	);
}
