import { defTemplatePerson } from "./fragments/person";
import { defTemplateAdresse } from "./fragments/adresse";
import { defTemplateSdE } from "./fragments/sde";
import { defTemplateSdV } from "./fragments/sdv";
import { defTemplateHuF } from "./fragments/huf";
import { defTemplateSdG } from "./fragments/sdg";
import { defTemplateAV } from "./fragments/av";
import { defTemplateVAO } from "./fragments/vao";
import { defTemplateKind } from "./fragments/kind";
import { defTemplateArbeitgeber } from "./fragments/arbeitgeber";
import { defTemplateEinnahmenAusgaben } from "./fragments/einnahmen_ausgaben";

const getDefinition = (path = "", template) => {
	if (path.length > 0) path += ".";
	return template.map((item) => {
		if (item.control === "expandable") {
			return { ...item, name: path + item.name };
		}

		let newItem = { ...item, name: path + item.name };
		if (newItem.children) newItem = { ...newItem, children: getDefinition(path + item.name, newItem.children) };
		return newItem;
	});
};

export const fdefDIN77230 = {
	formdef: [
		{
			name: "bedarf.tabs",
			control: "stepper",
			label: "Bedarfsermittlung",
			image: {
				source: "/images/Illustration.jpg",
				position: "right",
			},
			children: [
				{
					name: "kunde_persoenliches",
					label: "Persönliches",
					backgroundImage: "/images/persoenliches1.jpg",
					info: {
						title: "Persönliches",
						description: `<p><b>Nur wer die richtigen Fragen stellt, kann die richtigen Antworten erhalten.</b></p>
 
              <p>Die DIN-Norm 77230 legt das Verfahren zur Durchführung einer Finanzanalyse für einen Privathaushalt fest. Sie verfolgt einen ganzheitlichen Ansatz und umfasst die Bereiche Absicherung (z.B. Haftungsrisiken, Risiko aus dem Verlust/Beschädigung von Hausrat…), Vorsorge (z.B. Kostenrisiko Krankheit, Arbeitskraftverlust, Altersvorsorge…) und Vermögensplanung (z.B. Aufbau Liquiditätsreserve, Schaffung von Eigenkapital für eine eigengenutzte Immobilie, Ausbildungssparen Kinder, Verschuldung…).</p>
              <p>Ein Privathaushalt im Sinne der DIN-Norm 77230 setzt sich aus einem oder zwei Haushaltsverantwortlichen, die zueinander in Ehe, eingetragener Lebenspartnerschaft oder Partnerschaft stehen und ggf. aus wirtschaftlich abhängigen Kindern zusammen. Als wirtschaftlich abhängige Kinder im Sinne der DIN-Norm gelten alle minderjährigen leiblichen, Adoptiv- und Stiefkinder sowie Enkelkinder, für die eine Kindergeldbezugsberechtigung besteht, sowie alle volljährigen Kinder soweit ein Kindergeldbezugsberechtigung besteht, diese nicht verheiratet sind und kein Erwerbseinkommen aus einem Ausbildungsverhältnis oder hauptberuflichen Beschäftigungsverhältnis bezogen wird.</p>`,
						images: ["/images/persoenliches1.jpg"],
					},
					children: [
						{
							control: "section",
							name: "kunde",
							label: "Persönliche Informationen",
							cols: 12,
							children: getDefinition("kunde", defTemplatePerson),
						},
						{
							control: "upload",
							name: "kunde.ausweidokumente",
							label: "Ausweisdokumente",
							hide_label: true,
							multiple: true,
							dok_types: ["Personalausweis", "Reisepass", "sonstiges"],
						},
						{
							control: "section",
							name: "kunde_adresse",
							label: "Adresse",
							cols: 12,
							children: getDefinition("kunde", defTemplateAdresse),
						},
						{
							control: "section",
							name: "kunde_arbeitgeber",
							label: "Arbeitgeber",
							cols: 12,
							children: getDefinition("kunde.arbeitgeber", defTemplateArbeitgeber),
						},
						{
							control: "section",
							name: "kunde_familienstand",
							label: "Familienstand",
							info: {
								title: "Hinweise",
								description: "Der Familienstand ist relevant für die Absicherung der Hinterbliebenen im Todesfall.",
							},
							cols: 12,
							children: [
								{
									name: "kunde.familienstand",
									label: "Familienstand",
									control: "button_options",
									cols: 12,
									hide_label: true,
									options: [
										{ value: "ledig", label: "ledig/verwitwet", icon: "Person" },
										{ value: "verheiratet", label: "verheiratet", icon: "Married" },
										{ value: "partnerschaft", label: "Partnerschaft", icon: "Couple" },
									],
								},
							],
						},
						{
							control: "section",
							name: "partner",
							label: "Partner",
							cols: 12,
							watch: ["kunde.familienstand"],
							visible: { func: "equal", field: "kunde.familienstand", value: ["verheiratet", "partnerschaft"] },
							children: getDefinition("partner", defTemplatePerson),
						},
						{
							control: "section",
							name: "partner_arbeitgeber",
							label: "Arbeitgeber Partner",
							cols: 12,
							watch: ["kunde.familienstand"],
							visible: { func: "equal", field: "kunde.familienstand", value: ["verheiratet", "partnerschaft"] },
							children: getDefinition("partner.arbeitgeber", defTemplateArbeitgeber),
						},
						{
							control: "expandable",
							label_add: "Kind hinzufügen",
							name: "kinder",
							label: "Kinder",
							delete_label: "das Kind",
							icon: "Child",
							show_number: true,
							cols: 12,
							info: {
								title: "Hinweise",
								description:
									"Als wirtschaftlich abhängige Kinder im Sinne der DIN-Norm gelten alle minderjährigen leiblichen, Adoptiv- und Stiefkinder sowie Enkelkinder, für die eine Kindergeldbezugsberechtigung besteht, sowie alle volljährigen Kinder soweit ein Kindergeldbezugsberechtigung besteht, diese nicht verheiratet sind und kein Erwerbseinkommen aus einem Ausbildungsverhältnis oder hauptberuflichen Beschäftigungsverhältnis bezogen wird.",
							},
							children: getDefinition("", defTemplateKind),
						},
					],
				},
				{
					name: "sde",
					label: "Sicherung des Einkommens",
					backgroundImage: "/images/sde1.jpg",
					info: {
						title: "Sicherung des Einkommens",
						description: `<p>Sie haben Ihren Traumjob gefunden? Die Arbeit macht Ihnen richtig Spaß? Ihre Arbeit gibt Ihnen nicht nur soziale Bestätigung – sie sichert auch Ihre Existenz.
              Eines gilt es dabei besonders zu beachten: Ihre Arbeitskraft ist Ihr wertvollster Besitz! Darum sollte sie genauso geschützt werden wie Ihr materielles Hab und Gut. Denn Krankheiten, Unfälle oder ein Kräfteverfall gefährden Ihre Arbeitskraft mit finanziellen Folgen, die sich oft schwer vorhersehen lassen. Gut, wenn Sie dann rundum abgesichert sind.</p>
               
              <p>In diesem Bedarfsfeld erhalten Sie deshalb einen Überblick über die Absicherung Ihrer Arbeitskraft. Gemeinsam mit Ihrem Berater können Sie sich ein Bild machen für den Fall der Fälle: Was geschieht bei Berufsunfähigkeit, Krankheit oder bei einem Unfall? Vom Krankentagegeld über die Versicherung für schwere Krankheiten (Dread Disease) bis zur privaten Unfallversicherung – Sie werden sehen: Es ist ein gutes Gefühl, zu wissen, dass Sie im Ernstfall immer optimal geschützt sind.</p>
 
              <p><b>Ihr Einkommen sichert Ihre Lebensqualität – sichern Sie Ihr Einkommen!</b></p>`,
						images: ["/images/sde1.jpg", "/images/sde2.jpg", "/images/sde3.jpg", "/images/sde4.jpg", "/images/sde5.jpg"],
					},
					children: [
						{
							control: "tabs",
							name: "sde_beteiligte",
							icon: "PersonFilled",
							default: "sde_beteiligte_kunde",
							children_template: defTemplateSdE,
							children_entities: [
								{ key: "kunde", label: "Kunde" },
								{ key: "partner", label: "Partner" },
								{ key: "kinder", label: "Kinder" },
							],
						},
					],
				},
				{
					name: "huf",
					label: "Haus & Familie",
					backgroundImage: "/images/huf1.jpg",
					info: {
						title: "Haus & Familie",
						description: `<p>Egal ob zur Miete oder im Eigenheim, Wohnen ist immer ein Stück Lebensqualität. In jungen Jahren möchte man Karriere
              machen, mobil und flexibel bleiben. Auf dem weiteren Lebensweg nehmen Details wie Sicherheit und finanzieller Rückhalt
              einen höheren Stellenwert ein.</p>
               
              <p>Voraussetzung für die Erreichung der persönlichen Ziele ist eine gute Planung, Flexibilität und ein ausreichend finanzieller
              Spielraum.</p>
               
              <p>Zum einen können Sie Projekte angehen, für die Sie finanzielles Durchhaltevermögen brauchen: zum Beispiel, das
              eigene Haus bauen. Immobilien sind als Bestandteil des Vermögensaufbaus von großer Bedeutung, da sich mit ihnen
              unterschiedliche Ziele gleichzeitig verwirklichen lassen: Sie bilden Vermögen und sind ein sinnvoller Baustein für die
              Altersvorsorge. Rentner und Pensionäre zum Beispiel, die in ihrem Eigenheim wohnen, sparen so Mietaufwendungen, die
              oft einen erheblichen Anteil an den Gesamtausgaben ausmachen.</p>
               
              <p>Zum anderen können Sie auf Situationen reagieren, die Sie nicht vorhergesehen haben. Ein wichtiger Aspekt ist die
              gesicherte Finanzierung der Ausbildung Ihrer Kinder – egal, was passiert.</p>
               
              <p>Doch eines ist gewiss: Mit ihrem Berater werden Sie die richtige Lösung finden. So machen Sie
              Ihre Träume wahr!</p>`,
						images: ["/images/huf1.jpg", "/images/huf2.jpg", "/images/huf3.jpg", "/images/huf4.jpg", "/images/huf5.jpg"],
					},
					children: getDefinition("haushalt", defTemplateHuF),
				},
				{
					name: "sdv",
					label: "Sicherung der Vermögenswerte",
					backgroundImage: "/images/sdv1.jpg",
					info: {
						title: "Sicherung der Vermögenswerte",
						description: `<p>Die Gegenstände, mit denen wir uns umgeben, haben einen Wert. Zum Teil handelt es sich um rein materielle, zum Teil
              aber auch um emotionale Werte. Der emotionale Wert ist unersetzbar, der materielle schon. Dafür benötigt man allerdings
              die richtige Absicherung.</p>
               
              <p>Denn das Leben stellt einen immer wieder vor unvorhersehbare Herausforderungen: Sie können versehentlich anderen
              einen Vermögensschaden zufügen oder Ihre eigenen Vermögenswerte werden in Mitleidenschaft gezogen – sei es durch
              Dritte oder durch höhere Gewalt. Schon ein kleiner Vorfall kann Sie finanziell in Schwierigkeiten bringen.</p>
               
              <p>In diesen Momenten werden Sie den Wert einer Sicherung Ihrer Vermögenswerte zu schätzen wissen. Ihr Berater
              verschafft Ihnen deshalb in diesem Bedarfsfeld einen Überblick über die bisher geleistete Absicherung – von der
              Privathaftpflicht bis zur Rechtsschutzversicherung. Besteht Optimierungsbedarf? Dann wird er Ihnen sicher gerne
              weiterhelfen. Denn schließlich geht es um Ihre Werte.</p>`,
						images: ["/images/sdv1.jpg", "/images/sdv2.jpg", "/images/sdv3.jpg", "/images/sdv4.jpg", "/images/sdv5.jpg"],
					},
					children: getDefinition("haushalt", defTemplateSdV),
				},
				{
					name: "vao",
					label: "Vermögensaufbau/-optimierung",
					backgroundImage: "/images/vao6.jpg",
					info: {
						title: "Vermögensaufbau/-optimierung",
						description: `<p>Wichtig für den Aufbau Ihres Vermögens ist, dass Sie nichts dem Zufall überlassen. Das Geheimnis eines zielgerichteten
              Vermögensaufbaus lautet: Struktur.</p>
               
              <p>Struktur entscheidet zu 90% über den Erfolg des langfristigen Ansparens von Kapital. Finanzexperten raten, dass
              investierte Vermögen optimal zu mischen und zu streuen. Dadurch mindern Sie das Risiko und steigern gleichzeitig die
              Rendite.</p>
               
              <p>Wie das optimale Mischungsverhältnis aussieht, hängt im Einzelfall von Ihrem Alter, Ihrem Risikoprofil, dem Sparziel und
              der Anlagedauer ab. Dabei hilft Ihnen Ihr Berater, mittels wissenschaftlich abgesicherter Grundlagen und erarbeitet Ihre Investitionsziele, Ihren Anlagetyp und gibt Ihnen konkrete Empfehlungen. Am Ende können Sie sicher sein, dass Ihr Geld gut angelegt ist.</p>`,
						images: ["/images/vao1.jpg", "/images/vao2.jpg", "/images/vao4.jpg", "/images/vao5.jpg", "/images/vao6.jpg", "/images/vao7.jpg", "/images/vao8.jpg", "/images/vao9.jpg"],
					},
					children: getDefinition("haushalt", defTemplateVAO),
				},
				{
					name: "av",
					label: "Altersvorsorge",
					backgroundImage: "/images/av3.jpg",
					info: {
						title: "Altervorsorge",
						description: `<p>Die gesetzliche Rentenversicherung ist ohne Zweifel der wichtigste Sektor des deutschen Sozialsicherungssystems.
              Aufgrund der Entwicklungen des Arbeitsmarktes und der Überalterung der Gesellschaft nimmt die Bedeutung der privaten
              Altersvorsorge allerdings immer deutlicher zu.</p>
               
              <p>Grundlage für eine vernünftige Alterssicherung ist ein individuell abgestimmtes Vorsorgekonzept, denn nicht jedes
              Angebot passt zu jedem Menschen und zu jeder Situation.</p>
               
              <p>Mit dem Altersvorsorgeansatz legen Sie den Grundstein für eine gesicherte Zukunft: Die richtigen
              Förderungen abschöpfen, die optimalen Programme nutzen, die Altersvorsorge auf mehrere Säulen stellen.
              Verschaffen Sie sich einen umfassenden Überblick über den derzeitigen Stand Ihrer Absicherung und optimieren Sie Ihre
              Altersvorsorge: In die Sicherung Ihres wohlverdienten Ruhestandes zu investieren, ist eine der besten Investitionen Ihres
              Lebens!</p>
               
              <p>Im Bedarfsfeld Altersvorsorge wird unter Berücksichtigung bereits erworbener Altersversorgungsansprüche (aus gesetzl.
              Versorgungssystemen, betrieblicher/privater Ansprüche) die Versorgungslücke zum Ruhestandszeitpunkt ermittelt. Die
              Schließung der Lücke erfolgt unter Berücksichtigung der persönlichen Rahmenbedingungen und der Präferenzen der
              Personen im Haushalt.</p>`,
						images: ["/images/av1.jpg", "/images/av2.jpg", "/images/av3.jpg", "/images/av4.jpg"],
					},
					children: [
						{
							control: "tabs",
							name: "av_beteiligte",
							icon: "PersonFilled",
							default: "av_beteiligte_kunde",
							children_template: defTemplateAV,
							children_entities: [
								{ key: "kunde", label: "Kunde" },
								{ key: "partner", label: "Partner" },
								{ key: "kinder", label: "Kinder" },
							],
						},
					],
				},
				{
					name: "sdg",
					label: "Sicherung der Gesundheit",
					backgroundImage: "/images/sdg1.jpg",
					info: {
						title: "Sicherung der Gesundheit",
						description: `<p>"Gesundheit ist das höchste Gut." Eine alte Volksweisheit – aber aktueller denn je! Denn Ihre Gesundheit entscheidet darüber, ob Sie leistungsfähig sind und berufliche Herausforderungen meistern. Ihre Gesundheit ist die Grundlage dafür, dass Sie die erarbeitete Lebensqualität genießen können – zusammen mit Ihren Freunden und der Familie.</p>
 
              <p>Was aber, wenn Sie doch einmal medizinische Hilfe brauchen? Wenn Sie für eine ambulante Untersuchung zum Arzt oder Zahnarzt müssen? Oder sogar eine stationäre Behandlung im Krankenhaus benötigen? Dann ist es umso wichtiger, dass Sie frei entscheiden können: welchen Arzt Sie wählen und welche Heilmethoden oder Medikamente Sie bevorzugen, wo und unter welchen Bedingungen Sie behandelt werden.</p>
               
              <p>All das steuern Sie durch eine optimale Krankenversicherung. Darum ermittelt Ihr Berater Ihren aktuellen Schutz und passt ihn bei Bedarf an. Denn ganz gleich, ob privat, gesetzlich oder Zusatzversicherung: Ihre Gesundheit verdient immer die beste Wahl!</p>`,
						images: ["/images/sdg1.jpg", "/images/sdg2.jpg", "/images/sdg3.jpg", "/images/sdg4.jpg"],
					},
					children: [
						{
							control: "tabs",
							name: "sdg_beteiligte",
							icon: "PersonFilled",
							default: "sdg_beteiligte_kunde",
							children_template: defTemplateSdG,
							children_entities: [
								{ key: "kunde", label: "Kunde" },
								{ key: "partner", label: "Partner" },
								{ key: "kinder", label: "Kinder" },
							],
						},
					],
				},

				{
					name: "ausgaben",
					label: "Einnahmen/Ausgaben",
					backgroundImage: "/images/E-A.jpg",
					info: {
						title: "Einnahmen/Ausgaben",
						description: `<p>Die Einnahmen-/Ausgabenrechnung stellt Ihr Nettogesamteinkommen den Ausgaben gegenüber. Das Nettogesamteinkommen besteht aus ihrem Erwerbseinkommen und ggf. weiteren Einnahmen aus Vermietung & Verpachtung, Kapitalvermögen, Renten oder sonstigen Einnahmen. Die Ausgaben setzen sich aus ihrem Konsum, den Kosten für Wohnen, Zins-/Tilgungsleistungen sowie ihren Ausgaben und Sparraten in den Bereichen Absicherung, Vorsorge und Vermögensplanung zusammen. Mit der übersichtlichen Darstellung wird das Verhältnis von Einnahmen und Ausgaben transparent dargestellt – alles auf einen Blick.</p>`,
						images: ["/images/E-A.jpg"],
					},
					children: getDefinition("haushalt", defTemplateEinnahmenAusgaben),
				},
				{
					name: "vermoegensbilanz",
					label: "Vermögensbilanz",
					backgroundImage: "/images/Bilanz.jpg",
					info: {
						title: "Vermögensbilanz",
						description: `<p>In der Vermögensbilanz werden die Vermögenswerte den Verbindlichkeiten gegenübergestellt. Das Vermögen setzt sich zusammen aus dem Barvermögen, den Kapitalanlagen, dem Immobilienvermögen, sowie sonstigen Vermögenswerten. Die Verbindlichkeiten bestehen aus der Summe der Darlehensstände für eigen- und fremdgenutzte Immobilien, Ratenkredite/Konsumentendarlehen sowie sonstigen Verbindlichkeiten. Als nicht bilanzierbare Positionen gelten z.B. die Rürup-Rente oder gesetzliche Renten, da diese nicht als Kapitalwert verfügbar sind sowie Eventualverbindlichkeiten (z.B. Bürgschaften).</p><p>
Die Struktur aus Vermögenswerten und Verbindlichkeiten sollte regelmäßig im Hinblick auf die angestrebten Anlageziele, das persönliche Risikoprofil und die Fristigkeiten geprüft werden.
</p>`,
						images: ["/images/Bilanz.jpg"],
					},
					children: [
						{
							control: "component",
							name: "vermoegensbilanz",
							component: "Vermoegensbilanz",
						},
					],
				},
			],
		},
	],
	transform_in: [],
	transform_out: [],
};
