import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chart from "components/Chart";
import GaugeChart from "components/GaugeChart";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";
import { backendGet } from "services/dataService";
import { calcIst, getFamilienScore } from "services/DINIstCalculationService";
import { calcSoll } from "services/DINSollCalculationService";
import { getDatum } from "services/formatService";
import { getDisplayName } from "services/helperService";
import AnalyseErgebnisse from "./AnalyseErgebnisse";
import CustomIcon from "./CustomIcon";

const getScores = (werte) => {
	const relevant = werte.filter((wert) => wert.relevant);
	const stufe1 = werte.filter((wert) => wert.bedarfsstufe === "1");
	const stufe2 = werte.filter((wert) => wert.bedarfsstufe === "2");

	return {
		gesamt: Math.round(relevant.reduce((acc, curr) => acc + curr.ist_percent, 0) / relevant.length),
		stufe1: Math.round(stufe1.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe1.length),
		stufe2: Math.round(stufe2.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe2.length),
	};
};

FamilyScore.propTypes = {
	width: PropTypes.number,
};

export default function FamilyScore({ width = 300 }) {
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [vertragszuordnung, setVertragszuordnung] = useRecoilState(vertragszuordnungAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const [calculatedDisplaywerte, setCalculatedDisplaywerte] = useState(calcIst(calculatedSollwerte, [], bedarf));
	const [openDINErgebnise, setOpenDINErgebnise] = useState(false);
	const familienScore = getFamilienScore(calculatedDisplaywerte);
	const [isLoading, setIsLoading] = useState(true);

	const handleShowDINErgebnisse = () => {
		setOpenDINErgebnise(true);
	};

	const handleCloseDINErgebnisse = () => {
		setOpenDINErgebnise(false);
	};

	useEffect(() => {
		setCalculatedDisplaywerte(calcIst(calculatedSollwerte, vertragszuordnung, bedarf));
	}, [vertragszuordnung]);

	const setResultVertragszuordnung = (data) => {
		if (data?.zuordnung) {
			setVertragszuordnung(data.zuordnung);
		}
	};

	useEffect(() => {
		backendGet({ label: "Vertragszuordnung", route: "vertragszuordnung/" + bedarf.kunde.haushalt_id, setResult: setResultVertragszuordnung, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	}, [bedarf]);

	const getPieChart = (percent) => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "64px", width: "100%" }}>
				<Box sx={{ flex: "1 1 40%", position: "relative", alignContent: "center" }}>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Chart
							chartDef={{
								unit: "%",
								size: "48",
								chart_type: "doughnut",
								data: [
									{
										value: percent,
										color: "#4caf50",
										label: "Istwert",
									},
									{
										value: 100 - percent,
										color: "#EF5350",
										label: "fehlt",
									},
								],
							}}
						/>
					</Box>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Typography variant="caption">{Math.round(percent, 1)}%</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	const Person = ({ person, rolle = "haushaltsverantwortlich" }) => {
		if (person === null || person === undefined) return null;
		const personCalculatedDisplaywerte = calculatedDisplaywerte.filter((wert) => wert.haushaltsmitglied?.person_uuid === person.uuid);
		const personScores = getScores(personCalculatedDisplaywerte);

		let icon = person.geschlecht === "weiblich" ? "Femaleperson" : "Person";
		if (rolle === "kind") icon = "Child";
		return (
			<TableRow sx={{ margin: "0px" }}>
				<TableCell sx={{ borderBottom: "1px solid #E6E6E6", p: 0, m: 0, pl: "8px", pr: "8px", overflow: "hidden", whiteSpace: "nowrap" }}>
					<Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
						<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
							<CustomIcon name={icon} size={32} />
							<Box sx={{ pl: "4px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
								<b>{getDisplayName(person)}</b>
							</Box>
						</Box>
						<Box sx={{ flexShrink: 0, pr: "24px" }}>{getPieChart(personScores.gesamt)}</Box>
					</Box>
				</TableCell>
			</TableRow>
		);
	};
	Person.propTypes = {
		person: PropTypes.object,
		rolle: PropTypes.string,
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				onClick={handleShowDINErgebnisse}
				sx={{
					":hover": {
						cursor: "pointer",
					},
				}}
			>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Finanz-Score</Typography>
					<Typography variant="body2">{getDatum(new Date())}</Typography>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
					<GaugeChart value={familienScore} width={width} />
				</Box>
			</Box>
			<Box>
				<TableContainer component={Paper} sx={{ borderRadius: "12px", overflow: "hidden", border: "1px solid #E6E6E6", boxShadow: "none", width: "100%" }}>
					<Table sx={{ width: "100%", boxShadow: "none" }}>
						<TableBody>
							<Person person={haushalt.kunde} />
							<Person person={haushalt.partner} />
							{haushalt.kinder.map((kind, index) => (
								<Person key={index} person={kind} rolle="kind" />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Dialog
				open={openDINErgebnise}
				onClose={handleCloseDINErgebnisse}
				maxWidth={false}
				PaperProps={{
					style: {
						width: "100%",
						height: "100%",
						margin: 0,
						maxHeight: "90%",
					},
				}}
			>
				<DialogTitle>DIN 77230 Ergebnisse</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexGrow: "0", padding: "0px", height: "100%" }}>
						<AnalyseErgebnisse hideTitle={true} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDINErgebnisse}>schließen</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
