import { atom, selector } from "recoil";

export const haushaltAtom = atom({
	key: "haushaltAtom",
	default: null,
});

export const kundeDisplayNameSelector = selector({
	key: "kundeDisplayNameSelector",
	get: ({ get }) => {
		const haushalt = get(haushaltAtom);
		if (haushalt === null || haushalt === undefined) return "";
		if (haushalt.kunde.nachname === null) {
			if (haushalt.kunde.vorname === null) return "./.";
			return haushalt.kunde.vorname;
		}
		return haushalt.kunde.vorname + " " + haushalt.kunde.nachname;
	},
});
