import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	headerContainer: {
		marginBottom: 16,
	},
	headline: {
		fontSize: 18,
		fontWeight: "bold",
		color: "rgba(0, 0, 0, 0.87)",
	},
});

export const ContentHeader = ({ children, textAlign = "left" }) => {
	return (
		<View style={{ ...styles.headerContainer, textAlign: textAlign }}>
			<Text style={styles.headline}>{children}</Text>
		</View>
	);
};

ContentHeader.propTypes = {
	children: PropTypes.string,
	textAlign: PropTypes.string,
};
