import { FormControl, TextField, Autocomplete, Box } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { isItemHidden } from "../DMFhelperService";

MDDAutocomplete.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	trigger: PropTypes.func,
	getValues: PropTypes.func,
	services: PropTypes.object,
	disabled: PropTypes.bool,
	setValue: PropTypes.func,
};

function MDDAutocomplete(props) {
	const { item, variant = "outlined", control, trigger, services, getValues, disabled, setValue } = props;
	const [options, setOptions] = useState(item.options || []);
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	useEffect(() => {
		const fetchOptions = async () => {
			if (item.service) {
				const fetchedOptions = await services[item.service](getValues());
				setOptions(fetchedOptions);
			} else {
				setOptions(item.options || []);
			}
		};

		fetchOptions();
	}, [item.service, watched]);

	return (
		<FormControl fullWidth sx={{ pt: "16px", pb: "8px", display: hidden ? "none" : "block" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || ""}
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						options={options.sort((a, b) => a.label.localeCompare(b.label))}
						disabled={disabled}
						getOptionLabel={(option) => option.label}
						renderOption={(props, option) => (
							<li {...props} key={option.value}>
								{option.logo && (
									<Box sx={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
										<img src={`pg_logos/${option.logo}`} alt="" style={{ width: "40px" }} />
									</Box>
								)}{" "}
								{option.label}
							</li>
						)}
						renderInput={(params) => <TextField {...params} label={item.label} variant={variant} size="small" sx={{ pt: "2px" }} disabled={disabled} />}
						value={options.find((option) => option.value === value) || null}
						onChange={(event, newValue) => {
							setValue(item.name + "_obj", newValue ? newValue : "");
							onChange(newValue ? newValue.value : "");
							trigger(item.name);
						}}
					/>
				)}
			/>
		</FormControl>
	);
}

export default MDDAutocomplete;
