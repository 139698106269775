import axios from "axios";
import { getAuthToken } from "./authService";

const BACKEND = import.meta.env.VITE_BACKEND + "/api/";

export const backendGet = async (props) => {
	const { label, setIsLoading, route, setResult, enqueueSnackbar, options = {} } = props;
	const accessToken = await getAuthToken();
	setIsLoading(true);
	axios
		.get(BACKEND + route, {
			...options,
			headers: {
				"X-Access-Token": accessToken,
			},
		})
		.then((res) => {
			if (res.status === 200) {
				if (setResult) setResult(res.data);
				setIsLoading(false);
			} else {
				if (enqueueSnackbar)
					enqueueSnackbar("Daten (" + label + ") konnten nicht abgerufen werden.", {
						variant: "error",
					});
				setIsLoading(false);
			}
		})
		.catch((e) => {
			if (e.response && e.response.status === 401) {
				window.location.href = "/";

				if (enqueueSnackbar) {
					enqueueSnackbar("Bitte erneut anmelden.", {
						variant: "error",
					});
				}
			} else {
				if (enqueueSnackbar)
					enqueueSnackbar("Daten (" + label + ") konnten nicht abgerufen werden.", {
						variant: "error",
					});
			}
			console.error("error", e);
			setIsLoading(false);
		});
};

export const backendGetSync = async (props) => {
	const { label, setIsLoading, route, enqueueSnackbar, options = {} } = props;
	const accessToken = await getAuthToken();
	setIsLoading(true);

	try {
		const res = await axios.get(BACKEND + route, {
			...options,
			headers: {
				"X-Access-Token": accessToken,
			},
		});

		if (res.status === 200) {
			return res.data;
		} else {
			if (enqueueSnackbar)
				enqueueSnackbar("Daten (" + label + ") konnten nicht abgerufen werden.", {
					variant: "error",
				});
		}
	} catch (e) {
		if (e.response && e.response.status === 401) {
			window.location.href = "/";

			if (enqueueSnackbar) {
				enqueueSnackbar("Bitte erneut anmelden.", {
					variant: "error",
				});
			}
		} else {
			if (enqueueSnackbar)
				enqueueSnackbar("Daten (" + label + ") konnten nicht abgerufen werden.", {
					variant: "error",
				});
		}
		console.error("error", e);
	} finally {
		setIsLoading(false);
	}
};

export const backendPost = async (props) => {
	const { label, setIsLoading, route, toSend, setResult, enqueueSnackbar, options = {} } = props;
	const accessToken = await getAuthToken();
	setIsLoading(true);
	axios
		.post(BACKEND + route, toSend, {
			...options,
			headers: {
				"X-Access-Token": accessToken,
			},
		})
		.then((res) => {
			if (res.status === 200) {
				setResult(res.data);
				setIsLoading(false);
				if (res.data.message && enqueueSnackbar) enqueueSnackbar(res.data.message, { variant: "success" });
			} else {
				if (enqueueSnackbar)
					enqueueSnackbar("Daten (" + label + ") konnten nicht gespeichert werden.", {
						variant: "error",
					});
				setIsLoading(false);
			}
		})
		.catch((e) => {
			if (e.response && e.response.status === 401) {
				window.location.href = "/";

				if (enqueueSnackbar) {
					enqueueSnackbar("Bitte erneut anmelden.", {
						variant: "error",
					});
				}
			} else {
				if (enqueueSnackbar)
					enqueueSnackbar("Daten (" + label + ") konnten nicht abgerufen werden.", {
						variant: "error",
					});
			}
			console.error("error", e);
			setIsLoading(false);
		});
};
