import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

ContentDialogInit.propTypes = {};

export function ContentDialogInit() {
  const [openCD, setOpenCD] = useState(false);
  const [tca, setTca] = useState({});
  const [CDActions, setCDActions] = useState(null);

  const showDescription = (item) => {
    setTca({
      maxWidth: "sm",
      title: item.info.title || "",
      content: <>{item.info.description}</>,
      actions: (
        <>
          <Button onClick={() => setOpenCD(false)}>Schließen</Button>
        </>
      ),
    });
    setOpenCD(true);
  };

  return {
    openCD,
    setOpenCD,
    tca,
    setTca,
    CDActions,
    setCDActions,
    showDescription,
  };
}

ContentDialog.propTypes = {
  openCD: PropTypes.bool.isRequired,
  setOpenCD: PropTypes.func.isRequired,
  tca: PropTypes.object,
  maxWidth: PropTypes.string,
  centered: PropTypes.bool,
  CDActions: PropTypes.object,
};

export function ContentDialog(props) {
  const { openCD, setOpenCD, tca, centered = false, CDActions = null } = props;

  const handleClose = () => {
    setOpenCD(false);
  };

  return (
    <Dialog open={openCD} onClose={handleClose} maxWidth={props.tca.maxWidth || props.maxWidth || "md"} PaperProps={{ sx: { borderRadius: "8px" } }}>
      {tca.title && tca.title !== "" && (
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {tca?.title}
            {CDActions?.top && CDActions.top}
          </Box>
        </DialogTitle>
      )}
      <DialogContent
        dividers
        sx={
          centered
            ? {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }
            : {}
        }
      >
        {tca?.content}
      </DialogContent>
      {tca.actions && <DialogActions sx={{ padding: "4px" }}>{tca?.actions}</DialogActions>}
      {CDActions?.bottom && <DialogActions sx={{ padding: "4px" }}>{CDActions.bottom}</DialogActions>}
    </Dialog>
  );
}
