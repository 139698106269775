import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function Spinner(props) {
	const { message = "Inhalte werden geladen..." } = props;
	return (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100vw",
				height: "100vh",
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				zIndex: 9999,
			}}
		>
			<CircularProgress color="primary" size={120} />
			<Typography component="h1">{message}</Typography>
		</Box>
	);
}

Spinner.propTypes = {
	message: PropTypes.string,
};
