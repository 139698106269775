import { Box, IconButton, Menu, Typography, Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import CustomIcon from "components/CustomIcon";
import Notizen from "components/Notizen";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Tile from "components/Tile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PropTypes from "prop-types";
import AnalyseDownload from "pages/Print/AnalyseDownload";
import { backendGet } from "services/dataService";
import { useSnackbar } from "notistack";

export default function CreatePDF(props) {
	const { dokumente } = props;
	const [anchorNotes, setAnchorNotes] = useState(null);
	const openNotes = Boolean(anchorNotes);
	const [showAnalyseDownload, setShowAnalyseDownload] = useState(false);
	const [dlKey, setDlKey] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handlNotesMenuOpen = (event) => {
		setAnchorNotes(event.currentTarget);
	};

	const handleNotesMenuClose = () => {
		setAnchorNotes(null);
	};

	const sendPDFAsDownload = (data) => {
		console.log("data", data);
		const pdfBlob = new Blob([Uint8Array.from(atob(data.pdf.split("base64,")[1]), (c) => c.charCodeAt(0))], {
			type: "application/pdf",
		});

		const link = document.createElement("a");
		link.href = URL.createObjectURL(pdfBlob);
		link.download = "Bedarfsermittlung.pdf";
		link.click();

		URL.revokeObjectURL(link.href);
	};

	const createAndSendPDF = (key) => {
		setDlKey(new Date());
		if (key === "Analyse") {
			setShowAnalyseDownload(true);
		}
		if (key === "ADD9B584-4A84-4532-9170-C2581D3C7B65") {
			backendGet({ label: "PDF", setIsLoading: setIsLoading, route: "pdf/complete/" + "ADD9B584-4A84-4532-9170-C2581D3C7B65", setResult: sendPDFAsDownload, enqueueSnackbar: enqueueSnackbar });
		}
	};

	return (
		<>
			<IconButton onClick={handlNotesMenuOpen} sx={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#616161", borderRadius: "4px", marginLeft: "16px", padding: "0px", height: "48px" }}>
				<Typography sx={{ fontSize: "14px", paddingLeft: "8px" }}>PDF erzeugen</Typography>
				<CustomIcon name="PDF" size={48} />
			</IconButton>
			<Menu
				id="notes-menu"
				anchorEl={anchorNotes}
				open={openNotes}
				onClose={handleNotesMenuClose}
				TransitionComponent={Fade}
				MenuListProps={{ component: "div", sx: { padding: "0px" } }}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
						},
					},
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start", width: "520px", margin: "16px" }}>
					<Box>
						<Typography variant="h5">Dokumente bereit zum Export</Typography>
						<Typography variant="body1">Folgende Dokumente stehen zum Export bereit. Sie können diese vorher prüfen und Anmerkungen hinzufügen.</Typography>
					</Box>
					<Box>
						<IconButton edge="end" color="inherit" onClick={handleNotesMenuClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				<Box sx={{ padding: "16px" }}>
					{dokumente.map((dokument, index) => (
						<Tile key={index} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							{dokument.label}
							<Button variant="outlined" startIcon={<PictureAsPdfIcon />} onClick={() => createAndSendPDF(dokument.key)}>
								PDF erzeugen
							</Button>
						</Tile>
					))}
				</Box>
			</Menu>
			{showAnalyseDownload && <AnalyseDownload key={dlKey} />}
		</>
	);
}
CreatePDF.propTypes = {
	dokumente: PropTypes.array,
};
