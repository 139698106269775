import PropTypes from "prop-types";

PencilTool.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
    fill: PropTypes.string
};

export default function PencilTool(props) {
	const { width = 80, height = 48, fill = "#3E4F93" } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_489_16622)">
                <g filter="url(#filter0_d_489_16622)">
                    <path d="M2.73394 23.4623C2.72385 23.4653 2.71478 23.4678 2.7046 23.4705C1.76513 23.7271 1.76513 25.0033 2.70461 25.2598C2.71478 25.2626 2.72385 25.265 2.73394 25.2681C2.97484 25.3405 6.31731 26.3436 8.5072 26.932C11.9014 27.8441 17.3929 29.0334 17.3929 29.0334V19.6969C17.3929 19.6969 11.9014 20.8862 8.5072 21.7983C6.31731 22.3867 2.97484 23.3899 2.73394 23.4623Z" fill={fill} />
                    <path d="M2.73394 23.4623C2.72385 23.4653 2.71478 23.4678 2.7046 23.4705C1.76513 23.7271 1.76513 25.0033 2.70461 25.2598C2.71478 25.2626 2.72385 25.265 2.73394 25.2681C2.97484 25.3405 6.31731 26.3436 8.5072 26.932C11.9014 27.8441 17.3929 29.0334 17.3929 29.0334V19.6969C17.3929 19.6969 11.9014 20.8862 8.5072 21.7983C6.31731 22.3867 2.97484 23.3899 2.73394 23.4623Z" fill="url(#paint0_linear_489_16622)" />
                    <path d="M80 36.7301V12.0009L44.9663 12.0009C44.6129 12.0009 44.2706 12.1188 43.999 12.3341L40.1029 15.4221L40.1029 33.3088L43.999 36.3968C44.2706 36.6122 44.6129 36.7301 44.9663 36.7301L80 36.7301Z" fill="white" />
                    <path d="M80 36.7301V12.0009L44.9663 12.0009C44.6129 12.0009 44.2706 12.1188 43.999 12.3341L40.1029 15.4221L40.1029 33.3088L43.999 36.3968C44.2706 36.6122 44.6129 36.7301 44.9663 36.7301L80 36.7301Z" fill="url(#paint1_linear_489_16622)" />
                    <path d="M45.0667 30.3628C45.3319 29.9539 45.3573 29.448 45.1341 29.0179L43.676 26.2083C43.0715 25.0435 43.0715 23.6857 43.676 22.5209L45.1341 19.7113C45.3573 19.2812 45.3319 18.7753 45.0667 18.3664L43.5893 16.0889C43.0622 15.2765 43.1107 14.2489 43.7122 13.4825L44.8757 12L17.7052 19.0178C17.3765 19.1027 17.149 19.3812 17.149 19.6986L17.149 29.0306C17.149 29.348 17.3765 29.6265 17.7051 29.7114L44.8757 36.7292L43.7122 35.2467C43.1107 34.4803 43.0622 33.4528 43.5893 32.6403L45.0667 30.3628Z" fill="white" />
                    <path d="M45.0667 30.3628C45.3319 29.9539 45.3573 29.448 45.1341 29.0179L43.676 26.2083C43.0715 25.0435 43.0715 23.6857 43.676 22.5209L45.1341 19.7113C45.3573 19.2812 45.3319 18.7753 45.0667 18.3664L43.5893 16.0889C43.0622 15.2765 43.1107 14.2489 43.7122 13.4825L44.8757 12L17.7052 19.0178C17.3765 19.1027 17.149 19.3812 17.149 19.6986L17.149 29.0306C17.149 29.348 17.3765 29.6265 17.7051 29.7114L44.8757 36.7292L43.7122 35.2467C43.1107 34.4803 43.0622 33.4528 43.5893 32.6403L45.0667 30.3628Z" fill="url(#paint2_linear_489_16622)" />
                    <path d="M45.0667 30.3628C45.3319 29.9539 45.3573 29.448 45.1341 29.0179L43.676 26.2083C43.0715 25.0435 43.0715 23.6857 43.676 22.5209L45.1341 19.7113C45.3573 19.2812 45.3319 18.7753 45.0667 18.3664L43.5893 16.0889C43.0622 15.2765 43.1107 14.2489 43.7122 13.4825L44.8757 12L17.7052 19.0178C17.3765 19.1027 17.149 19.3812 17.149 19.6986L17.149 29.0306C17.149 29.348 17.3765 29.6265 17.7051 29.7114L44.8757 36.7292L43.7122 35.2467C43.1107 34.4803 43.0622 33.4528 43.5893 32.6403L45.0667 30.3628Z" fill="url(#paint3_linear_489_16622)" />
                    <path d="M52.7433 36.7292L52.7433 12L48.7058 12L48.7058 36.7292H52.7433Z" fill={fill} />
                    <path d="M52.7433 36.7292L52.7433 12L48.7058 12L48.7058 36.7292H52.7433Z" fill="url(#paint4_linear_489_16622)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_489_16622" x="-8" y="-7" width="96" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_489_16622" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_489_16622" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_489_16622" x1="9.76006" y1="21.5522" x2="9.60481" y2="27.4849" gradientUnits="userSpaceOnUse">
                    <stop offset="0.5" stopOpacity="0" />
                    <stop offset="0.784027" stopOpacity="0.12" />
                    <stop offset="1" stopOpacity="0.16" />
                </linearGradient>
                <linearGradient id="paint1_linear_489_16622" x1="51.9653" y1="11.9056" x2="51.9653" y2="36.9701" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.05" stopOpacity="0.08" />
                    <stop offset="0.253195" stopOpacity="0.16" />
                    <stop offset="0.326559" stopOpacity="0.02" />
                    <stop offset="0.68209" stopOpacity="0.04" />
                    <stop offset="0.75" stopOpacity="0.24" />
                    <stop offset="0.95" stopOpacity="0.2" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <linearGradient id="paint2_linear_489_16622" x1="32.3327" y1="16.6514" x2="31.4556" y2="33.0859" gradientUnits="userSpaceOnUse">
                    <stop offset="0.5" stopOpacity="0" />
                    <stop offset="0.848204" stopOpacity="0.16" />
                    <stop offset="1" stopOpacity="0.24" />
                </linearGradient>
                <linearGradient id="paint3_linear_489_16622" x1="31.3205" y1="15.7094" x2="33.1326" y2="31.0505" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.106127" stopOpacity="0.12" />
                    <stop offset="0.334671" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_489_16622" x1="50.7246" y1="12.1421" x2="50.7246" y2="36.7292" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.05" stopOpacity="0.08" />
                    <stop offset="0.253195" stopOpacity="0.16" />
                    <stop offset="0.326559" stopOpacity="0" />
                    <stop offset="0.68209" stopOpacity="0.04" />
                    <stop offset="0.75" stopOpacity="0.24" />
                    <stop offset="0.95" stopOpacity="0.2" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <clipPath id="clip0_489_16622">
                    <rect width="80" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
