import PropTypes from "prop-types";

PenTool.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string
};

export default function PenTool(props) {
    const { width = 80, height = 48, fill = "#3E4F93" } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4297_10435)">
                <g filter="url(#filter0_d_4297_10435)">
                    <path d="M8 25.5V22.5L2.5 22.5C1.67157 22.5 1 23.1716 1 24C1 24.8284 1.67157 25.5 2.5 25.5L8 25.5Z" fill={fill} />
                    <path d="M8 25.5V22.5L2.5 22.5C1.67157 22.5 1 23.1716 1 24C1 24.8284 1.67157 25.5 2.5 25.5L8 25.5Z" fill="url(#paint0_linear_4297_10435)" />
                    <path d="M13.9346 26V22L8.10102 22C7.82086 22 7.59375 22.2271 7.59375 22.5073L7.59375 25.4927C7.59375 25.7729 7.82086 26 8.10102 26L13.9346 26Z" fill="white" />
                    <path d="M13.9346 26V22L8.10102 22C7.82086 22 7.59375 22.2271 7.59375 22.5073L7.59375 25.4927C7.59375 25.7729 7.82086 26 8.10102 26L13.9346 26Z" fill="url(#paint1_linear_4297_10435)" />
                    <path d="M13.9346 26V22L8.10102 22C7.82086 22 7.59375 22.2271 7.59375 22.5073L7.59375 25.4927C7.59375 25.7729 7.82086 26 8.10102 26L13.9346 26Z" fill="url(#paint2_linear_4297_10435)" />
                    <path d="M25.4699 31.1495L25.4699 16.9463L14.5355 17.4264C14.1265 17.4416 13.8027 17.7775 13.8027 18.1868L13.8027 29.909C13.8027 30.3183 14.1265 30.6542 14.5355 30.6694L25.4699 31.1495Z" fill="white" />
                    <path d="M25.4699 31.1495L25.4699 16.9463L14.5355 17.4264C14.1265 17.4416 13.8027 17.7775 13.8027 18.1868L13.8027 29.909C13.8027 30.3183 14.1265 30.6542 14.5355 30.6694L25.4699 31.1495Z" fill="url(#paint3_linear_4297_10435)" />
                    <path d="M25.4699 31.1495L25.4699 16.9463L14.5355 17.4264C14.1265 17.4416 13.8027 17.7775 13.8027 18.1868L13.8027 29.909C13.8027 30.3183 14.1265 30.6542 14.5355 30.6694L25.4699 31.1495Z" fill="url(#paint4_linear_4297_10435)" />
                    <path d="M80.0001 36.0947V12L39.1707 12L26.1276 14.8582C25.6704 14.9669 25.3477 15.3753 25.3477 15.8452L25.3477 32.2495C25.3477 32.7195 25.6704 33.1279 26.1276 33.2365L39.1707 36.0947L80.0001 36.0947Z" fill="white" />
                    <path d="M80.0001 36.0947V12L39.1707 12L26.1276 14.8582C25.6704 14.9669 25.3477 15.3753 25.3477 15.8452L25.3477 32.2495C25.3477 32.7195 25.6704 33.1279 26.1276 33.2365L39.1707 36.0947L80.0001 36.0947Z" fill="url(#paint5_linear_4297_10435)" />
                    <path d="M80.0001 36.0947V12L39.1707 12L26.1276 14.8582C25.6704 14.9669 25.3477 15.3753 25.3477 15.8452L25.3477 32.2495C25.3477 32.7195 25.6704 33.1279 26.1276 33.2365L39.1707 36.0947L80.0001 36.0947Z" fill="url(#paint6_linear_4297_10435)" />
                    <path d="M51.9429 36.0947L51.9429 12L47.8848 12L47.8848 36.0947H51.9429Z" fill={fill} />
                    <path d="M51.9429 36.0947L51.9429 12L47.8848 12L47.8848 36.0947H51.9429Z" fill="url(#paint7_linear_4297_10435)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_4297_10435" x="-8" y="-7" width="96" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4297_10435" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4297_10435" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_4297_10435" x1="2.07328" y1="22.5172" x2="2.07328" y2="25.5" gradientUnits="userSpaceOnUse">
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.04" />
                    <stop offset="0.85" stopOpacity="0.16" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <linearGradient id="paint1_linear_4297_10435" x1="8.89701" y1="22.2925" x2="8.89701" y2="25.8229" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.56" />
                    <stop offset="0.1" stopOpacity="0.4" />
                    <stop offset="0.2" stopOpacity="0.32" />
                    <stop offset="0.375326" stopOpacity="0" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.32" />
                    <stop offset="0.9" stopOpacity="0.48" />
                    <stop offset="1" stopOpacity="0.72" />
                </linearGradient>
                <linearGradient id="paint2_linear_4297_10435" x1="13.9346" y1="24.0475" x2="8.06344" y2="24.0475" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.1" stopOpacity="0.0796634" />
                    <stop offset="0.35" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_4297_10435" x1="17.6226" y1="17.0279" x2="17.6226" y2="31.1495" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.2" stopOpacity="0.04" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.04" />
                    <stop offset="1" stopOpacity="0.24" />
                </linearGradient>
                <linearGradient id="paint4_linear_4297_10435" x1="27.4989" y1="24.0479" x2="14.8173" y2="24.0479" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.16" />
                    <stop offset="0.05" stopOpacity="0.0796634" />
                    <stop offset="0.15" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint5_linear_4297_10435" x1="37.0148" y1="22.906" x2="34.3517" y2="34.89" gradientUnits="userSpaceOnUse">
                    <stop offset="0.75" stopOpacity="0" />
                    <stop offset="1" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient id="paint6_linear_4297_10435" x1="45.2578" y1="12.1385" x2="45.2578" y2="36.0947" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.24" />
                    <stop offset="0.05" stopOpacity="0.16" />
                    <stop offset="0.2" stopOpacity="0.04" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.04" />
                    <stop offset="0.9" stopOpacity="0.24" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <linearGradient id="paint7_linear_4297_10435" x1="49.9138" y1="12.1385" x2="49.9138" y2="36.0947" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.24" />
                    <stop offset="0.05" stopOpacity="0.16" />
                    <stop offset="0.2" stopOpacity="0.04" />
                    <stop offset="0.4" stopOpacity="0" />
                    <stop offset="0.7" stopOpacity="0.04" />
                    <stop offset="0.9" stopOpacity="0.24" />
                    <stop offset="1" stopOpacity="0.32" />
                </linearGradient>
                <clipPath id="clip0_4297_10435">
                    <rect width="80" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}
