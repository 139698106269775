import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
	headerContainer: {
		marginTop: 8,
		marginBottom: 0,
	},
	headline: {
		fontSize: 12,
		fontWeight: "bold",
		color: "rgba(0, 0, 0, 0.87)",
	},
});

export const ParagraphHeader = ({ children, textAlign = "left" }) => {
	return (
		<View style={{ ...styles.headerContainer, textAlign: textAlign }}>
			<Text style={styles.headline}>{children}</Text>
		</View>
	);
};

ParagraphHeader.propTypes = {
	children: PropTypes.string,
	textAlign: PropTypes.string,
};
