import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
    progressContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerStyles: {
        height: 6,
        width: '100%',
        backgroundColor: '#D32F2F',
        borderRadius: '50%',
    },
    fillerStyles: {
        height: '100%',
        backgroundColor: '#2E7D32',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    labelStyles: {
        fontSize: 10,
        color: "rgba(0, 0, 0, 0.87)",
        marginLeft: 4
    }
});

export const ProgressBar = ({ completed }) => (
    <View style={styles.progressContainer}>
        <View style={styles.containerStyles}>
            <View style={[styles.fillerStyles, { width: `${completed}%` }]} />
        </View>
        <Text style={styles.labelStyles}>{`${completed}%`}</Text>
    </View>
)

ProgressBar.propTypes = {
    completed: PropTypes.number,
}
