import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

MDDNumberLabel.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
};

function MDDNumberLabel(props) {
	const { item, control } = props;

	const getFormattedValue = (value) => {
		let thousandsGroupStyle = null;
		let decimalSeparator = ",";
		let thousandSeparator = ".";

		switch (item.unit_type) {
			case "currency_no_digit":
				thousandsGroupStyle = "thousand";
				break;
			case "currency":
				thousandsGroupStyle = "thousand";
				break;
			case "percent":
				thousandSeparator = null;
				break;
			case "integer":
				break;
		}

		const formattedValue = new Intl.NumberFormat("de-DE", {
			style: "decimal",
			minimumFractionDigits: item.decimal_places || 0,
			maximumFractionDigits: item.decimal_places || 0,
			useGrouping: thousandsGroupStyle !== null,
			grouping: thousandsGroupStyle,
			decimalSeparator,
			thousandsSeparator: thousandSeparator,
		}).format(value);

		return `${formattedValue} ${item.unit || ""}`.trim();
	};

	return (
		<Controller
			name={item.name}
			control={control}
			render={({ field: { value } }) => (
				<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "0px", alignItems: "flex-end" }}>
					<Typography variant="subtitle2">{item.label}</Typography>
					<Typography variant="h4">{getFormattedValue(value || 0)}</Typography>
				</Box>
			)}
		/>
	);
}

export default MDDNumberLabel;
