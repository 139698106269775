import PropTypes from "prop-types";
import { Fragment, useEffect, useRef } from "react";
import { Line, Transformer } from "react-konva";

Triangle.propTypes = {
  shapeProps: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isRubberSelected: PropTypes.bool.isRequired,
  onDeleteShape: PropTypes.func.isRequired,
};

export default function Triangle(props) {
  const { shapeProps, isSelected, onSelect, onChange, isRubberSelected, onDeleteShape } = props;
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleShapeClick = () => {
    if (isRubberSelected) {
      onDeleteShape(shapeRef.current, isSelected);
    } else {
      onSelect();
    }
  };

  const handleDragEnd = (e) => {
    onChange({
      ...shapeProps,
      x: e.target.x(),
      y: e.target.y(),
    });
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
      width: node.width() * scaleX,
      height: node.height() * scaleY,
    });
  };

  return (
    <Fragment>
      <Line
        onMouseOver={(e) => {
          if (e.evt.buttons !== 0 && isRubberSelected) {
            onDeleteShape(shapeRef.current, isSelected);
          }
        }}
        onClick={handleShapeClick}
        ref={shapeRef}
        {...shapeProps}
        draggable={true}
        onTap={handleShapeClick}
        onDblClick={onSelect}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />
      {isSelected && <Transformer ref={trRef} anchorSize={20} rotateEnabled={true} borderDash={[6, 2]} />}
    </Fragment>
  );
}
