import colorLib from "@kurkle/color";
import { useTheme } from "@mui/material/styles";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import PropTypes from "prop-types";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import tinycolor from "tinycolor2";

ChartJS.register(CategoryScale, ArcElement, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const currency_format = (val) => {
	return val
		.toString()
		.replace(".", ",")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const euro_format = (val) => {
	return currency_format(Math.round(val)) + " €";
};
const percent_format = (val) => {
	return val + "%";
};
const transparentize = (value, opacity) => {
	var alpha = opacity === undefined ? 0.5 : 1 - opacity;
	return colorLib(value).alpha(alpha).rgbString();
};

Chart.propTypes = {
	chartDef: PropTypes.object,
};

export default function Chart(props) {
	const theme = useTheme();
	const { chartDef } = props;
	const chartData = chartDef.data;
	let chartKey = "";

	const transparency = 0.25;

	const getRGB = (color) => {
		let hex = color;
		if (hex[0] !== "#") hex = theme.palette[color].main;
		if (!hex) return "";
		const tinyColor = new tinycolor(hex);
		return tinyColor.toRgbString();
	};

	const getXLabels = () => {
		let labels = [];
		let base = chartDef.x_label_base ? parseInt(chartDef.x_label_base) - 1 : 0;
		for (let i = 0; i < chartDef.data[0].value.length; i++) {
			let label = "";
			if (chartDef.label_x || chartDef.x_label_base) label = chartDef.label_x + " " + (base + i + 1);
			labels.push(label);
		}
		return labels;
	};

	const genLabelEuro = (context) => {
		return " " + context.formattedValue + "€";
	};

	const getPluginStruct = (position, labelFunction) => {
		return {
			title: {
				display: false,
				font: {
					family: "Roboto, Helvetica, Arial, sans-serif",
					weight: "400",
					size: 20,
				},
				text: chartDef.label,
			},
			legend: {
				position: position,
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						if (labelFunction) return labelFunction(context);
						else return " " + context.formattedValue;
					},
				},
			},
		};
	};

	const getTicks = () => {
		return {
			callback: function (value, index, values) {
				switch (chartDef.unit) {
					case "€":
						return euro_format(value);
					case "%":
						return percent_format(value);
					default:
						return value;
				}
			},
		};
	};

	const getDoughnut = () => {
		const labels = [];
		const data = [];
		const colors = [];

		for (let i = 0; i < chartData.length; i++) {
			data.push(chartData[i].value);
			colors.push(getRGB(chartData[i].color));
			labels.push(chartData[i].label);
		}
		const doughnutData = {
			labels: labels,

			datasets: [
				{
					data: chartData,
					backgroundColor: colors.map((color) => transparentize(color, transparency)),
					borderColor: "#FFFFFF",
					hoverBackgroundColor: colors,
					borderWidth: 0,
					hoverOffset: 4,
				},
			],
		};
		return (
			<>
				<Doughnut
					id={chartKey}
					data={doughnutData}
					options={{
						aspectRatio: 1,
						responsive: true,
						maintainAspectRatio: true,
						cutout: "70%",
						plugins: getPluginStruct(null),
					}}
					width={chartDef.size || 144}
					height={chartDef.size || 144}
				/>
			</>
		);
	};
	/*
  const getWaterfall = () => {
    const labels = chartData.map((o) => o.label);
    const data = [];
    let last = 0;
    for (let i = 0; i < chartData.length; i++) {
      let calculatedValue = chartData[i].value + last;
      if (!chartData[i]["sum"]) {
        data.push([last, calculatedValue]);
      } else {
        data.push(chartData[i].value);
      }
      last = calculatedValue;
    }

    const colors = data.map((item, index) => {
      switch (index) {
        case 0:
          return getRGB(chartDef.colorBegin);
        case data.length - 1:
          return getRGB(chartDef.colorSum);
        default:
          if (item[0] > item[1]) return getRGB(chartDef.colorMinus);
          else return getRGB(chartDef.colorPlus);
      }
    });

    return (
      <Bar
        id={chartKey}
        data={{
          labels: labels,
          datasets: [
            {
              label: chartDef.label,
              data: data,
              backgroundColor: colors.map((color) => transparentize(color, transparency)),
              borderColor: colors,
              hoverBackgroundColor: colors,
              borderWidth: 2,
              borderRadius: 5,
              borderSkipped: false,
            },
          ],
        }}
        options={{
          responsive: false,
          maintainAspectRatio: true,
          plugins: getPluginStruct(), //false
          scales: {
            x: {
              grid: { display: false },
            },
            y: {
              ticks: getTicksEuro(),
            },
          },
        }}
        width={500}
        height={400}
      />
    );
  };*/

	const getBar = () => {
		const data = [];
		for (const dataset of chartData) {
			data.push({
				data: dataset.values,
				backgroundColor: transparentize(getRGB(dataset.color), transparency),
				borderColor: getRGB(dataset.color),
				hoverBackgroundColor: getRGB(dataset.color),
				borderWidth: 2,
				borderRadius: 5,
			});
		}
		return (
			<Bar
				id={chartKey}
				data={{
					labels: chartDef.labels,
					datasets: data,
				}}
				options={{
					responsive: true,
					maintainAspectRatio: true,
					plugins: getPluginStruct("bottom"),
					scales: {
						x: {
							grid: { display: false },
						},
						y: {
							ticks: getTicks(),
						},
					},
				}}
				width={400}
				height={200}
			/>
		);
	};
	/*
  const getStacked = () => {
    const data = [];

    for (let i = 0; i < chartData.length; i++) {
      data.push({
        label: chartData[i].label,
        data: chartData[i].value,
        borderColor: getRGB(chartData[i].color),
        backgroundColor: transparentize(getRGB(chartData[i].color), transparency),
        hoverBackgroundColor: getRGB(chartData[i].color),
        borderWidth: 2,
        borderRadius: 5,
      });
    }

    return (
      <Bar
        id={chartKey}
        data={{
          labels: getXLabels(),
          datasets: data,
        }}
        options={{
          responsive: false,
          maintainAspectRatio: true,
          scales: {
            x: {
              stacked: true,
              grid: { display: false },
            },
            y: {
              stacked: true,
              ticks: getTicksEuro(),
            },
          },
          plugins: getPluginStruct("bottom"),
        }}
        width={500}
        height={400}
      />
    );
  };

  const getLine = () => {
    const data = [];

    for (let i = 0; i < chartData.length; i++) {
      data.push({
        id: chartData[i].id,
        label: chartData[i].label,
        fill: chartData[i].fill,
        backgroundColor: transparentize(getRGB(chartData[i].color), transparency),
        borderColor: getRGB(chartData[i].color),
        hoverBackgroundColor: getRGB(chartData[i].color),
        borderWidth: 2,
        data: chartData[i].value,
        hidden: chartData[i].hidden,
      });
    }

    return (
      <Line
        id={chartKey}
        data={{
          labels: getXLabels(),
          datasets: data,
        }}
        options={{
          responsive: false,
          maintainAspectRatio: true,
          plugins: getPluginStruct("bottom"),
          scales: {
            x: {
              grid: { display: false },
            },
            y: {
              ticks: getTicksEuro(),
            },
          },
        }}
        width={500}
        height={400}
      />
    );
  };
*/
	const renderChart = (suffix) => {
		chartKey = chartDef.key + suffix;
		switch (chartDef.chart_type) {
			case "doughnut":
				return getDoughnut();
			/*case "waterfall":
        return getWaterfall();*/
			case "bar":
				return getBar();

			/*        case "stacked":
        return getStacked();
      case "line":
        return getLine();*/
			default:
				return <>{"unknown chart type"}</>;
		}
	};

	return <>{renderChart("_view")} </>;
}
