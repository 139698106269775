import { Box, Slider, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { isItemHidden } from "../DMFhelperService";
import InfoDialog from "../components/InfoDialog";
import { Info } from "@mui/icons-material";

MDDSliderInput.propTypes = {
	item: PropTypes.object,
	variant: PropTypes.string,
	control: PropTypes.object,
	size: PropTypes.string,
	trigger: PropTypes.func,
	getValues: PropTypes.func,
	callBack: PropTypes.func,
	disabled: PropTypes.bool,
};

function MDDSliderInput(props) {
	const { item, variant = "outlined", control, size = "small", trigger, getValues, callBack = null, disabled } = props;
	const prevDefaultRef = useRef(item.default);
	const watched = useWatch({ name: item.watch || [undefined], control: control });
	const [hidden, setHidden] = useState(false);

	if (item.update_func) {
		if (item.update_func === "processDeckung") {
			const deckungName = item.name.replace("wert", "deckung_obj").replace("[", ".").replace("]", "");
			const obj = getValues(deckungName);
			if (obj) {
				const deckungDescription = obj.obj;
				if (deckungDescription.field_type === "SliderInput") {
					item.visible = { func: "const", value: "true" };
					item.max = deckungDescription.slider_max || 1000000;
				} else item.visible = { func: "const", value: "false" };
			}
		}
	}

	useEffect(() => {
		setHidden(isItemHidden(item, getValues()));
	}, []);

	useEffect(() => {
		if (watched[0] !== undefined && watched[0] !== "") {
			setHidden(isItemHidden(item, getValues()));
		}
	}, [watched]);

	useEffect(() => {
		if (item.default !== prevDefaultRef.current) {
			prevDefaultRef.current = item.default;
		}
	}, [item.default]);

	const getMinMaxLabel = (value, addPrefixSuffix) => {
		let thousandsGroupStyle = null;
		let decimalSeparator = ",";
		let thousandSeparator = ".";

		switch (item.unit_type) {
			case "currency_no_digit":
				thousandsGroupStyle = "thousand";
				break;
			case "currency":
				thousandsGroupStyle = "thousand";
				break;
			case "percent":
				thousandSeparator = null;
				break;
			case "integer":
				break;
		}

		const formattedValue = new Intl.NumberFormat("de-DE", {
			style: "decimal",
			minimumFractionDigits: item.decimal_places || 0,
			maximumFractionDigits: item.decimal_places || 0,
			useGrouping: thousandsGroupStyle !== null,
			grouping: thousandsGroupStyle,
			decimalSeparator,
			thousandsSeparator: thousandSeparator,
		}).format(value);

		if (addPrefixSuffix) return `${item.prefix || ""} ${formattedValue} ${item.unit || ""} ${item.suffix || ""}`.trim();
		else return `${formattedValue} ${item.unit || ""}`.trim();
	};

	// Debounce-Funktion verwenden
	const handleChangeDebounced = useCallback(
		debounce((newValue) => {
			trigger(item.name);
			if (callBack) {
				callBack();
			}
		}, 500), // 500ms Verzögerung
		[item.name, trigger]
	);

	return (
		<Box sx={{ alignItems: "center", pb: "8px", flexDirection: "column", display: hidden ? "none" : "flex" }}>
			<Controller
				name={item.name}
				control={control}
				defaultValue={item.default || 0}
				render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
					let thousandsGroupStyle = null;
					let decimalSeparator = ",";
					let thousandSeparator = ".";

					const handleChange = (newValue) => {
						onChange(newValue);
						handleChangeDebounced(newValue);
					};

					switch (item.unit_type) {
						case "currency_no_digit":
							thousandsGroupStyle = "thousand";
							break;
						case "currency":
							thousandsGroupStyle = "thousand";
							break;
						case "percent":
							thousandSeparator = null;
							break;
						case "integer":
							break;
					}

					return (
						<Box sx={{ display: "flex", width: "100%", alignItems: "flex-start" }}>
							<Box sx={{ flex: "2", width: "100%", paddingRight: "16px", flexDirection: "row", display: "flex" }}>
								<NumericFormat
									allowNegative={item.allowNegative || false}
									suffix={" " + item.unit || ""}
									thousandsGroupStyle={thousandsGroupStyle}
									decimalSeparator={decimalSeparator}
									thousandSeparator={thousandSeparator}
									customInput={TextField}
									onValueChange={(values) => {
										if (values.floatValue !== undefined) {
											handleChange(values.floatValue);
										}
									}}
									disabled={item.disabled || disabled || false}
									value={value}
									label={item.label_short || item.label}
									variant={variant}
									fullWidth
									size={size}
									helperText={item.label && item.label_short && item.label}
									{...(invalid && { error: true, helperText: error.message })}
									{...{
										InputProps: {
											sx: {
												"& input": {
													textAlign: "right",
												},
											},
										},
									}}
								/>
							</Box>
							<Box sx={{ flex: "3", width: "100%", padding: "0px", marginTop: "0px", paddingRight: "4px" }}>
								<Slider sx={{ padding: "0px" }} disabled={item.disabled || disabled || false} value={value} onChange={(event, newValue) => handleChange(newValue)} min={item.min || 0} max={item.max || 100} step={item.step || 1} />
								<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "4px" }}>
									<Typography variant="body2">{getMinMaxLabel(item.min, false)}</Typography>
									<Typography variant="body2">{getMinMaxLabel(item.max, true)}</Typography>
								</Box>
							</Box>
							<InfoDialog item={item} />
						</Box>
					);
				}}
			/>
		</Box>
	);
}

export default MDDSliderInput;
