import "@fontsource/roboto";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { deDE } from "@mui/x-date-pickers/locales";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import Home from "./pages/Home/Home";
import Start from "./pages/Start/Start";
import Bedarfsermittlung from "./pages/DIN/Bedarfsermittlung";
import Generic from "./pages/Generic/Generic";
import { paletteAtom } from "recoil/atoms/paletteAtom";
import { appPalette, appTheme } from "./theme/theme";
import Leistungspraesentation from "pages/Leistungspraesentation/Leistungspraesentation";
import Sollwerte from "pages/DIN/Sollwerte";
import { useSnackbar } from "notistack";
import { useAuthContext } from "../context/AuthContext";
import Login from "./pages/Login/Login";
import { getAuthToken } from "services/authService";
import DINSpiel from "pages/DINSpiel/DINSpiel";
import Beratungsmappe from "pages/Beratungsmappe/Beratungsmappe";

const App = () => {
	const { user } = useAuthContext();
	const { enqueueSnackbar } = useSnackbar();
	const [palette, setPalette] = useRecoilState(paletteAtom);

	const muiTheme = React.useMemo(() => {
		let theme = { ...appTheme };
		if (palette !== null) {
			theme.palette = palette;
		} else {
			theme.palette = appPalette;
		}
		theme = createTheme(theme, deDE);
		return responsiveFontSizes(theme, {
			breakpoints: ["sm", "md"],
			factor: 2,
			variants: ["h1", "h2", "h3"],
		});
	}, [palette]);

	const checkToken = async () => {
		const authToken = await getAuthToken();
		if (!authToken) {
			enqueueSnackbar("Ihre Anmeldung ist abgelaufen. Bitte neu einloggen.", {
				variant: "error",
			});
			console.error("Token ungültig. Ihre Anmeldung ist abgelaufen. Bitte neu einloggen.");
			return redirect("/login");
		}
		return null;
	};

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path="/login" element={<Login />} />
				<Route path="/home" loader={checkToken} element={<Home />} />
				<Route path="/start" loader={checkToken} element={<Start />} />
				<Route path="/vorstellung_vertrieb" loader={checkToken} element={<Generic />} />
				<Route path="/kunde/:param?" loader={checkToken} element={<Generic />} />
				<Route path="/din_77230" loader={checkToken} element={<Generic />} />
				<Route path="/beratungsmappe" loader={checkToken} element={<Beratungsmappe />} />
				<Route path="/leistungspraesentation" loader={checkToken} element={<Leistungspraesentation />} />
				<Route path="/din_bedarfsermittlung" loader={checkToken} element={<Bedarfsermittlung />} />
				<Route path="/din_sollwerte" loader={checkToken} element={<Sollwerte />} />
				<Route path="/" element={<Login />} />
				<Route path="*" element={<Login />} />
				<Route path="/din_spiel" loader={checkToken} element={<DINSpiel />} />
			</>
		)
	);

	return (
		<ThemeProvider theme={muiTheme}>
			<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<RouterProvider router={router} />
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
