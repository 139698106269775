import { Droppable } from "@hello-pangea/dnd";
import { Box, Typography } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { spielKartenMap } from "services/DINSpielService";

CardContainer.propTypes = {
	cardSolution: PropTypes.string,
	cardsMap: PropTypes.object,
	checkAnswers: PropTypes.bool,
	stufe: PropTypes.string,
};

export default function CardContainer(props) {
	const { cardSolution, cardsMap, checkAnswers, stufe } = props;
	const placedCard = cardsMap[cardSolution];
	const properCard = spielKartenMap[cardSolution];
	return (
		<Droppable droppableId={cardSolution}>
			{(provided, snapshot) => (
				<div ref={provided.innerRef} style={{ width: "152px", height: "220px" }} {...provided.droppableProps}>
					{!placedCard ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								border: "2px dashed #E5CC9C",
								borderRadius: "8px",
								width: "152px",
								height: "220px",
								background: snapshot.isDraggingOver ? "#F0F0F0" : "#FFFFFF",
							}}
						>
							<Typography variant="h6">Stufe</Typography>
							<Typography variant="h6">{stufe}</Typography>
						</Box>
					) : (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "4px",
								border: `1px solid ${checkAnswers ? (placedCard.solution === properCard.solution ? "#2E7D32" : "#D32F2F") : "#E5CC9C"}`,
								bgcolor: "#FFFFFF",
								width: "152px",
								height: "220px",
								borderRadius: "8px",
								position: "relative",
							}}
						>
							<Box sx={{ position: "absolute", right: 0, height: "48px", bgcolor: "#FFFFFF", borderBottomLeftRadius: "9px", borderTopRightRadius: "9px" }}>
								<CustomIcon name={`${checkAnswers ? (placedCard.solution === properCard.solution ? "Work" : "Close") : "QuestionMark"}`} size={48} borderTopRightRadius="9px" borderBottomLeftRadius="9px" />
							</Box>
							<img src={`images/${placedCard.imageName}.jpg`} style={{ width: "150px", height: "95px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
							<Typography variant="subtitle2" sx={{ px: "4px", lineHeight: "18px" }}>
								{placedCard.headerText}
							</Typography>
							<Typography variant="caption" sx={{ px: "4px", lineHeight: "13.11px" }}>
								{placedCard.bodyText}
							</Typography>
						</Box>
					)}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
}
