import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { ProgressBar } from "./ProgressBar";
import { getDisplayName } from "services/formatService";
import { getChart } from "services/chartService";

const styles = StyleSheet.create({
	cardContainer: {
		border: "1px solid gray",
		borderRadius: 8,
		display: "flex",
		flexDirection: "column",
		color: "rgba(0, 0, 0, 0.87)",
	},
	cardTopContainer: {
		borderBottom: "1px solid gray",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
	},
	cardBottomContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	cardLeftContainer: {
		padding: 8,
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
	cardRightContainer: {
		padding: 8,
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
	nameText: {
		fontSize: 14,
	},
	typeText: {
		fontSize: 12,
		color: "rgba(0, 0, 0, 0.60)",
	},
	labelText: {
		fontSize: 8,
		textAlign: "left",
	},
	nameTypeContainer: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
	},
});

export const PersonCard = ({ person, role, werte }) => {
	const getIcon = () => {
		if (role === "Kind") return import.meta.env.VITE_BACKEND + "/LineiconChild.png";
		if (person.geschlecht === "maennlich") return import.meta.env.VITE_BACKEND + "/MaleCard.png";
		return import.meta.env.VITE_BACKEND + "/FemaleCard.png";
	};

	const relevant = werte.filter((wert) => wert.relevant);
	const stufe1 = relevant.filter((wert) => wert.bedarfsstufe === "1");
	const stufe2 = relevant.filter((wert) => wert.bedarfsstufe === "2");

	const chartValuePercentage = Math.round(relevant.reduce((acc, curr) => acc + curr.ist_percent, 0) / relevant.length);
	const firstProgressPercentage = Math.round(stufe1.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe1.length);
	const secondProgressPercentage = Math.round(stufe2.reduce((acc, curr) => acc + curr.ist_percent, 0) / stufe2.length);

	return (
		<View style={styles.cardContainer}>
			<View style={styles.cardTopContainer}>
				<View style={{ alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
					{getChart("bewertung_" + Math.round(chartValuePercentage, 0) + "_view", { width: 40, height: 40, marginRight: 5 })}

					<View style={{ position: "absolute" }}>
						<Text style={{ fontSize: 14, color: "rgba(0, 0, 0, 0.60)", marginLeft: -3 }}>{chartValuePercentage}%</Text>
					</View>
				</View>
				<View style={styles.nameTypeContainer}>
					<Text style={styles.nameText}>{getDisplayName(person)}</Text>
					<Text style={styles.typeText}>{role}</Text>
				</View>
				<Image src={getIcon()} style={{ width: 40, marginLeft: "auto" }} />
			</View>
			<View style={styles.cardBottomContainer}>
				<View style={styles.cardLeftContainer}>
					<ProgressBar completed={firstProgressPercentage} />
					<Text style={styles.labelText}>Sicherung des finanziellen Grundbedarfs</Text>
				</View>
				<View style={styles.cardRightContainer}>
					<ProgressBar completed={secondProgressPercentage} />
					<Text style={styles.labelText}>Erhaltung des Lebensstandards</Text>
				</View>
			</View>
		</View>
	);
};

PersonCard.propTypes = {
	person: PropTypes.object,
	role: PropTypes.string,
	werte: PropTypes.array,
};
