import { Typography, Box, Chip } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Spinner from "components/Spinner";
import Tile from "components/Tile";
import BackendLayout from "layouts/BackendLayout";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { userAtom } from "recoil/atoms/userAtom";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { backendGet } from "services/dataService";
import KundeList from "./components/KundeList";

export default function Home() {
	const [dashboardData, setDashboardData] = useState(null);
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const [user, setUser] = useRecoilState(userAtom);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const appWindow = useRecoilValue(appWindowAtom);
	const navWidth = 350;
	const setAndInitWorkflow = (data) => {
		const module_first = data.modules[0];
		setWorkflow({ workflow: data, active_module: module_first.uuid, active_submodule: module_first.submodules[0]?.uuid || null });
	};

	const setDashboardResponse = (data) => {
		setDashboardData({
			kunden: data.kunden,
			vertragsaufteilung: data.vertragsaufteilung,
		});
		setUser(data.user);
	};

	useEffect(() => {
		backendGet({ label: "Kunden", setIsLoading: setIsLoading, route: "dashboard", setResult: setDashboardResponse, enqueueSnackbar: enqueueSnackbar });
		backendGet({ label: "Beratungsprozess", setIsLoading: setIsLoading, route: "workflow/1", setResult: setAndInitWorkflow, enqueueSnackbar: enqueueSnackbar });
	}, []);

	return (
		<BackendLayout>
			{isLoading && <Spinner />}
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
				<Tile sx={{ width: navWidth + "px", padding: "0px", mr: "16px", height: appWindow.contentHeight + "px" }}>
					<Box
						sx={{
							mb: "4px",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: "8px",
							borderStyle: "solid",
							borderWidth: "1px",
							borderColor: "#E6E6E6",
							borderRadius: "8px",
							backgroundColor: "rgba(62, 79, 147, 0.08)",
						}}
					>
						<CustomIcon name="Clientprofile" size={48} />
						<Typography variant="body2">Kunden</Typography>
					</Box>
					<Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px", cursor: "pointer" }} onClick={() => navigate("/beratungsmappe")}>
						<CustomIcon name="Presentation" size={48} />
						<Typography variant="body2">Beratungsmappe</Typography>
					</Box>
					<Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px", opacity: "0.6" }}>
						<CustomIcon name="Calculator" size={48} />
						<Typography variant="body2">Rechner</Typography>
						<Box>
							<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
						</Box>
					</Box>
					<Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px", opacity: "0.6" }}>
						<CustomIcon name="Comments" size={48} />
						<Typography variant="body2">News</Typography>
						<Box>
							<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
						</Box>
					</Box>
					<Box sx={{ mb: "4px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "8px", opacity: "0.6" }}>
						<CustomIcon name="CardGame" size={48} />
						<Typography variant="body2">DIN-Spiel</Typography>
						<Box>
							<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
						</Box>
					</Box>
				</Tile>

				<Tile sx={{ display: "flex", flexGrow: "0", padding: "16px", margin: "0px", height: appWindow.contentHeight + "px" }}>
					<KundeList data={dashboardData?.kunden.liste} />
				</Tile>
			</Box>
		</BackendLayout>
	);
}

/*
onClick={() => navigate("/din_spiel")}
 */
