import { Box, Button, Typography, IconButton, Chip } from "@mui/material";
import DMTable from "components/DMTable";
import { containerRendererFlex, getEmptyModel, itemRendererFlex } from "components/FormRenderer/DMFhelperService";
import FormRenderer from "components/FormRenderer/FormRenderer";
import Tile from "components/Tile";
import { fdefVertrag } from "formdefs/vertrag";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { backendGet, backendGetSync, backendPost } from "services/dataService";
import { getEuro } from "services/formatService";
import CustomIcon from "./CustomIcon";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { fdefAnteil } from "formdefs/anteil";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";

Vertragsspiegel.propTypes = {
	hideTitle: PropTypes.bool,
	addCallback: PropTypes.func,
	activeRow: PropTypes.object,
	isSimulation: PropTypes.bool,
};

export default function Vertragsspiegel(props) {
	const { hideTitle = false, addCallback = null, activeRow = null, isSimulation = false } = props;
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const [vertraege, setVertraege] = useState([]);
	const [vertrag, setVertrag] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const appWindow = useRecoilValue(appWindowAtom);
	const [formValues, setFormValues] = useState({});
	const [anteilValues, setAnteilValues] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [vertragszuordnung, setVertragszuordnung] = useRecoilState(vertragszuordnungAtom);

	useEffect(() => {
		backendGet({ label: "Kunden", route: "vertrag/haushalt/" + haushalt.kunde.haushalt_id, setResult: setVertragsspiegelData, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	}, [haushalt]);

	const setVertragsspiegelData = (data) => {
		setVertraege(data);
	};

	const valuesCallBack = useCallback((values, setValue) => {
		setFormValues(values);
	}, []);

	const anteilCallback = useCallback((values, setValue) => {
		setAnteilValues(values);
	}, []);

	const getVersicherungsnehmer = async (model) => {
		return await backendGetSync({ label: "Versicherungsnehmer", route: "values/versicherungsnehmer/" + model.haushalt_id, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getVersichertePersonen = async (model) => {
		return await backendGetSync({ label: "Versicherte Personen", route: "values/versicherte_personen/" + model.haushalt_id, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getProduktgeber = async (model) => {
		return await backendGetSync({ label: "Produktgeber", route: "values/produktgeber", setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getSparten = async (model) => {
		return await backendGetSync({ label: "Sparten", route: "values/sparten/" + model.produktgeber, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getProdukte = async (model) => {
		return await backendGetSync({ label: "Produkte", route: "values/produkte/" + model.sparte, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const getDeckungen = async (model) => {
		const result = await backendGetSync({ label: "Deckung", route: "values/deckungen/" + model.produkt, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
		return result;
	};

	const memoizedFormRenderer = useMemo(
		() => (
			<FormRenderer
				containerRenderer={containerRendererFlex}
				itemRenderer={itemRendererFlex}
				fdef={fdefVertrag}
				variant="filled"
				size="small"
				valuesCallBack={valuesCallBack}
				editMode={editMode}
				model={vertrag}
				services={{
					versicherungsnehmerService: getVersicherungsnehmer,
					versichertePersonenService: getVersichertePersonen,
					produktgeberService: getProduktgeber,
					spartenService: getSparten,
					produkteService: getProdukte,
					deckungService: getDeckungen,
				}}
			/>
		),
		[valuesCallBack, vertrag, editMode]
	);

	const storeVertrag = async () => {
		backendPost({ label: "Vertrag", route: "vertrag/store", toSend: formValues, setResult: setSubmitResult, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const setSubmitResult = (data) => {
		let index;
		let updatedVertraege;
		switch (data.updateInfo.action) {
			case "insert":
				setVertraege([...vertraege, data.updateInfo.data]);
				break;
			case "update":
				index = vertraege.findIndex((element) => element.id === data.updateInfo.id);
				if (index !== -1) {
					updatedVertraege = [...vertraege];
					updatedVertraege[index] = data.updateInfo.data;
					setVertraege(updatedVertraege);
				}
				break;
			default:
		}
		setVertrag(null);
		setEditMode(false);
	};

	const handleSetVertrag = (row) => {
		setEditMode(false);
		setVertrag(null);
		setTimeout(() => {
			setVertrag({ ...row, objProduktgeber: row.produktgeber, produktgeber: row.produktgeber?.id, produkt: row.produkt?.id, versicherungsnehmer: row.versicherungsnehmer_id, haushalt_id: haushalt.kunde.haushalt_id });
		}, 0);
	};

	const handleCreateVertrag = () => {
		setVertrag(null);
		setTimeout(() => {
			const emptyModel = getEmptyModel(fdefVertrag.formdef);
			emptyModel.haushalt_id = haushalt.kunde.haushalt_id;
			setVertrag(emptyModel);
			setEditMode(true);
		}, 0);
	};

	const columnsDefiniton = [
		{
			id: "produktgeberName",
			accessorFn: (row) => `${row.produktgeber.name} $(row.produkt?.name}`,
			header: "Vertrag",
			size: 180,
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
							<img src={`pg_logos/${objectToRender.produktgeber?.logo}`} alt="" style={{ width: "40px" }} />
						</Box>
						<Box>
							<Typography variant="body1">{objectToRender.produkt?.produkt}</Typography>
							<Typography variant="body2">{objectToRender.produktgeber?.name}</Typography>
						</Box>
					</Box>
				);
			},
			sortingFn: (rowA, rowB) => {
				const nameA = rowA.original.produktgeber.name.toLowerCase();
				const nameB = rowB.original.produktgeber.name.toLowerCase();
				return nameA.localeCompare(nameB);
			},
		},
		{
			header: "Beitrag",
			size: 60,
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box>
						{objectToRender.vertragsart === "Simulationsvertrag" && (
							<Box sx={{ textAlign: "right" }}>
								<Chip color={"Info"} label={"Sim"} variant="filled" sx={{ m: "2px", p: "0px", height: "16px", fontSize: "12px", borderRadius: "4px" }} />
							</Box>
						)}
						<Typography variant="body1">{getEuro(objectToRender.beitrag_gem_zahlweise)}</Typography>
						<Typography variant="body2">{objectToRender.zahlweise}</Typography>
					</Box>
				);
			},
		},
	];

	const fnRenderDetailPanel = (row) => {
		let luecke = "";
		if (typeof activeRow?.sollwert === "string" && activeRow?.ist_wert !== activeRow?.sollwert) luecke = activeRow?.sollwert;
		else luecke = activeRow?.sollwert - activeRow?.ist_wert;
		if (row.original.produkt.sparte === "Krankenversicherung" || row.original.produkt.sparte === "Krankenversicherung (gesetzlich)") {
			return (
				<Box
					sx={{
						alignItems: "left",
						left: "30px",
						maxWidth: "1000px",
						position: "sticky",
						width: "100%",
					}}
				>
					{isSimulation || row.original.vertragsart !== "Simulationsvertrag" ? (
						<Button
							sx={{ fontSize: "13px", fontWeight: 400, lineHeight: "13px", pr: "8px", pl: "8px", pt: "4px", pb: "4px", height: "30px", textTransform: "none" }}
							variant="outlined"
							onClick={() => addCallback(row.original, "Krankenversicherung", "***Krankenversicherung***")}
						>
							hinzufügen
						</Button>
					) : (
						<Box>Simulationsvertrag - Verwendung nicht möglich</Box>
					)}
				</Box>
			);
		}
		return (
			<Box
				sx={{
					alignItems: "left",
					left: "30px",
					maxWidth: "1000px",
					position: "sticky",
					width: "100%",
				}}
			>
				{row.original.deckungen.map((deckung, index) => {
					const verfuegbar = deckung.wert - vertragszuordnung.filter((zuordnung) => zuordnung.uuid_deckung === deckung.uuid).reduce((summe, zuordnung) => summe + zuordnung.din_wert, 0);

					return (
						<Box key={index}>
							<Typography variant="body2">
								{deckung.deckung}: {getEuro(deckung.wert)}
							</Typography>
							<FormRenderer
								containerRenderer={containerRendererFlex}
								itemRenderer={itemRendererFlex}
								fdef={{
									...fdefAnteil,
									formdef: fdefAnteil.formdef.map((item) => ({
										...item,
										max: Math.min(deckung.wert, luecke, verfuegbar),
									})),
								}}
								variant="filled"
								size="small"
								valuesCallBack={anteilCallback}
								editMode={true}
								model={{ wert: 0 }}
							/>
							{isSimulation || row.original.vertragsart !== "Simulationsvertrag" ? (
								<Button
									sx={{ fontSize: "13px", fontWeight: 400, lineHeight: "13px", pr: "8px", pl: "8px", pt: "4px", pb: "4px", height: "30px", textTransform: "none" }}
									variant="outlined"
									onClick={() => addCallback(row.original, anteilValues.wert, deckung.uuid)}
								>
									hinzufügen
								</Button>
							) : (
								<Box>Simulationsvertrag - Verwendung nicht möglich</Box>
							)}
						</Box>
					);
				})}
			</Box>
		);
	};

	const tableProps = {
		data: vertraege,
		columns: columnsDefiniton,
		rowAction: handleSetVertrag,
		searchword: "",
		initialStateConfig: {
			sorting: [{ id: "produktgeberName", desc: false }],
		},
		handleCreate: () => handleCreateVertrag(),
		labelNeu: "Vertrag",
		showExport2Excel: false,
		...(addCallback && { fnRenderDetailPanel: fnRenderDetailPanel }),
	};

	return (
		<Box sx={{ width: "100%", padding: "0px" }}>
			<Box sx={{ display: "flex", height: "100%" }}>
				<Tile sx={{ width: "40%", mr: "24px" }}>
					{!hideTitle && <Typography variant="h6">Vertragsspiegel</Typography>}
					<DMTable {...tableProps} />
				</Tile>

				{vertrag ? (
					<Tile
						sx={{
							width: "60%",
						}}
					>
						{!editMode ? (
							<Fragment>
								<Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", margin: "0px", pb: "32px" }}>
									<Typography variant="h6" sx={{ pb: "16px" }}>
										Vertragsdetails
									</Typography>
									<IconButton onClick={() => setEditMode(true)}>
										<CustomIcon name="EditFilled" size={24} />
									</IconButton>
								</Box>
								<Box sx={{ height: appWindow.contentHeight - 96 + "px", overflowY: "auto", padding: "20px" }}>{memoizedFormRenderer}</Box>
							</Fragment>
						) : (
							<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
								<Box
									sx={{
										backgroundColor: "rgba(62, 79, 147, 0.08)",
										padding: "16px",
										alignItems: "center",
										display: "flex",
										justifyContent: "space-between",
										margin: "0px",
									}}
								>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												marginRight: "8px",
											}}
										>
											<img src={`pg_logos/${vertrag.objProduktgeber?.logo}`} alt="" style={{ width: "40px" }} />
											<Typography variant="body1" sx={{ ml: "8px" }}>
												{vertrag.sparte}
											</Typography>
										</Box>
										<Typography variant="body2">{vertrag.objProduktgeber?.name}</Typography>
									</Box>
									<Box>
										<Button
											variant="outlined"
											onClick={() => {
												setVertrag(null);
												setEditMode(false);
											}}
										>
											Abbrechen
										</Button>
										<Button variant="contained" sx={{ ml: "16px", textTransform: "none" }} onClick={() => storeVertrag()}>
											Speichern
										</Button>
									</Box>
								</Box>
								<Box
									sx={{
										flexGrow: 1,
										overflowY: "auto",
										padding: "0px",
									}}
								>
									{memoizedFormRenderer}
								</Box>
							</Box>
						)}
					</Tile>
				) : (
					<Tile
						sx={{
							width: "60%",
							textAlign: "center",
							display: "flex",

							flexDirection: "column",
							justifyContent: "center",

							alignItems: "center",
						}}
					>
						<CustomIcon name="HealthChecklist" size={48} />
						<Typography variant="h6">Wählen Sie einen Vertrag aus, um Details anzuzeigen...</Typography>
					</Tile>
				)}
			</Box>
		</Box>
	);
}
