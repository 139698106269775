export const sollwerte = [
	{
		rang: 1,
		finanzthema: "Kostenrisiko Krankheit Grundschutz",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Der medizinische Grundschutz wird bei Abschluss einer gesetzlichen Krankenversicherung, privaten Krankenvollversicherung, freie Heilfürsorge oder einer privaten Krankenversicherung mit Beihilfe geboten. Eine Krankenversicherung ist in Deutschland gesetzlich vorgeschrieben.",
	},
	{
		rang: 2,
		finanzthema: "Allgemeines Haftungsrisiko",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Schäden, die an Sachgegeständen von Dritten oder an Personen verursacht wurden, müssen lt. § 823 BGB vollumfänglich ersetzt werden. Mithilfe einer privaten Haftpflichtversicherung, wird dieses Risiko abegedeckt. Besondere oder spezielle Risiken müssen durch Deckungserweiterungen abgedeckt werden.",
	},
	{
		rang: 3,
		finanzthema: "Arbeitskraftverlust Erwerbsunfähigkeit (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Dauerhafte Krankheits- oder Unfallbedingte Einkommensausfälle müssen abgedeckt werden, zur Sicherung des finanziellen Grundbedarfs oder auch zum Erhalt des Lebensstandards. Die Absicherung kann durch verschiedene gesetzliche Versorgungssysteme eingerichtet werden: Gesetzliche Rentenversicherung, Berufsständische Versorgungswerke, Beamtenversorgung, Renten aus betrieblicher Versorgung oder Renten aus privater Vorsorge. Empfohlen wird eine Versicherung für den Fall der Erwerbsunfähigkeit.",
	},
	{
		rang: 4,
		finanzthema: "Arbeitskraftverlust Berufs-/Dienstunfähigkeit (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Dauerhafte Krankheits- oder Unfallbedingte Einkommensausfälle müssen abgedeckt werden, zur Sicherung des finanziellen Grundbedarfs oder auch zum Erhalt des Lebensstandards. Die Absicherung kann durch verschiedene gesetzliche Versorgungssysteme eingerichtet werden: Gesetzliche Rentenversicherung, Berufsständische Versorgungswerke, Beamtenversorgung, Renten aus betrieblicher Versorgung oder Renten aus privater Vorsorge. Empfohlen wird eine Berufs-/Dienstunfähigkeitsversicherung.",
	},
	{
		rang: 5,
		finanzthema: "Arbeitskraftverlust Arbeitsunfähigkeit (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Bei der Arbeitsunfähigkeit wird kein Erwerbseinkommen erwirtschaftet. Für diesen Fall bieten die Arbeitgeber eine Entgeltfortzahlung bis zu 6 Wochen an. Ab dem 43. Krankheitstag zahlt die gesetzliche Krankenversicherung das Krankengeld/ die private Krankenversicherung das Krankentagegeld. Zur Abdeckung des verbleibenden Einkommensausfall, wird empfohlen, eine Krankentagegeldversicherung abzuschließen.",
	},
	{
		rang: 6,
		finanzthema: "Kostenrisiko Pflegebedürftigkeit (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Der Abschluss einer gesetzlichen Pflegepflichtversicherung wird in Deutschland vorgeschrieben. Die gesetzliche Pflegepflichtversicherung deckt im Pflegefall den Mindestbedarf ab. Ein Eigenanteil ist Pflegefall dennoch zu leisten.",
	},
	{
		rang: 7,
		finanzthema: "todesfallbedingte finanzielle Einbußen (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Partner*in und ggf. Kinder"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Sollte der Todesfall einer wirtschaftlich unabhängigen Person innerhalb des Hauslhaltes eintreffen, kann es für die anderen Personen des Haushalten finanzielle Einbußen geben. Dieses Risiko sollte abgesichert sein, wenn eine Partnerschaft eingegangen wurde, durch eine Heirat der Ehebund eingegangen wurde oder Kinder bzw. wirtschaflich abhängige Personen innerhalb des Haushaltes wohnen. ",
	},
	{
		rang: 8,
		finanzthema: "Haftungsrisiko Kraftfahrzeuge",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Mobilität und Reisen"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Alle eventuellen Personen-, Sach- oder Vermögensschäden müssen bei Haltern von Kraftfahrzeugen abgedeckt werden. Das Absichern der eventuellen Schäden ist für alle Fahrer des Fahrzeugs zu leisten. In Deutschland ist eine Kfz-Haftpflichtversicherung vorgeschrieben.",
	},
	{
		rang: 9,
		finanzthema: "Haftungsrisiko private Tierhaltung",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Alle eventuellen Personen-, Sach- oder Vermögensschäden, die durch eines der Tiere verursacht werden, müssen bei Hunde- oder Pferdebesitzer abgedeckt werden. ",
	},
	{
		rang: 10,
		finanzthema: "Haftungsrisiko Haus-und Grundbesitz",
		bedarfsfeld_vertrieb: "Haus & Familie",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Für Eigentümer von eigen- oder fremgenutzten Immobilien, sowie umgebauten Grundstücken besteht ein Haftungsrisiko für eventuelle Personen-, Sach- oder Vermögensschaden, die durch die Immobilie verursacht wurde. Das Risiko wird durch eine Haus- und Grunsbestitzer-Haftpflichtversicherung abgesichert.",
	},
	{
		rang: 11,
		finanzthema: "Haftungsrisiko Bau und Sanierung",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Alle Baumaßnahmen in Form von Neubau, Sanierung und Umbau der Immobilie können je Bauvorhaben Personen-, Sach- oder Vermögensschäden verursachen. Um dieses Haftungsrisiko abzusichern, wird eine Bauherrenhaftpflichtversicherung empfohlen.",
	},
	{
		rang: 12,
		finanzthema: "Haftungsrisiko Gewässerschäden (Heizöltank o. ä.)",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Eigentümer eines Heizöltanks oder einer Anlage mit wassergefährdenden Stoffen haften für eventuelle Personen-, Sach- oder Vermögensschäden, die bspw. Durch das Auslaufen des Öltanks verursacht werden. Durch eine Gewässerschaden-Haftpflichtversicherung wird dieses Haftungsrisiko abgesichert.",
	},
	{
		rang: 13,
		finanzthema: "Haftungsrisiko Photovoltaik-Anlage",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Jeder Besiter/Betreiber einer Photovoltaikanlage haftet für eventuelle Personen-, Sach- oder Vermögensschäden, die durch den Betrieb der Anlage verursacht werden. Zur Absicherung dieses Risikos muss eine Betreiberhaftpflichtversicherung abgeschlossen werden.",
	},
	{
		rang: 14,
		finanzthema: "Haftungsrisiko Jagd",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Für eventuelle Personen-, Sach- oder Vermögenschäden, die durch das Ausüben des Jagdsportes verursacht werden, ist der Betreiber des Jagdsportes haftbar. Die Absicherung dieses Risikos geschieht durch den Abschluss einer Jagdhaftpflichtversicherung.",
	},
	{
		rang: 15,
		finanzthema: "Haftungsrisiko Luftfahrzeuge (inkl. ferngesteuerte Flugmodelle und Drohnen)",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Mobilität und Reisen"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Eigentümer von Luftfahrzeugen haften für eventuelle Personen-, Sach- oder Vermögensschäden, welche durch den Betrieb der Luftfahrzeuge entstehen. Die Absicherung der Schäden ist für alle Personen zu leisten, die das Luftfahrzeug fliegen.",
	},
	{
		rang: 16,
		finanzthema: "Haftungsrisiko aus besonderer ausgeübter Tätigkeit und Ehrenamt",
		bedarfsfeld_vertrieb: "Persönliche Angaben",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "-",
	},
	{
		rang: 17,
		finanzthema: "Haftungsrisiko Wasserfahrzeuge",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Mobilität und Reisen"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Benutzer von Wassersportgeräten haften für eventuelle Personen-, Sach- oder Vermögensschäden, die durch das Wasserfahrzeug verursacht wurden. Durch eine Wasserfahrzeug-Haftpflichtversicherung wird das Haftungsrisiko abgesichert.",
	},
	{
		rang: 18,
		finanzthema: "Verhinderung der kurzfristig drohenden Zahlungsunfähigkeit",
		bedarfsfeld_vertrieb: "Vermögensaufbau/-optimierung",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Liquidität und Vermögensbilanz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Durch das Schaffen eine ausreichenden Liquiditätsreserve, werden unvorhergesehene finanzielle Engpässe oder kurzfristige Einnahmeausfälle überbrückt und es müssen keine Absicherungs- und Vorsorgemaßnahmen ausgesetzt werden. Hierfür können kurzfristig zur Verfügung stehende, zweckgebundene und angesparte Vermögenswerte oder auch freie, nicht ausgenutzte Dispositionskreditlinien verwendet werden. Diese verursachen allerdings Kostenrisiken durch hohe Zinsen.",
	},
	{
		rang: 19,
		finanzthema: "Schuldenrisiko aus Dispositions- und Konsumentenkrediten",
		bedarfsfeld_vertrieb: "Vermögensaufbau/-optimierung",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Liquidität und Vermögensbilanz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Um eine Überschuldung des Haushaltes durch hohe Zinsen und kurze Laufzeiten von Konsumenten- und Dispositionskrediten zu verhinden, sollten die Schulden nie mehr als das 10-fache Nettogesamteinkommen übersteigen. Falls durch besondere Situationen eine Zurückführung der Kredite nicht möglich sein sollte, muss frühzeitig einer Überschuldung entgegengewirkt werden durch Kreditrückführung, Umfinanzierung o. Ä..",
	},
	{
		rang: 20,
		finanzthema: "Altersvorsorge (Grundschutz)",
		bedarfsfeld_vertrieb: "Altersvorsorge",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Vorsorge für das Alter", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Es wird empfohlen eine private Altersvorsorge abzuschließen, um das Kostenrisiko der fehlenden Altervorsorge zu minimieren. Für die Altersvorsorge gibt es verschiedene Angebote, bspw. durch den Gesetzgeber, den Betrieb oder auch aus privaten Ansprüchen.",
	},
	{
		rang: 21,
		finanzthema: "Risiko des Verlustes/der Beschädigung einer Immobilie",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Der Verlust oder die Beschädigung einer Immobilie, stellt für den Eigentümer der Immobilie ein hohes finanzielles Risiko dar. Zur Gewährletistung des Wiederaufbaus im neuwertigen Zustand wird eine gleitende Neuwertversicherung mit Unterversicherungsverzichtsklausel empfohlen. In der Regel umfasst der Versicherungsschutz folgende Gefahren: Feuer-, Leitungswasser-, Sturm-/Hagel- und ggf. Elementarschäden. Für weitere mögliche Schäden müssen zusätzlich Versicherungen abgeschlossen werden.",
	},
	{
		rang: 22,
		finanzthema: "Kostenrisiko Krankheit Ausland",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vorsorge",
		bedarfsstufe: "1",
		bedarfsfeld_din: ["Krankheit und Pflege", "Mobilität und Reisen"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Innerhalb der gesetzlichen oder einer privaten Kankenversicherungen können Leistungen begrenzt sein. Sollten Leistungen im Ausland finanziell über dem gesetzlichen bzw. vertraglichen Stand liegen, müssen Zuzahlungen erbracht werden, weshalb bei Reisen ins Ausland eine private Auslandskrankenverscherung mit Einschluss des Krankenrücktransportes empfohlen wird.",
	},
	{
		rang: 23,
		finanzthema: "Arbeitskraftverlust Erwerbsunfähigkeit",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Dauerhafte Krankheits- oder Unfallbedingte Einkommensausfälle müssen abgedeckt werden, zur Sicherung des finanziellen Grundbedarfs oder auch zum Erhalt des Lebensstandards. Die Absicherung kann durch verschiedene gesetzliche Versorgungssysteme eingerichtet werden: Gesetzliche Rentenversicherung, Berufsständische Versorgungswerke, Beamtenversorgung, Renten aus betrieblicher Versorgung oder Renten aus privater Vorsorge. Empfohlen wird eine Versicherung für den Fall der Erwerbsunfähigkeit.",
	},
	{
		rang: 24,
		finanzthema: "Arbeitskraftverlust Berufs-/Dienstunfähigkeit",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Dauerhafte Krankheits- oder Unfallbedingte Einkommensausfälle müssen abgedeckt werden, zur Sicherung des finanziellen Grundbedarfs oder auch zum Erhalt des Lebensstandards. Die Absicherung kann durch verschiedene gesetzliche Versorgungssysteme eingerichtet werden: Gesetzliche Rentenversicherung, Berufsständische Versorgungswerke, Beamtenversorgung, Renten aus betrieblicher Versorgung oder Renten aus privater Vorsorge. Empfohlen wird eine Berufs-/Dienstunfähigkeitsversicherung.",
	},
	{
		rang: 25,
		finanzthema: "Arbeitskraftverlust Arbeitsunfähigkeit",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Arbeitskraftverlust"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Bei der Arbeitsunfähigkeit wird kein Erwerbseinkommen erwirtschaftet. Für diesen Fall bieten die Arbeitgeber eine Entgeltfortzahlung bis zu 6 Wochen an. Ab dem 43. Krankheitstag zahlt die gesetzliche Krankenversicherung das Krankengeld/ die private Krankenversicherung das Krankentagegeld. Zur Abdeckung des verbleibenden Einkommensausfall, wird empfohlen, eine Krankentagegeldversicherung abzuschließen.",
	},
	{
		rang: 26,
		finanzthema: "todesfallbedingte finanzielle Einbußen (Grundschutz)",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Partner*in und ggf. Kinder"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Sollte der Todesfall einer wirtschaftlich unabhängigen Person innerhalb des Hauslhaltes eintreffen, kann es für die anderen Personen des Haushalten finanzielle Einbußen geben. Dieses Risiko sollte abgesichert sein, wenn eine Partnerschaft eingegangen wurde, durch eine Heirat der Ehebund eingegangen wurde oder Kinder bzw. wirtschaflich abhängige Personen innerhalb des Haushaltes wohnen. ",
	},
	{
		rang: 27,
		finanzthema: "Aufbau Liquiditätsreserve",
		bedarfsfeld_vertrieb: "Vermögensaufbau/-optimierung",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Liquidität und Vermögensbilanz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Durch das Schaffen eine ausreichenden Liquiditätsreserve, werden unvorhergesehene finanzielle Engpässe oder kurzfristige Einnahmeausfälle überbrückt und es müssen keine Absicherungs- und Vorsorgemaßnahmen ausgesetzt werden. Hierfür können kurzfristig zur Verfügung stehende, zweckgebundene und angesparte Vermögenswerte oder auch freie, nicht ausgenutzte Dispositionskreditlinien verwendet werden. Diese verursachen allerdings Kostenrisiken durch hohe Zinsen.",
	},
	{
		rang: 28,
		finanzthema: "Invalidität/ Erwerbsunfähigkeit/ Berufsunfähigkeit von Kindern",
		bedarfsfeld_vertrieb: "Haus & Familie",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Partner*in und ggf. Kinder"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Durch Invalidität/Erwerbsunfähigkeit oder auch Berufsunfähigkeit der eigenen Kinder, können für die Kinder finanzielle Einbußen entstehen. Dieses Risiko kann abgesichert werden.",
	},
	{
		rang: 29,
		finanzthema: "Altersvorsorge",
		bedarfsfeld_vertrieb: "Altersvorsorge",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Vorsorge für das Alter", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Es wird empfohlen eine private Altersvorsorge abzuschließen, um das Kostenrisiko der fehlenden Altervorsorge zu minimieren. Für die Altersvorsorge gibt es verschiedene Angebote, bspw. durch den Gesetzgeber, den Betrieb oder auch aus privaten Ansprüchen.",
	},
	{
		rang: 30,
		finanzthema: "Zinsänderungsrisiko bei Immobilienfinanzierungen",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Haus und Wohnung", "Liquidität und Vermögensbilanz"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Durch die Zinsänderung bei der Imobilienfinanzierung kann eine ungeplante Überschuldung entstehen. Um das Risiko abzusichern, können weitere Sparmaßnahmen parallel getroffen werden. ",
	},
	{
		rang: 31,
		finanzthema: "Kostenrisiko Pflegebedürftigkeit",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Der Abschluss einer gesetzlichen Pflegepflichtversicherung wird in Deutschland vorgeschrieben. Die gesetzliche Pflegepflichtversicherung deckt im Pflegefall den Mindestbedarf ab. Der verbleibende Eigenateil sollte abgesichert werden, um bspw. freie Heimwahl zu gewährleisten oder eine finanzielle Inanspruchnahme von Angehörigen zu verhindern. Empfohlen wird eine private Pflegezusatzversicherung",
	},
	{
		rang: 32,
		finanzthema: "Risiko des Verlustes/der Beschädigung von Hausrat",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Sollte der Fall einer Beschädigung oder der Verlust des Hausrats eintreten, so kann ein finanzielles Risiko entstehen. Zur Absicherung für Schäden durch Feuer, Leitungswasser, Sturm, Hagel, Einbruchdiebstahl, Raub, Vandalismus und ggf. Elemtarschäden wird eine Hausratsversicherung mit Unterversicherungverzicht empfohlen.",
	},
	{
		rang: 33,
		finanzthema: "Instandhaltung Immobilien",
		bedarfsfeld_vertrieb: "Haus & Familie",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Krankheit und Pflege", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Die Abnutzung der selbst- und fremdgenutzten Immobilien verursacht Aufwendungen zur Instandhaltung und -setzung. Hierfür sollte eine Rücklage geschaffen werden. Ebenfalls sollte die Instandhaltungsrücklage berücksichtigen, dass ggf.  Neu- oder Anbauten geplant werden. Größere Investitionen werden nicht über die Instandhaltungsrücklage abgedeckt.",
	},
	{
		rang: 34,
		finanzthema: "Kostenrisiko Krankheit aus stationären Zusatzleistungen",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Innerhalb der gesetzlichen oder einer privaten Kankenversicherungen können Leistungen begrenzt sein. Sollten Leistungen finanziell über dem gesetzlichen bzw. vertraglichen Stand liegen, müssen Zuzahlungen erbracht werden, weshalb zur Absicherung im stationären Bereich eine private Krankenzusatzverscherung oder eine Krankenvollversicherung empfohlen wird.",
	},
	{
		rang: 35,
		finanzthema: "Risiko von nicht gedeckten Folgekosten nach Unfall und Krankheit",
		bedarfsfeld_vertrieb: "Sicherung des Einkommens",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Es besteht ein Risiko eines finanziellen Bedarfs durch bleibende Schäden nach einem Unfall oder auch durch schwere Krankheiten. Der Bedarf  (bspw. Umbaumaßnahmen für die Barrierefreiheit) bezieht sich auf Folgekosten, welche zusätzlich abgesichert sein sollten.",
	},
	{
		rang: 36,
		finanzthema: "Kostenrisiko aus Rechtsdurchsetzung",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Haftung und Rechtsschutz / Vermögensschutz", "Mobilität und Reisen", "Haus und Wohnung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Rechtsanwaltsgebühren, Gerichtsgebühren, Kosten für Gutachter, usw. sind Kosten für die Wahrnehmung von rechtlichen Interessen, welche abgesichert werden sollten. Die persönlichen Umstände beeinflussen, den abzudeckenden (Rechts-)Bereich. Empfohlen wird eine Rechtschutzversicherung.",
	},
	{
		rang: 37,
		finanzthema: "Schaffung von Kapital für die Ausbildung der Kinder",
		bedarfsfeld_vertrieb: "Haus & Familie",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Partner*in und ggf. Kinder", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Die Ausbildung von Kindern kann finanzielle Mittel beanspruchen für bspw. Lehrmaterialien, Jahres-/Semestergebühren, Ausflüge, etc.. Sinnvoll ist es, Sparmaßnahmen für die Schaffung von Kapital für die Ausbildung der Kinder zu ergreifen.",
	},
	{
		rang: 38,
		finanzthema: "Wesentliche Ersatzinvestitionen Sachwerte (ohne Immobilien)",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Mobilität und Reisen", "Haus und Wohnung", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Für wesentliche Ersatzinvestitionen von Sachwerten sollte eine Planung erstellt werden und mit einem separaten Kapitalaufbau frühzeitig begonnen werden, um  zu verhindern, dass die Liquiditätsreserver für Ersatzinvestitionen verwendet werden.",
	},
	{
		rang: 39,
		finanzthema: "Kostenrisiko Krankheit aus ambulanten, zahnmedizinischen und weiteren Zusatzleistungen",
		bedarfsfeld_vertrieb: "Sicherung der Gesundheit",
		themenbereich: "Vorsorge",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Krankheit und Pflege"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Innerhalb der gesetzlichen oder einer privaten Kankenversicherungen können Leistungen begrenzt sein. Sollten Leistungen finanziell über dem gesetzlichen bzw. vertraglichen Stand liegen, müssen Zuzahlungen erbracht werden, weshalb zur Absicherung im ambulanten und im zahnmedizinischen Bereich  eine private Krankenzusatzverscherung oder eine Krankenvollversicherung empfohlen wird.",
	},
	{
		rang: 40,
		finanzthema: "Risiko des Verlustes/der Beschädigung von Fahrzeugen",
		bedarfsfeld_vertrieb: "Sicherung der Vermögenswerte",
		themenbereich: "Absicherung",
		bedarfsstufe: "2",
		bedarfsfeld_din: ["Mobilität und Reisen"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Durch ggf. Beschädigung, Zerstörung oder Verlust von Kfz, Flugzeug oder Wasserfahrzeig entstehen Aufwände für den Neukauf/die Reparatur. Dieses Risiko kann abgesichert werden.",
	},
	{
		rang: 41,
		finanzthema: "Schaffung von Eigenkapital zum Erwerb von selbstgenutztem Wohneigentum",
		bedarfsfeld_vertrieb: "Vermögensaufbau/-optimierung",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "3",
		bedarfsfeld_din: ["Haus und Wohnun", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "-",
	},
	{
		rang: 42,
		finanzthema: "Weitere individuelle Ziele",
		bedarfsfeld_vertrieb: "Vermögensaufbau/-optimierung",
		themenbereich: "Vermögensplanung",
		bedarfsstufe: "3",
		bedarfsfeld_din: ["Mobilität und Reisen", "Haus und Wohnung", "Sparen und Vermögensbildung"],
		risiko_uuid: "",
		risiko_label: "",
		info: "Für weitere individuelle Ziele sollte eine Planung erstellt werden und mit einem separaten Kapitalaufbau frühzeitig begonnen werden, um  zu verhindern, dass die Liquiditätsreserver für Ersatzinvestitionen verwendet werden.",
	},
];
