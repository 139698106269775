import { Add, Clear, ExpandLess, ExpandMore, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import PropTypes from "prop-types";
import { Fragment, useRef, useState, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { getEmptyModel } from "../DMFhelperService";
import { ContentDialog, ContentDialogInit } from "../components/ContentDialog";
import YesNoComponent from "../components/YesNoComponent";
import useIsVisible from "components/useIsVisble";
import { v4 as uuidv4 } from "uuid";

MDDExpandable.propTypes = {
	item: PropTypes.object,
	control: PropTypes.object,
	disabled: PropTypes.bool,
	formItemRender: PropTypes.func,
	getValues: PropTypes.func,
	setValue: PropTypes.func,
	trigger: PropTypes.func,
};

export default function MDDExpandable(props) {
	const { item, control, formItemRender, getValues, setValue, trigger, disabled } = props;
	const { fields, append, remove, move } = useFieldArray({
		control,
		name: item.name,
	});
	const { openCD, setOpenCD, tca, setTca, showDescription } = ContentDialogInit();
	const [open, setOpen] = useState(false);
	const [sum, setSum] = useState(0);
	const [indexToRemove, setIndexToRemove] = useState(null);
	const theme = useTheme();
	const [dialogOpen, setDialogOpen] = useState(false);
	const deleteLabel = item.delete_label || "der Eintrag";
	const ref = useRef();
	const isVisible = useIsVisible(ref);

	useEffect(() => {
		if (isVisible) {
			if (getValues(item.name)?.length === 0) setOpen(true);
			recalculateSum();
		}
	}, [isVisible]);

	const handleYesAction = () => {
		removeItem();
	};

	const handleOpenDialog = (indexToRemove) => {
		setIndexToRemove(indexToRemove);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	const recalculateSum = () => {
		const newSum = getValues(item?.name)?.reduce((acc, listItem) => {
			return acc + Number(listItem[item.show_sum?.name] || 0);
		}, 0);
		setSum(newSum || 0);
	};

	const getFormattedValue = (value, unit_type, unit) => {
		let thousandsGroupStyle = null;
		let decimalSeparator = ",";
		let thousandSeparator = ".";

		switch (unit_type) {
			case "currency_no_digit":
				thousandsGroupStyle = "thousand";
				break;
			case "currency":
				thousandsGroupStyle = "thousand";
				break;
			case "percent":
				thousandSeparator = null;
				break;
			case "integer":
				break;
		}
		const formattedValue = new Intl.NumberFormat("de-DE", {
			style: "decimal",
			minimumFractionDigits: item.decimal_places || 0,
			maximumFractionDigits: item.decimal_places || 0,
			useGrouping: thousandsGroupStyle !== null,
			grouping: thousandsGroupStyle,
			decimalSeparator,
			thousandsSeparator: thousandSeparator,
		}).format(value);

		return `${formattedValue} ${unit || ""}`.trim();
	};

	const handleClick = () => {
		recalculateSum();
		setOpen(!open);
	};

	const buttonSX = { margin: "2px", minWidth: "32px", width: "32px", height: "32px" };

	const getRenderItem = (toRender, index) => {
		return toRender.children.map((child) => {
			const name = toRender.control === "expandable" ? `${toRender.name}[${index}].${child.name}` : `${toRender.name}.${child.name}`;
			let newChild = { ...child, name: name };

			if (newChild.label) newChild.label = newChild.label.replace("{index}", index + 1);

			if (newChild.watch && Array.isArray(newChild.watch)) {
				newChild.watch = newChild.watch.map((watchItem) => {
					if (typeof watchItem === "string") {
						return watchItem.replace("{index}", index);
					}
					return watchItem;
				});
			}

			if (newChild.visible && newChild.visible.field) {
				newChild.visible = JSON.parse(JSON.stringify(newChild.visible).replace("{index}", index));
			}

			if (newChild.children) newChild.children = getRenderItem(newChild, index);
			return newChild;
		});
	};

	const addItem = () => {
		const newIndex = fields.length;
		append({ uuid: uuidv4() });
		const initialValues = getEmptyModel(getRenderItem(item, newIndex));
		Object.keys(initialValues).forEach((key) => {
			setValue(key, initialValues[key]);
		});
		trigger(item.name);
		recalculateSum();
	};

	const removeItem = () => {
		remove(indexToRemove);
		trigger(item.name);
		recalculateSum();
	};

	const Content = () => {
		if (fields.length === 0) return <></>;
		return fields.map((field, index) => {
			let renderItem = getRenderItem(item, index);
			const rowValues = getValues(item.name)[index];
			if (rowValues["protected"]) {
				renderItem = renderItem.map((item) => {
					return { ...item, disabled: true };
				});
			}

			return (
				<Box
					key={field.id}
					sx={{
						mt: "8px",
						mb: "0px",
						pl: "0px",
						pr: "0px",
						pb: "8px",
						pt: "16px",
						boxShadow: "0px 1px 0px #E6E6E6",
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							height: "100%",
							mt: "2px",
							ml: "0px",
							mr: "0px",
							pr: "0px",
							pl: "10px",
							display: "flex",
							flexDirection: "column",
							flexShrink: 0,
							alignItems: "center",
							position: "relative",
						}}
					>
						{item.show_number && (
							<Box
								sx={{
									position: "absolute",
									top: "-20px",
									left: "17px",
									transform: "translateX(-50%)",
									padding: "2px",
									zIndex: 1,
									borderRadius: "50%",
									border: "1px solid #E6E6E6",
									backgroundColor: "#f6f6f6",
									width: "25px",
									height: "25px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								{index + 1}
							</Box>
						)}
						<CustomIcon name={item.icon} size={48} />
					</Box>

					<Box sx={{ flex: 1, pl: "0px", pr: "8px", ml: "0px" }}>{formItemRender(renderItem, recalculateSum)}</Box>
					{!disabled && (
						<Box
							sx={{
								height: "100%",
								mt: "2px",
								ml: "10px",
								mr: "0px",
								pr: "8px",
								display: "flex",
								flexDirection: "column",
								flexShrink: 0,
							}}
						>
							<IconButton sx={buttonSX} disabled={rowValues["protected"] || false} onClick={() => handleOpenDialog(index)}>
								<Clear />
							</IconButton>
							<IconButton variant="outlined" disabled={index === 0} sx={buttonSX} onClick={() => move(index, index - 1)}>
								<ExpandLess />
							</IconButton>
							<IconButton variant="outlined" disabled={index === fields.length - 1} sx={buttonSX} onClick={() => move(index, index + 1)}>
								<ExpandMore />
							</IconButton>
						</Box>
					)}
				</Box>
			);
		});
	};

	return (
		<div ref={ref}>
			<Tile sx={{ padding: "0px", mb: "16px" }}>
				<ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} />
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						pl: "16px",
						pt: "16px",
						pb: "16px",
						boxShadow: "0px 1px 0px #E6E6E6",
						":hover": {
							cursor: disabled ? "default" : "pointer",
							backgroundColor: disabled ? "none" : theme.palette.grey[100],
						},
					}}
					onClick={handleClick}
				>
					<Typography variant="h6">
						{item.label} ({fields.length})
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{item.show_sum && (
							<Typography variant="h6" sx={{ marginRight: "8px" }}>
								{getFormattedValue(sum, item.show_sum.unit_type || "currency_no_digit", item.show_sum.unit)}
							</Typography>
						)}
						{item.info && (
							<IconButton onClick={(e) => showDescription(item)} sx={{ width: "40px", height: "40px" }}>
								<CustomIcon name="Info" />
							</IconButton>
						)}
						<IconButton>
							<KeyboardArrowUp sx={{ width: "24px", height: "24px", ...(!open && { transform: "rotate(180deg)" }) }} />
						</IconButton>
					</Box>
				</Box>
				{open && (
					<Fragment>
						<Content />
						{item.show_sum && (
							<Fragment>
								<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "0px", pl: "16px", pr: "16px", alignItems: "flex-end" }}>
									<Typography variant="subtitle2">{item.show_sum.label || "Summe"}</Typography>
									<Typography variant="h4">{getFormattedValue(sum, item.show_sum.unit_type || "currency_no_digit", item.show_sum.unit)}</Typography>
								</Box>
							</Fragment>
						)}
						<Box
							sx={{
								pl: "16px",
								pt: "16px",
								pb: "16px",
								display: "flex",
								justifyContent: "flex-start",
							}}
						>
							<Button size="small" variant="outlined" disabled={disabled} onClick={addItem}>
								<Add sx={{ mr: "10px" }} />
								{item.label_add || "neu"}
							</Button>
						</Box>
					</Fragment>
				)}
			</Tile>
			<YesNoComponent open={dialogOpen} onClose={handleCloseDialog} onYes={handleYesAction} title="Wirklich?" message={"Soll " + deleteLabel + " wirklich gelöscht werden?"} />
		</div>
	);
}
