import { useEffect } from "react";
//import { getToken } from "../helper";
import { Box, CssBaseline } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import Header from "./components/Header";

BackendLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default function BackendLayout(props) {
	const { children } = props;
	const theme = useTheme();
	const [appWindow, setAppWindow] = useRecoilState(appWindowAtom);

	useEffect(() => {
		const handleResize = () => {
			const headerHeight = 76;
			const padding = 48;

			let width = window.innerWidth;
			let height = window.innerHeight - headerHeight;

			let contentWidth = width - padding * 2;
			let contentHeight = height - padding * 2;
			if (contentWidth > 1900) {
				contentWidth = 1900;
			}

			setAppWindow({ width: width, height: height, contentWidth: contentWidth, contentHeight: contentHeight, contentAlign: "center", headerHeight: headerHeight, isMobile: false });
		};

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			<Header />

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "center",
					maxWidth: "100%",
					height: appWindow.height,
					flexGrow: 1,
					marginTop: appWindow.headerHeight + "px",
					overflowY: "auto",
					padding: "48px",
					backgroundColor: theme.palette.bgColor,
				}}
			>
				<Box
					id="content"
					sx={{
						width: appWindow.contentWidth,
						minHeight: appWindow.contentHeight,
						padding: "0px",
					}}
				>
					{children}
				</Box>
			</Box>
		</Box>
	);
}
