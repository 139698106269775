export const defTemplateVAO = [
	{
		control: "expandable",
		label_add: "Vermögenswert hinzufügen",
		name: "vermoegenswerte",
		label: "Vermögenswerte",
		delete_label: "der Vermögenswert",
		icon: "MoneyIncome",
		cols: 12,
		info: {
			title: "Hinweise",
			description:
				"Der Auflistung ihrer Vermögenswerte dient dazu einen Gesamtüberblick über ihre Vermögenssituation zu erhalten. Hierzu haben Sie die Möglichkeit alle vorhandenen Geld-, Vermögens- und Sachwerte aufzulisten. Die Angaben werden Ihnen in einer Bilanz zusammengefasst.",
		},
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		default: [
			{
				bezeichnung: "girokonto",
				wert: 0,
			},
			{
				bezeichnung: "spareinlagen",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: [
					{ label: "Girokonto", value: "girokonto" },
					{ label: "Geldschulden gegenüber dem Haushalt", value: "geldschulden" },
					{ label: "Spareinlagen", value: "spareinlagen" },
					{ label: "festverzinsliche Wertpapiere", value: "wertpapiere" },
					{ label: "Bausparverträge", value: "bausparvertraege" },
					{ label: "Bargeld", value: "bargeld" },
					{ label: "Lebensversicherungen", value: "lebensversicherungen" },
					{ label: "Börsennotierte Aktien", value: "aktien" },
					{ label: "Investmentfonds", value: "investmentfonds" },
					{ label: "Unternehmenseigentum", value: "unternehmenseigentum" },
					{ label: "Wertgegenstände", value: "wertgegenstaende" },
					{ label: "Fahrzeuge", value: "fahrzeuge" },
					{ label: "Wohnsitze", value: "wohnsitze" },
					{ label: "weitere Immobilien", value: "weitere_immobilien" },
					{ label: "Sonstige", value: "sonstige" },
				],
			},
			{
				name: "wert",
				label: "Wert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "protected",
				control: "boolean",
				default: false,
			},
		],
	},

	{
		control: "expandable",
		label_add: "Verbindlichkeit hinzufügen",
		name: "verbindlichkeiten",
		label: "Verbindlichkeiten",
		delete_label: "die Verbindlichkeit",
		icon: "MoneySpending",
		info: {
			title: "Hinweise",
			description:
				"Der Auflistung ihrer Verbindlichkeiten dient dazu einen Gesamtüberblick über ihre Vermögenssituation zu erhalten. Hierzu haben Sie die Möglichkeit alle vorhandenen Darlehens- und Kreditverbindlichkeiten aufzulisten. Die Angaben werden Ihnen in einer Bilanz zusammengefasst.",
		},
		cols: 12,
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		default: [
			{
				bezeichnung: "ueberziehungkredit",
				wert: 0,
			},
			{
				bezeichnung: "kreditkarten",
				wert: 0,
			},
			{
				bezeichnung: "sonstige_kredite",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: [
					{ label: "Darlehen Wohnsitze", value: "wohnsitze" },
					{ label: "Darlehen weitere Immobilien", value: "weitere_immobilien" },
					{ label: "Darlehen Fahrzeuge", value: "fahrzeuge" },
					{ label: "Sonstige Kredite", value: "sonstige_kredite" },
					{ label: "Konsumentenkredit", value: "konsumentenkredit" },
					{ label: "Kreditkarten Schulden", value: "kreditkarten" },
					{ label: "Überziehungskredite", value: "ueberziehungkredit" },
				],
			},
			{
				name: "wert",
				label: "Wert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "protected",
				control: "boolean",
				default: false,
			},
		],
	},

	{
		control: "expandable",
		label_add: "Ziel hinzufügen",
		name: "investitionsziele",
		label: "Investitionsziele",
		delete_label: "das Investitionsziel",
		icon: "Target",
		info: {
			title: "Hinweise",
			description:
				"Die Angabe von Investitionszielen dient dazu, finanzielle Ziele, die nicht von der Norm abgedeckt sind bzw. die über die festgelegten Zielgrößen hinausgehen bei der Planung der Verwendung der finanziellen Mittel mit einzubeziehen.",
		},
		cols: 12,
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: [
					{ label: "Erwerb Kfz", value: "ziel_auto" },
					{ label: "Kapitalanlage Immobilie", value: "ziel_appartement" },
					{ label: "früherer Ruhestand", value: "ziel_ruhestand" },
					{ label: "Aufbau weitere Liquidität", value: "ziel_liquiditaet" },
					{ label: "Weltreise", value: "ziel_weltreise" },
					{ label: "Berufliche Auszeit", value: "ziel_auszeit" },
					{ label: "Umzug", value: "ziel_umzug" },
					{ label: "Fortbildung", value: "ziel_fortbildung" },
					{ label: "Erwerb von Konsumgütern", value: "ziel_fernseher" },
					{ label: "neues Hobby", value: "ziel_neues_hobby" },
				],
			},
			{
				name: "zeitpunkt",
				label: "Zielzeitpunkt",
				control: "year_month_picker",
				cols: 6,
			},
			{
				name: "wert",
				label: "Zielwert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
		],
	},
];

/*

Erwerb Kfz
2. Erwerb Eigenheim
3. Früherer Ruhestand
4. Aufbau weitere Liquidität
5. Weltreise
6. Berufliche Auszeit
7. Umzug
8. Fortbildung
9. Erwerb von Konsumgütern
10. neue Hobby

*/
