export const getNewWorkflow = (workflow, module_uuid) => {
  const activeModule = workflow.workflow.modules.find((module) => module.uuid === module_uuid);
  let submodule_uuid = null;
  if (activeModule?.submodules?.length > 0) submodule_uuid = activeModule.submodules[0].uuid;
  return { workflow: workflow.workflow, active_module: module_uuid, active_submodule: submodule_uuid };
};

export const getActiveModule = (workflow) => {
  if (!workflow.active_module) return null;
  return workflow.workflow.modules?.find((module) => module.uuid === workflow.active_module);
};

export const getActiveSubmodule = (workflow) => {
  const activeModule = getActiveModule(workflow);
  if (!activeModule) return null;
  return activeModule.submodules?.find((submodule) => submodule.uuid === workflow.active_submodule);
};
