export const defTemplateTelekommunikation = [
	{
		name: "tel_privat",
		label: "Telefon privat",
		control: "input",
		cols: 12,
		icon: "Phone",
	},
	{
		name: "tel_mobil",
		label: "Telefon mobil",
		control: "input",
		cols: 12,
		icon: "Phone",
	},
	{
		name: "tel_geschaeftlich",
		label: "Telefon geschäftlich",
		control: "input",
		cols: 12,
		icon: "Phone",
	},
	{
		name: "email_privat",
		label: "E-Mail privat",
		control: "input",
		cols: 12,
		icon: "Mail",
	},
	{
		name: "email_geschaeftlich",
		label: "E-Mail geschäftlich",
		control: "input",
		cols: 12,
		icon: "Mail",
	},
];
