const toGroup = [8, 9, 10, 11, 12, 13, 14, 15, 17, 19, 21, 30, 32, 33, 38, 41, 42];

export const getFamilienScore = (calculatedDisplaywerte) => {
	const relevant = calculatedDisplaywerte.filter((wert) => wert.relevant);
	return Math.round(relevant.reduce((acc, curr) => acc + curr.ist_percent, 0) / relevant.length);
};

export const calcIst = (sollWerte, istWerte, bedarf, isSimulation = false) => {
	return sollWerte.map((sollWert) => {
		const zugeordneteVertraege = istWerte?.filter((istWert) => istWert.rang === sollWert.rang && istWert.person_uuid === sollWert.haushaltsmitglied.person_uuid);
		let wert_simulation = sollWert.valueNotRelevant;
		let wert_vorhanden = sollWert.valueNotRelevant;
		let istPercent_simulation = 0;
		let istPercent_vorhanden = 0;
		const vertraege = zugeordneteVertraege;
		if (vertraege.length > 0) {
			switch (sollWert.einheit) {
				case "string":
					vertraege.map((vertrag) => {
						let sollCompareWert = [sollWert.sollwert];
						if (sollCompareWert === "Krankenversicherung mit Einschluss Leistungen im Ausland") sollCompareWert = ["Auslandsreisekrankenversicherung"];
						if (sollCompareWert === "Pflegepflichtversicherung") sollCompareWert = ["Betriebliche Krankenversicherung", "Gesetzliche Krankenversicherung", "Private Krankenversicherung"];
						if (sollCompareWert === "Gebäudeversicherung nach gleitendem Neuwert mit Unverversicherungsverzicht") sollCompareWert = ["Betriebsgebäude", "Wohngebäude ohne Feuer", "Wohngebäudeversicherung"];
						if (sollCompareWert === "PKV (Voll/Zusatz) mit Einschluss stationärer Leistungen") sollCompareWert = "Dummy";
						if (sollCompareWert === "PKV (Voll/Zusatz) mit Einschluss Leistungen Ambulant und Zahn") sollCompareWert = "Dummy";
						if (sollCompareWert === "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust") sollCompareWert = "Dummy";

						let matched = false;
						if (sollWert.sollwert === "Pflegepflichtversicherung" && vertrag.produkt_id === 4) {
							matched = true;
						}
						if (sollCompareWert.includes(vertrag.din_wert)) matched = true;
						if (matched) {
							if (vertrag.vertragsart === "Simulationsvertrag") {
								wert_simulation = "erfüllt";
								istPercent_simulation = 100;
							} else {
								istPercent_vorhanden = 100;
								wert_vorhanden = "erfüllt";
							}
						}
					});
					break;
				case "euro":
				case "euro_gerundet":
					wert_simulation = vertraege.filter((vertrag) => vertrag.vertragsart === "Simulationsvertrag").reduce((summe, vertrag) => summe + vertrag.din_wert, 0);
					istPercent_simulation = (wert_simulation / sollWert.sollwert) * 100;
					if (istPercent_simulation > 100) istPercent_simulation = 100;

					wert_vorhanden = vertraege.filter((vertrag) => vertrag.vertragsart !== "Simulationsvertrag").reduce((summe, vertrag) => summe + vertrag.din_wert, 0);
					istPercent_vorhanden = (wert_vorhanden / sollWert.sollwert) * 100;
					if (istPercent_vorhanden > 100) istPercent_vorhanden = 100;
					break;
			}
		}

		if (sollWert.autoPercent) {
			istPercent_simulation += sollWert.autoPercent;
			wert_simulation += (sollWert.autoPercent / 100) * sollWert.sollwert;
			istPercent_vorhanden += sollWert.autoPercent;
			wert_vorhanden += (sollWert.autoPercent / 100) * sollWert.sollwert;
		}

		let wert = wert_vorhanden;
		let istPercent = istPercent_vorhanden;
		if (isSimulation) {
			if (sollWert.einheit === "string") {
				if (wert_simulation !== sollWert.valueNotRelevant) {
					wert = wert_simulation;
					istPercent = istPercent_simulation;
				}
			} else {
				wert += wert_simulation;
				istPercent += istPercent_simulation;
			}
		}

		return {
			...sollWert,
			ist_wert: wert,
			ist_wert_vorhanden: wert_vorhanden,
			ist_wert_simulation: wert_simulation,
			ist_percent: istPercent,
			ist_percent_simulation: istPercent_simulation,
			ist_percent_vorhanden: istPercent_vorhanden,
			vertraege: vertraege,
		};
	});
};

export const isGroupingRang = (rang) => toGroup.includes(Number(rang));

export const groupRang = (data) => {
	const groupedData = data.reduce((acc, curr) => {
		const found = acc.find((item) => isGroupingRang(curr.rang) && item.rang === curr.rang);

		if (found) {
			found.haushaltsmitglied.push(curr.haushaltsmitglied);
		} else {
			acc.push({
				...curr,
				haushaltsmitglied: [curr.haushaltsmitglied],
			});
		}

		return acc;
	}, []);
	return groupedData;
};
