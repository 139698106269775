export const defTemplateEinnahmenAusgaben = [
	{
		control: "expandable",
		label_add: "Einnahme hinzufügen",
		name: "einnahmen",
		label: "Einnahmen",
		delete_label: "die Einnahme",
		icon: "MoneyIncome",
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		cols: 12,
		default: [
			{
				bezeichnung: "nicht_selbstaendige_arbeit",
				wert: 0,
			},
			{
				bezeichnung: "nicht_selbstaendige_arbeit",
				wert: 0,
			},
			{
				bezeichnung: "oeffentliche_transferzahlungen",
				wert: 0,
			},
			{
				bezeichnung: "nicht_oeffentliche_transferzahlungen",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Name der Einnahme",
				control: "select",
				cols: 12,
				options: [
					{ label: "pauschales Haushaltseinkommen", value: "pauschale_einnahmen" },
					{ label: "Nettoeinkommen aus selbständiger Arbeit", value: "selbstaendige_arbeit" },
					{ label: "Nettoeinkommen aus nicht selbständiger Arbeit", value: "nicht_selbstaendige_arbeit" },
					{ label: "Einnahmen aus Vermögen", value: "vermoegen" },
					{ label: "sonstige Einnahmen", value: "sonstige_einnahmen" },
				],
			},
			{
				name: "wert",
				label: "Höhe",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 10000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				suffix: "/mtl.",
			},
			{
				name: "details_sonstiges",
				label: "Details zur Einnahme 'sonstige Einnahmen'",
				control: "input",
				watch: ["haushalt.einnahmen[{index}].bezeichnung"],
				visible: { func: "equal", field: "haushalt.einnahmen[{index}].bezeichnung", value: "sonstige_einnahmen" },
				cols: 12,
			},
		],
	},

	{
		control: "expandable",
		label_add: "Ausgabe hinzufügen",
		name: "ausgaben",
		label: "Ausgaben",
		delete_label: "die Ausgabe",
		icon: "MoneySpending",
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		cols: 12,
		default: [
			{
				bezeichnung: "nahrung",
				wert: 0,
			},
			{
				bezeichnung: "bekleidung",
				wert: 0,
			},
			{
				bezeichnung: "wohnen",
				wert: 0,
			},
			{
				bezeichnung: "versicherungen",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Name der Ausgabe",
				control: "select",
				cols: 12,
				options: [
					{ label: "pauschale Lebenshaltungskosten", value: "pauschale_ausgaben" },
					{ label: "Nahrungsmittel, Getränke, Tabakwaren", value: "nahrung" },
					{ label: "Bekleidung und Schuhe", value: "bekleidung" },
					{ label: "Wohnen, Energie, Wohnungsinstandhaltung", value: "wohnen" },
					{ label: "Innenausstattung, Haushaltsgeräte und -gegenstände", value: "innenausstattung" },
					{ label: "Gesundheit", value: "gesundheit" },
					{ label: "Verkehr", value: "verkehr" },
					{ label: "Information und Kommunikation", value: "information" },
					{ label: "Freizeit, Sport und Kultur", value: "freizeit" },
					{ label: "Bildungsdienstleistungen", value: "bildung" },
					{ label: "Gastronomie- und Beherbungsdienstleistungen", value: "gastronomie" },
					{ label: "Versicherungs- und Finanzdienstleistungen", value: "versicherungen" },
					{ label: "sonstiges Ausgaben", value: "sonstige_ausgaben" },
					{ label: "Unterhalt", value: "unterhalt" },
				],
			},
			//feld bei Auswahl von Sonstiges: "Details zu Ausgabe "Sonstiges""
			{
				name: "wert",
				label: "Höhe",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 10000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				suffix: "/mtl.",
			},
			{
				name: "details_sonstiges",
				label: "Details zur Ausgabe 'sonstige Ausgaben'",
				control: "input",
				watch: ["haushalt.ausgaben[{index}].bezeichnung"],
				visible: { func: "equal", field: "haushalt.ausgaben[{index}].bezeichnung", value: "sonstige_ausgaben" },
				cols: 12,
			},
		],
	},
	{
		name: "liquiditaet",
		label: "Liquidität",
		control: "number_label",
		unit: "€",
		unit_type: "currency_no_digit",
		disabled: true,
		cols: 12,
	},
];
