import { Box, Typography, useTheme, Collapse } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Tile from "components/Tile";
import { useRecoilValue, useRecoilState } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { getActiveModule } from "services/workflowService";
import SubModuleTile from "./SubModuleTile";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { useNavigate } from "react-router-dom";
import { doReplace } from "services/helperService";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuOpen } from "@mui/icons-material/";
import FamilyScore from "./FamilyScore";
import { MenuItem, FormControl, Select, Chip } from "@mui/material";

export default function SubModules() {
	const haushalt = useRecoilValue(haushaltAtom);
	const appWindow = useRecoilValue(appWindowAtom);
	const navWidth = 350;
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const activeModule = getActiveModule(workflow);
	const navigate = useNavigate();
	const theme = useTheme();
	const [visible, setVisible] = useState(true);
	const [selectedValue, setSelectedValue] = useState(workflow.active_submodule);

	useEffect(() => {
		setSelectedValue(workflow.active_submodule);
	}, [workflow.active_submodule]);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
		const newWorkflow = { ...workflow };
		newWorkflow.active_submodule = event.target.value;
		setWorkflow(newWorkflow);
	};

	let kundeLabel = doReplace(activeModule?.label, haushalt);
	if (kundeLabel === "") kundeLabel = "neuer Kunde";
	if (!activeModule) return null;

	const active_submodule = activeModule?.submodules?.find((submodule) => submodule.uuid === workflow.active_submodule);

	const getNavigation = () => {
		const selectedSubmodule = activeModule.submodules?.find((submodule) => submodule.uuid === selectedValue);

		if (active_submodule && active_submodule.navi_config) {
			return (
				<>
					<Box
						sx={{
							margin: "16px",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<FormControl sx={{ width: "100%", borderRadius: "8px", padding: "8px" }}>
							<Select
								labelId="custom-select-label"
								id="custom-select"
								value={selectedValue}
								onChange={handleChange}
								displayEmpty
								renderValue={(value) => {
									if (!value) return <em>Bitte wählen</em>;
									return (
										<Box sx={{ display: "flex", alignItems: "center" }}>
											{selectedSubmodule?.icon && <CustomIcon name={selectedSubmodule.icon} size={24} />}
											<Box sx={{ pl: "8px" }}>
												<Typography variant="body1" component="div">
													{selectedSubmodule?.label}
												</Typography>
											</Box>
										</Box>
									);
								}}
								sx={{
									borderRadius: "8px",
									backgroundColor: "#fff",
									".MuiOutlinedInput-notchedOutline": {
										borderColor: "rgba(0, 0, 0, 0.23)",
									},
									borderColor: "#E6E6E6",
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#E6E6E6",
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										borderColor: "#E6E6E6",
									},
								}}
							>
								{activeModule.submodules?.map((submodule) => (
									<MenuItem key={submodule.uuid} value={submodule.uuid}>
										{submodule.icon && (
											<Box>
												<CustomIcon name={submodule.icon} size={48} />
											</Box>
										)}
										<Box sx={{ pl: "8px" }}>
											<Typography variant="body1" component="div">
												{submodule.label}
											</Typography>
											<Typography variant="body2" component="span">
												{submodule.description}
											</Typography>
											{submodule.coming_soon && (
												<Box>
													<Chip size="small" sx={{ backgroundColor: "#E5CC9C", borderRadius: "8px" }} label="Coming soon" />
												</Box>
											)}
										</Box>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
							<MenuOpen />
						</IconButton>
					</Box>
					<Box sx={{ width: "100%", pl: "16px", pr: "16px", pt: "0px", pb: "16px", display: "flex", borderBottom: "1px solid #E6E6E6" }}>
						<FamilyScore />
					</Box>
				</>
			);
		} else
			return (
				<>
					<Box
						sx={{
							margin: "16px",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								":hover": {
									cursor: "pointer",
									backgroundColor: theme.palette.grey[100],
								},
							}}
							onClick={() => navigate("/home")}
						>
							<Box sx={{ pr: "8px", display: "flex", alignItems: "center" }}>
								<CustomIcon name="ArrowBack" size={20} />
							</Box>
							<Typography
								variant="body1"
								sx={{
									whiteSpace: "nowrap",
								}}
							>
								Alle Kunden
							</Typography>
						</Box>
						<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
							<MenuOpen />
						</IconButton>
					</Box>
					<Box sx={{ margin: "0px", padding: "0px" }}>
						<Box sx={{ width: "100%", pl: "16px", pr: "16px", pt: "0px", pb: "0px", display: "flex", alignItems: "center", borderBottom: "1px solid #E6E6E6" }}>
							<Box sx={{ pr: "16px" }}>
								<CustomIcon name={activeModule.icon} size={48} />
							</Box>
							<Box sx={{ pl: "8px" }}>
								<Typography variant="h5" component="div">
									{kundeLabel}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ padding: "16px", maxHeight: `calc(${appWindow.contentHeight}px - 200px)`, overflowY: "auto" }}>
							{activeModule.submodules?.map((submodule) => (
								<SubModuleTile key={submodule.uuid} submodule={submodule} />
							))}
						</Box>
					</Box>
				</>
			);
	};

	return (
		<Box>
			{!visible && (
				<Box
					sx={{
						margin: "0px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
						<Menu />
					</IconButton>
				</Box>
			)}

			<Collapse
				in={visible}
				orientation="horizontal"
				sx={{
					width: visible ? `${navWidth}px` : "0px",
					transition: theme.transitions.create("width", {
						duration: theme.transitions.duration.standard,
						easing: theme.transitions.easing.easeInOut,
					}),
				}}
			>
				<Tile sx={{ width: navWidth + "px", padding: "0px", margin: "0px", height: appWindow.contentHeight + "px" }}>{getNavigation()}</Tile>
			</Collapse>
		</Box>
	);
}
