import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme, IconButton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { ContentDialog, ContentDialogInit } from "../components/ContentDialog";

MDDButtonOptions.propTypes = {
  item: PropTypes.object,
  variant: PropTypes.string,
  control: PropTypes.object,
  trigger: PropTypes.func,
  disabled: PropTypes.bool,
};

function MDDButtonOptions(props) {
  const { item, control, trigger, disabled } = props;
  const { openCD, setOpenCD, tca, setTca, showDescription } = ContentDialogInit();
  const theme = useTheme();

  const anzItems = item.options.length;
  let percentage = "25%";
  if (anzItems % 2 === 0) percentage = "50%";
  if (anzItems % 3 === 0) percentage = "33.3%";
  if (anzItems % 4 === 0) percentage = "25%";

  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {!item.hide_label && <Typography variant="h6">{item.label}</Typography>}
        {item.info && (
          <IconButton onClick={(e) => showDescription(item)} sx={{ width: "40px", height: "40px" }}>
            <CustomIcon name="Info" />
          </IconButton>
        )}
      </Box>
      <Controller
        name={item.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
          return (
            <RadioGroup value={value || null} onChange={onChange} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
              {item.options.map((option, index) => (
                <Box
                  key={option.value}
                  sx={{
                    flex: "1 1 calc(" + percentage + " - 8px)",
                    minWidth: "calc(" + percentage + " - 8px)",
                    maxWidth: "calc(" + percentage + " - 8px)",
                    marginBottom: "8px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      border: "2px solid " + (value === option.value ? theme.palette.borderActive : theme.palette.borderInactive),
                      borderRadius: "8px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      ":hover": {
                        cursor: disabled ? "default" : "pointer",
                        backgroundColor: disabled ? "none" : theme.palette.grey[100],
                      },
                    }}
                    onClick={() => {
                      if (!disabled) {
                        onChange(value === option.value ? null : option.value);
                        trigger(item.name);
                      }
                    }}
                  >
                    <FormControlLabel value={option.value} control={<Radio disabled={disabled} sx={{ padding: "4px", margin: "0px" }} />} sx={{ alignSelf: "flex-start", marginLeft: "0px" }} />
                    <Box sx={{ flex: "1", mt: "-20px", display: "flex", alignItems: "center" }}>
                      <CustomIcon name={option.icon} size={48} />
                    </Box>
                    <Typography variant="body2" sx={{ alignSelf: "center", marginBottom: "8px" }}>
                      {option.label}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {Array(4 - (item.options.length % 4))
                .fill(null)
                .map((_, index) => (
                  <div key={`empty${index}`} style={{ flex: "1 1 " + percentage, minWidth: percentage, maxWidth: percentage }}></div>
                ))}
            </RadioGroup>
          );
        }}
      />
    </FormControl>
  );
}

export default MDDButtonOptions;
