/* eslint-disable react/prop-types */
import { Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import PropTypes from "prop-types";
import { getEuro } from "services/formatService";

const styles = StyleSheet.create({
	page: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		padding: 20,
		fontFamily: "FoundryMonoline",
	},
	content: {
		display: "flex",
		flexDirection: "row",
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		border: "1px solid gray",
		borderRadius: 8,
		paddingTop: 4,
		paddingBottom: 4,
	},
	footer: {
		marginTop: 16,
		paddingTop: 16,
		paddingBottom: 16,
		marginBottom: 16,
	},
	footerText: {
		marginLeft: 5,
	},
	pricePositive: {
		marginRight: 5,
		color: "#1B5E20",
	},
	priceNegative: {
		marginRight: 5,
		color: "#C62828",
	},
});

export const ColumnPage = ({ pdfModel, columns, contentGap = 40, showSum = false, labelSum = "Nettovermögen", valueSum = 0 }) => (
	<Page size="A4" style={styles.page} orientation="landscape">
		<Header pdfModel={pdfModel} />
		<View style={[styles.content, { gap: contentGap }]}>
			{columns.map((column, i) => (
				<View key={`column_${i}`} style={{ flex: column.flex ? column.flex : 1, textAlign: column.textAlign ? column.textAlign : "justify" }}>
					{column.content}
				</View>
			))}
			{columns.length === 1 && <View style={{ flex: 1 }} />}
		</View>
		{showSum && (
			<View style={[styles.row, styles.footer]}>
				<Text style={styles.footerText}>{labelSum}</Text>
				{valueSum > 0 ? <Text style={styles.pricePositive}>{getEuro(valueSum, 0)}</Text> : <Text style={styles.priceNegative}>{getEuro(valueSum, 0)}</Text>}
			</View>
		)}
		<Footer pdfModel={pdfModel} />
	</Page>
);

ColumnPage.propTypes = {
	columns: PropTypes.array,
	contentGap: PropTypes.number,
	labelSum: PropTypes.string,
};
