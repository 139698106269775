import { pdfModel } from "mock/pdf_test";
import { Image as ReactPDFImage } from "@react-pdf/renderer";

export const generalChartOptions = {
	animations: false,
	responsive: false,
	cutout: "70%",
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
			border: {
				display: false,
			},
		},
		y: {
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
			border: {
				display: false,
			},
		},
	},
};

export const gaugeChartOptions = {
	...generalChartOptions,
	rotation: 270,
	circumference: 180,
};

export const getVisualisierungChartData = () => {
	const visualisierungChartData = {
		labels: pdfModel.visualisierung.map(() => ""),
		datasets: [
			{
				data: pdfModel.visualisierung.map((x) => x.price),
				backgroundColor: pdfModel.visualisierung.map((x) => x.bulletColor),
				weight: 5,
			},
		],
	};

	return visualisierungChartData;
};

export const getFamilienScoreChartData = () => {
	const familienScoreChartData = {
		labels: pdfModel.familienScore.map(() => ""),
		datasets: [
			{
				data: pdfModel.familienScore.map((x) => x.percentage),
				backgroundColor: pdfModel.familienScore.map((x) => x.trackColor),
				weight: 5,
			},
		],
	};

	return familienScoreChartData;
};

export const getPersonChartData = (chartValuePercentage) => {
	const personChartData = {
		labels: ["", ""],
		datasets: [
			{
				data: [chartValuePercentage, 100 - chartValuePercentage],
				backgroundColor: ["#2E7D32", "#D32F2F"],
				weight: 5,
			},
		],
	};

	return personChartData;
};

export const getChartColor = (i) => {
	switch (i) {
		case 0:
			return "#C62828";
		case 1:
			return "#F9A825";
		case 2:
			return "#0057B2";
		case 3:
			return "#512DA8";
		case 4:
			return "#2E7D32";
		case 5:
			return "#D84315";
		case 6:
			return "#FFD600";
		case 7:
			return "#6D4C41";
		case 8:
			return "#FF6F00";
		case 9:
			return "#FFD600";
		case 10:
			return "#FF6F00";
		default:
			return "#000000";
	}
};
export const getChart = (identifier, style = {}) => {
	let chartBase64;
	const chartCanvas = document.getElementById(identifier);
	if (chartCanvas) {
		chartBase64 = chartCanvas.toDataURL("image/png");
	}
	if (chartBase64) {
		return <ReactPDFImage src={chartBase64} style={style} />;
	}
	return null;
};
