import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography, useTheme } from "@mui/material";
import AppModuleTile from "components/AppModuleTile";
import DMTable from "components/DMTable";
import Tile from "components/Tile";
import BackendLayout from "layouts/BackendLayout";
import { Fragment, useEffect, useState, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { calcSoll } from "services/DINSollCalculationService";
import { getEuro } from "services/formatService";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";

export default function Sollwerte() {
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [activeModule, setActiveModule] = useState("Alle Bedarfsfelder");
	const appWindow = useRecoilValue(appWindowAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const haushaltsmitglieder = [...new Set(calculatedSollwerte.map((objekt) => objekt.haushaltsmitglied))];

	const [sollwerte, setSollwerte] = useState(calculatedSollwerte);
	const [nurRelevant, setNurRelevant] = useState(true);
	const [haushaltsmitglied, setHaushaltsmitglied] = useState("Alle");
	const theme = useTheme();

	const handleChange = (event) => {
		setHaushaltsmitglied(event.target.value);
	};

	useEffect(() => {
		if (calculatedSollwerte) {
			let sollwerteFiltered = calculatedSollwerte;
			if (activeModule !== "Alle Bedarfsfelder") sollwerteFiltered = sollwerteFiltered.filter((sollwert) => sollwert.bedarfsfeld_vertrieb === activeModule);
			if (nurRelevant) sollwerteFiltered = sollwerteFiltered.filter((item) => item.relevant);
			if (haushaltsmitglied !== "Alle") {
				sollwerteFiltered = sollwerteFiltered.filter((item) => item.haushaltsmitglied === haushaltsmitglied);
			}
			setSollwerte(sollwerteFiltered);
		}
	}, [activeModule, nurRelevant, haushaltsmitglied]);

	const setNewActiveModule = (newActiveModule) => {
		// if (newActiveModule !== "Beratungsprozess") setWorkflow({ workflow: workflow.workflow, active_step: 0 });
		setActiveModule(newActiveModule);
	};

	const getSollFormatted = (item) => {
		switch (item.einheit) {
			case "euro":
				return getEuro(item.sollwert);
			case "euro_gerundet":
				return getEuro(item.sollwert, 0);
			case "string":
			default:
				return item.sollwert;
		}
	};

	const columnsDefiniton = [
		{
			accessorKey: "rang",
			header: "Rang",
			size: 70,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorKey: "bedarfsfeld_din",
			header: "DIN-Modul",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return <Fragment>{objectToRender.bedarfsfeld_din.join(", ")}</Fragment>;
			},
			size: 200,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorFn: (row) => `${row.bedarfsfeld_vertrieb} ${row.themenbereich}`,
			header: "Bedarfsfeld/Risiko",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Fragment>
						<div>{objectToRender.bedarfsfeld_vertrieb}</div>
						<div>
							<b>{objectToRender.finanzthema}</b>
						</div>
					</Fragment>
				);
			},
			size: 200,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorKey: "haushaltsmitglied",
			header: "Haushaltsmitglied",
			size: 100,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorFn: (row) => `${row.wert} ${row.einheit}`,
			header: "Sollwert",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return getSollFormatted(objectToRender);
			},
			size: 100,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return { align: "right" };
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
	];

	return (
		<BackendLayout>
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
				<Tile sx={{ width: "400px", padding: "24px", mr: "16px", height: appWindow.contentHeight + "px", overflowY: "auto" }}>
					<Box sx={{ margin: "0px", padding: "0px" }}>
						<Box sx={{ pl: "8px", pt: "8px", pb: "24px", display: "flex", alignItems: "center" }}>
							<Box sx={{ pl: "8px" }}>
								<Typography variant="h5" component="div">
									DIN-Sollwerte ABCD
								</Typography>
							</Box>
						</Box>
						<AppModuleTile label="Alle Bedarfsfelder" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/SdE1.svg" label="Sicherung des Einkommens" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/SdV1.jpg" label="Sicherung der Vermögenswerte" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/HuF2.jpg" label="Haus & Familie" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/SdG1.svg" label="Sicherung der Gesundheit" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/AV3.jpg" label="Altersvorsorge" activeModule={activeModule} setActiveModule={setNewActiveModule} />
						<AppModuleTile background_image="/images/VAO6.svg" label="Vermögensaufbau/-optimierung" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					</Box>
				</Tile>
				<Tile sx={{ width: appWindow.contentWidth + "px", padding: "24px", margin: "0px", height: appWindow.contentHeight + "px" }}>
					<Box sx={{ height: appWindow.contentHeight - 100 + "px", padding: "0px", margin: "0px", overflowY: "auto" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography variant="h5" sx={{ marginRight: "auto", paddingBottom: "24px" }}>
								{activeModule}
							</Typography>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
								<FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
									<InputLabel size="small">Haushaltsmitglied</InputLabel>
									<Select value={haushaltsmitglied} onChange={handleChange}>
										<MenuItem value="Alle">Alle</MenuItem>
										{haushaltsmitglieder.map((haushaltsmitglied) => (
											<MenuItem key={haushaltsmitglied} value={haushaltsmitglied}>
												{haushaltsmitglied}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControlLabel
									checked={nurRelevant || false}
									onChange={(e) => {
										setNurRelevant(e.target.checked);
									}}
									control={<Switch />}
									label="nur relevante Risiken anzeigen"
									sx={{ marginLeft: "auto", marginRight: 0 }}
								/>
							</Box>
						</Box>
						<DMTable
							data={sollwerte}
							columns={columnsDefiniton}
							searchword=""
							initialStateConfig={{
								sorting: [
									{
										id: "rang",
										asc: true,
									},
								],
							}}
						/>
					</Box>
				</Tile>
			</Box>
		</BackendLayout>
	);
}
