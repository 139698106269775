import BackendLayout from "layouts/BackendLayout";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { workflowAtom } from "recoil/atoms/workflowAtom";
//import { getWFRoute } from "services/workflowService";
import { useNavigate } from "react-router-dom";

export default function Start() {
	const [workflow, setWorkflow] = useRecoilState(workflowAtom);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const join_key = params.get("join_key");
	const navigate = useNavigate();

	useEffect(() => {
		const getData = async () => {
			axios
				.get(import.meta.env.VITE_BACKEND + "/api/auth/" + join_key, {})
				.then((res) => {
					if (res.status === 200) {
						setWorkflow({ workflow: res.data.workflow, active_step: 0 });
						console.log("res.data.workflow", res.data.workflow);
						// navigate(getWFRoute(res.data.workflow, 0));
					} else {
						console.log("OK not 200", res);
					}
				})
				.catch((e) => {
					console.log("error", e);
				});
		};
		getData();
	}, [join_key]);

	return <BackendLayout>{join_key}</BackendLayout>;
}
