import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, TextField, Typography, Grid } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { storeAuthToken } from "services/authService";
import { backendPost } from "services/dataService";
import { userAtom } from "recoil/atoms/userAtom";
import { useRecoilState } from "recoil";

export default function Login() {
	const { register, handleSubmit } = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [user, setUser] = useRecoilState(userAtom);

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = (event) => event.preventDefault();

	const onSubmit = (data) => {
		backendPost({
			label: "Login",
			setIsLoading: setIsLoading,
			route: "auth/signin",
			toSend: { username: data.username, password: data.password },
			setResult: doLogin,
			enqueueSnackbar: enqueueSnackbar,
		});
	};

	const doLogin = (data) => {
		storeAuthToken(data.accessToken, data.refreshToken, data.expiresIn);
		setUser({ user: { id: data.id, username: data.username, email: data.email, status: data.status }, berater: data.berater });
		navigate("/home", { replace: true });
	};

	return (
		<Box sx={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", background: "#FFFCF5" }}>
			<Grid container sx={{ flexGrow: 1 }}>
				<Grid item xs={8} sx={{ backgroundImage: "url('/formaxx/login_background.jpeg')", backgroundSize: "cover", backgroundPosition: "right" }}></Grid>

				<Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#FFFFFF" }}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							width: "100%",
							padding: "40px",
						}}
					>
						<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
							<Typography variant="h4">Anmelden</Typography>
							<Typography variant="subtitle1" sx={{ mb: 3 }}>
								Melden Sie sich mit Ihrem CRM-Add-In Benutzernamen und Passwort an
							</Typography>
							<TextField label="Benutzername" variant="filled" fullWidth {...register("username", { required: true })} sx={{ mb: 2 }} />
							<TextField
								label="Passwort"
								type={showPassword ? "text" : "password"}
								variant="filled"
								fullWidth
								{...register("password", { required: true })}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
								sx={{ mb: 2 }}
							/>
							<Typography variant="body2" sx={{ mb: 3 }}>
								Passwort vergessen?
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<Button variant="contained" color="primary" type="submit" disabled={isLoading}>
									Anmelden
								</Button>
							</Box>
						</form>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
